import moment from 'moment';
/* eslint-disable camelcase */
import React from 'react';
import { formatMoney } from '../../../helpers/currency';
import { dateToServerFormat, formatDateToSlashes, getDaysInt, isInPast, timePrettify } from '../../../helpers/date';
import { isMobile } from '../../../helpers/device';
import { isTrainer } from '../../../helpers/userRoles';
import { classNormalizer } from '../../../reducers/classesListReducer';
import { CustomerDataNormalizer } from '../../../reducers/currentCustomerReducer';
import {
    CustomerDogDataNormalizer,
    CustomerDogMedicalDataNormalizer,
    DogDataNormalizer,
    dogReportNormalizer,
} from '../../../reducers/currentDogReducer';
import {
    ClassEventDataNormalizer,
    classEventSubmitNormalizer,
    TrainingEventDataNormalizer,
} from '../../../reducers/currentEventReducer';
import { formEditCreateDataNormalizer } from '../../../reducers/formsPageReducer';
import { messageNormalizer } from '../../../reducers/messagesReducer';
import { packageNormalizer, trainingNormalizer } from '../../../reducers/packageListReducer';
import { VeterinarianDataNormalizer } from '../../../reducers/veterinarianReducer';
import * as FieldFactory from './fieldFactory';

export const getModalDialogFormName = modalType => {
    const forms = {
        editCreateModalSidebar: 'EditCreateModalSidebarDialogForm',
        editCreateModal: 'EditCreateModalDialogForm',
        tabModal: 'TabModalDialogForm',
    };
    return forms[modalType];
};

export const forms = {
    get dogInfoEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    breed: dogData.get('breed'),
                    weight: dogData.get('weight'),
                    markings: dogData.get('markings'),
                    month: new Date(dogData.get('birthday')).getMonth() + 1,
                    year: new Date(dogData.get('birthday')).getFullYear(),
                    gender: dogData.get('gender'),
                    is_altered: dogData.get('is_altered') ? 'Yes' : 'No',
                };
            },
            submitNormalizer: DogDataNormalizer,
            updateNormalizer: DogDataNormalizer,
            fields: [
                'breed',
                'weight',
                'markings',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['month', 'year'],
                },
                'gender',
                'is_altered',
            ],
        };
    },
    get dogNameEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    name: dogData.get('name'),
                };
            },
            fields: ['name'],
        };
    },
    get dogMedicalNotesEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    medical_notes: dogData.get('medical_notes'),
                };
            },
            fields: ['medical_notes'],
        };
    },
    get dogIntoleranceListEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    intolerance_list: dogData.get('intolerance_list').toJS().map(item => {
                        return {
                            label: item,
                            value: item,
                            className: 'Select-create-option-placeholder',
                        };
                    }),
                };
            },
            submitNormalizer: DogDataNormalizer,
            updateNormalizer: DogDataNormalizer,
            fields: ['intolerance_list'],
        };
    },
    get dogAccessLevelEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    access_level: dogData.get('access_level'),
                };
            },
            submitNormalizer: DogDataNormalizer,
            updateNormalizer: DogDataNormalizer,
            fields: ['access_level'],
        };
    },
    get dogVeterinarianEditModalDialog() {
        return {
            getInitialValues: dogData => {
                return {
                    veterinarian: dogData.getIn(['veterinarian_detail', 'id']),
                };
            },
            submitNormalizer: formData => {
                return {
                    veterinarian: formData.get('veterinarian').value,
                };
            },
            updateNormalizer: formData => {
                const vet = formData.get('veterinarian');
                return {
                    veterinarian_detail: {
                        id: vet.value,
                        name: vet.label,
                        address: vet.address,
                        phone: vet.phone,
                    },
                };
            },
            fields: ['veterinarian'],
        };
    },
    get dogVeterinarianCreateModalDialog() {
        return {
            getInitialValues: () => {
                return {
                    vet_name: '',
                    vet_phone: '',
                };
            },
            updateNormalizer: responseData => {
                return {
                    veterinarian_detail: responseData,
                };
            },
            submitNormalizer: VeterinarianDataNormalizer,
            fields: ['vet_name', 'vet_phone'],
        };
    },
    get createCustomerModalDialog() {
        return {
            submitNormalizer: CustomerDataNormalizer,
            fields: ['full_name', 'email', 'phone'],
        };
    },
    get createDogReportModalDialog() {
        return {
            getInitialValues: data => {
                if (data) {
                    const {
                        dog,
                        dog_detail,
                        id,
                        class_occurrence_detail,
                    } = data;
                    return {
                        dog,
                        dogName: dog_detail.name,
                        class_product: id,
                        date: formatDateToSlashes(class_occurrence_detail.date, true),
                    };
                }
                return { date: formatDateToSlashes(moment().toDate().toISOString(), true) };
            },
            submitNormalizer: dogReportNormalizer,
            isFieldDisabled: (formProps, field) => {
                if (field === 'date' && formProps.initialValues.get('class_product')) {
                    return true;
                }
                return undefined;
            },
            fields: [
                'report_form_photo',
                'report_form_title',
                'date',
                'report_form_icon_checkbox',
                'report_form_selectable',
            ],
        };
    },
    get editDogReportModalDialog() {
        return {
            getInitialValues: data => {
                let initialValues;
                if (data) {
                    if (Object.keys(data).indexOf('size') >= 0) {
                        data = data.toJS();
                    }
                    const { icons, date, photo_mobile, photo_desktop, ...otherProps } = data;
                    initialValues = {
                        date: formatDateToSlashes(date, true),
                        icon_select: icons.map((icon) => (icon.type)),
                        report_edit_form_photo: isMobile ? photo_desktop : photo_mobile,
                        ...otherProps
                    };
                    icons.map((icon) => { // eslint-disable-line array-callback-return
                        initialValues[icon.type] = {
                            label: icon.label,
                            value: icon.label,
                        };
                    });
                }
                return initialValues;
            },
            submitNormalizer: dogReportNormalizer,
            isFieldDisabled: (formProps, field) => {
                const classProduct = formProps.initialValues.get('class_product');
                if (field === 'date' && classProduct !== undefined && classProduct !== null) {
                    return true;
                }
                return undefined;
            },
            fields: [
                'report_edit_form_photo',
                'report_form_title',
                'date',
                'report_form_icon_checkbox',
                'report_form_selectable',
            ],
        };
    },
    get createClassModalDialog() {
        return {
            getInitialValues: () => {
                return {
                    date_interval: {
                        start: 'None',
                        end: 'None',
                    },
                    pickup_start_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    },
                    pickup_end_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    },
                    dropoff_start_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    },
                    dropoff_end_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    },
                };
            },
            getFieldProps: () => {
                return {
                    class_commission: {
                        minValue: 0,
                        maxValue: 100,
                        suffix: '%',
                    },
                    class_spots: {
                        minValue: 0,
                    },
                    class_price: {
                        minValue: 0,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                };
            },
            submitNormalizer: classNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['class_name_autocomplete'],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['create_class_location'],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['create_class_link_website'],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['class_image'],
                        },
                    ],
                },
                'separator',
                'class_weekdays',
                'separator',
                {
                    className: 'form__aligner form__aligner_50-50',
                    title: 'Pickup Times',
                    fields: ['class_pickup_start_time', 'class_pickup_end_time'],
                },
                'separator',
                {
                    className: 'form__aligner form__aligner_50-50',
                    title: 'Dropoff Times',
                    fields: ['class_dropoff_start_time', 'class_dropoff_end_time'],
                },
                'separator',
                'date_interval',
                'separator',
                'access_level',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['trainer_autocomplete', 'class_price'],
                },
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['class_spots', 'class_commission'],
                },
                'description',
            ],
        };
    },
    get editClassModalDialog() {
        return {
            getInitialValues: classData => {
                const classDataJS = classData.size ? classData.toJS() : classData;
                return {
                    class_name_autocomplete:
                        classDataJS.id !== undefined && classDataJS.name !== undefined
                            ? {
                                value: classDataJS.id,
                                label: classDataJS.name,
                            }
                            : undefined,
                    location:
                        classDataJS.location !== undefined && classDataJS.location_detail !== undefined
                            ? {
                                value: classDataJS.location,
                                label: classDataJS.location_detail,
                            }
                            : undefined,
                    location_array:
                        classDataJS.location_array && classDataJS.location_array.length ?
                            Object.keys(classDataJS.location_array_detail).map((key) => {
                                return {
                                    value: +key,
                                    label: classDataJS.location_array_detail[key],
                                    query: 'location',
                                    clearableValue: false

                                };
                            })
                            : undefined,
                    link_to_website: classDataJS.link_to_website,
                    class_weekdays: classDataJS.weekdaysArray,
                    class_image: classDataJS.photo_processed
                        ? classDataJS.photo_processed
                        : classDataJS.photo ? classDataJS.proto : undefined,
                    access_level:
                        classDataJS.access_level !== undefined && classDataJS.access_level_detail !== undefined
                            ? {
                                value: classDataJS.access_level,
                                label: classDataJS.access_level_detail,
                            }
                            : undefined,
                    trainer_autocomplete:
                        classDataJS.trainer_detail
                            ? {
                                value: classDataJS.trainer_detail.id,
                                label: classDataJS.trainer_detail.full_name,
                            }
                            : undefined,
                    class_price: classDataJS.price,
                    class_spots: classDataJS.spots.toString(),
                    class_commission: classDataJS.commission.toString(),
                    description: classDataJS.description,
                    date_interval: classDataJS.classDates,
                    pickup_start_time: {
                        value: timePrettify(classDataJS.pickup_start_time, 'hh:mmA'),
                        label: timePrettify(classDataJS.pickup_start_time, 'hh:mmA'),
                    },
                    pickup_end_time: {
                        value: timePrettify(classDataJS.pickup_end_time, 'hh:mmA'),
                        label: timePrettify(classDataJS.pickup_end_time, 'hh:mmA'),
                    },
                    dropoff_start_time: {
                        value: timePrettify(classDataJS.dropoff_start_time, 'hh:mmA'),
                        label: timePrettify(classDataJS.dropoff_start_time, 'hh:mmA'),
                    },
                    dropoff_end_time: {
                        value: timePrettify(classDataJS.dropoff_end_time, 'hh:mmA'),
                        label: timePrettify(classDataJS.dropoff_end_time, 'hh:mmA'),
                    },
                };
            },
            getFieldProps: classData => {
                const classDataJS = classData.size ? classData.toJS() : classData;
                return {
                    class_image: {
                        image: classDataJS.photo_processed
                            ? classDataJS.photo_processed
                            : classDataJS.photo ? classDataJS.proto : undefined,
                    },
                    class_commission: {
                        minValue: 0,
                        maxValue: 100,
                        suffix: '%',
                    },
                    class_spots: {
                        minValue: 0,
                    },
                    class_price: {
                        minValue: 0,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                };
            },
            submitNormalizer: classNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['class_name_autocomplete'],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['create_class_location'],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: ['create_class_link_website'],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['class_image'],
                        },
                    ],
                },
                'separator',
                'class_weekdays',
                'separator',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['class_pickup_start_time', 'class_pickup_end_time'],
                },
                'separator',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['class_dropoff_start_time', 'class_dropoff_end_time'],
                },
                'separator',
                'date_interval',
                'separator',
                'access_level',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['trainer_autocomplete', 'class_price'],
                },
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['class_spots', 'class_commission'],
                },
                'description',
            ],
        };
    },
    get classEventForm() {
        return {
            getInitialValues: eventData => {
                const {
                    location_array, training_class, training_class_detail, trainer, trainer_detail, access_level, date,
                    pickup_start_time, pickup_end_time, dropoff_start_time, dropoff_end_time, notes,
                    location_array_detail, valid_zip_codes_array
                } = eventData;
                // create new class after dnd field selection on CalendarPage case
                if (location_array === undefined && training_class === undefined && training_class_detail === undefined
                    && trainer === undefined && trainer_detail === undefined && access_level === undefined &&
                    pickup_end_time === undefined && dropoff_start_time === undefined && notes === undefined) {
                    return {
                        start_date: formatDateToSlashes(date, true),
                        end_date: formatDateToSlashes(date, true),
                        pickup_start_time: {
                            value: timePrettify(pickup_start_time, 'hh:mmA'),
                            label: timePrettify(pickup_start_time, 'hh:mmA'),
                        },
                        dropoff_end_time: {
                            value: timePrettify(dropoff_end_time, 'hh:mmA'),
                            label: timePrettify(dropoff_end_time, 'hh:mmA'),
                        },
                    };
                }
                // getting full info about classOccurrence from server case
                return {
                    location_array: location_array && location_array.length ?
                        Object.keys(location_array_detail).map((key) => {
                            return {
                                value: +key,
                                label: location_array_detail[key],
                                query: 'location',
                                clearableValue: false

                            };
                        })
                        : undefined,
                    // For autocomplete fields initial values must be provided as a { value, label } object,
                    // because this will also become its only option for the field.
                    training_class: {
                        value: training_class,
                        label: training_class_detail.name,
                        extra: {
                            price: training_class_detail.price,
                            zipCodes: training_class_detail.valid_zip_codes_array,
                        }
                    },
                    trainer: {
                        value: trainer,
                        label: trainer_detail.full_name,
                    },
                    access_level,
                    start_date: formatDateToSlashes(date, true),
                    end_date: formatDateToSlashes(date, true),
                    pickup_start_time: {
                        value: timePrettify(pickup_start_time, 'hh:mmA'),
                        label: timePrettify(pickup_start_time, 'hh:mmA'),
                    },
                    pickup_end_time: {
                        value: timePrettify(pickup_end_time, 'hh:mmA'),
                        label: timePrettify(pickup_end_time, 'hh:mmA'),
                    },
                    dropoff_start_time: {
                        value: timePrettify(dropoff_start_time, 'hh:mmA'),
                        label: timePrettify(dropoff_start_time, 'hh:mmA'),
                    },
                    dropoff_end_time: {
                        value: timePrettify(dropoff_end_time, 'hh:mmA'),
                        label: timePrettify(dropoff_end_time, 'hh:mmA'),
                    },
                    notes,
                };
            },
            isFieldDisabled: (formProps, field) => {
                if (isTrainer()) {
                    return true;
                }
                if (!formProps || !formProps.eventData) {
                    return undefined;
                }
                return isInPast(formProps.eventData.start) && !formProps.preventDisablingFields;
            },
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40',
                    fields: [
                        {
                            className: 'form__aligner_margin-top',
                            fields: [
                                'training_class', 'trainer',
                                {
                                    className: 'form__aligner form__aligner_50-50 form__divider',
                                    fields: ['location_array', 'access_level']
                                },
                                'start_date_observed',
                                {
                                    className: 'form__aligner form__aligner_50-50 form__divider_spacer',
                                    fields: ['pickup_start_time', 'pickup_end_time'],
                                },
                                'end_date_observed',
                                {
                                    className: 'form__aligner form__aligner_50-50',
                                    fields: ['dropoff_start_time', 'dropoff_end_time'],
                                },
                            ],
                        },
                        'event_notes',
                    ],
                },
            ],
            submitNormalizer: formData => {
                return ClassEventDataNormalizer(formData);
            },
        };
    },
    get trainingEventForm() {
        return {
            getInitialValues: eventData => {
                const { program, program_name, trainer, trainer_name, start, end, notes } = eventData;
                if (program === undefined && program_name === undefined && trainer === undefined &&
                    trainer_name === undefined && notes === undefined) {
                    const startPrettified = timePrettify(start, 'hh:mmA');
                    const endPrettified = timePrettify(end, 'hh:mmA');
                    return {
                        start_date: formatDateToSlashes(start, true),
                        end_date: formatDateToSlashes(end, true),
                        start_time: {
                            value: startPrettified,
                            label: startPrettified,
                        },
                        end_time: {
                            value: endPrettified,
                            label: endPrettified,
                        },
                    };
                }
                return {
                    // For autocomplete fields initial values must be provided as a { value, label } object,
                    // because this will also become its only option for the field.
                    program: {
                        value: program,
                        label: program_name,
                    },
                    trainer: {
                        value: trainer,
                        label: trainer_name,
                    },
                    start_date: moment(start).format('MM/DD/YY'),
                    end_date: moment(end).format('MM/DD/YY'),
                    start_time: {
                        value: moment(start).format('hh:mmA'),
                        label: moment(start).format('hh:mmA'),
                    },
                    end_time: {
                        value: moment(end).format('hh:mmA'),
                        label: moment(end).format('hh:mmA'),
                    },
                    notes,
                };
            },
            isFieldDisabled: (formProps, field) => {
                if (isTrainer() && field === 'trainer_by_training_program') {
                    return true;
                }
                if (!formProps || !formProps.eventData) {
                    return false;
                }
                return isInPast(formProps.eventData.start) && !formProps.preventDisablingFields;
            },
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40',
                    fields: [
                        {
                            className: 'form__aligner_margin-top',
                            fields: [
                                {
                                    className: 'form__aligner_margin-top form__divider',
                                    fields: ['program', 'trainer_by_training_program'],
                                },
                                {
                                    className: 'form__aligner_margin-top form__divider_spacer',
                                    fields: ['start_date_training', 'start_time'],
                                },
                                'end_date_training', 'end_time',
                            ],
                        },
                        'training_notes',
                    ],
                },
            ],
            submitNormalizer: TrainingEventDataNormalizer,
        };
    },
    get classEventDogSearch() {
        return {
            fields: ['class_event_dog_search'],
        };
    },
    get trainingEventDogSearch() {
        return {
            fields: ['training_event_dog_search'],
        };
    },
    get changePackageExpirationDateModalDialog() {
        return {
            getInitialValues: packageData => {
                return {
                    package_expiration_date: moment(packageData.get('expires_at'), 'YYYY-MM-DD').format('MM/DD/YY'),
                };
            },
            submitNormalizer: formData => {
                return {
                    expires_at: moment(formData.get('package_expiration_date'), 'MM/DD/YY').format('YYYY-MM-DD'),
                };
            },
            fields: [
                'package_expiration_date',
            ],
        };
    },

    get changePackageTrainerModalDialog() {
        return {
            getInitialValues: packageData => {
                return {
                    trainer: packageData.get('trainer'),
                };
            },
            submitNormalizer: formData => {
                return {
                    trainer: formData.get('trainer').value,
                };
            },
            fields: [
                'trainer',
            ],
        };
    },

    get changePackageCreditsModalDialog() {
        return {
            getInitialValues: packageData => {
                return {
                    package_credits_spinner: packageData.get('quantity_remaining'),
                };
            },
            getFieldProps: packageData => {
                return {
                    package_credits_spinner: {
                        minValue: 0,
                        maxValue: packageData.get('quantity_purchased'),
                    },
                };
            },
            submitNormalizer: formData => {
                return {
                    quantity_remaining: formData.get('package_credits_spinner'),
                };
            },
            fields: [
                'package_credits_spinner',
            ],
        };
    },
    get trainingBookingModalDialog() {
        return {
            getFieldProps: ({ programName, ownerId, form }) => {
                return {
                    total_charge: {
                        value: `1 ${programName} Session`,
                        label: `1 ${programName} Session`,
                    },
                    pickup_dropoff_address: { ownerId, form },
                    payment_information: {
                        disabled: true,
                        paymentValue: {
                            value: `1 ${programName} Session`,
                            label: `1 ${programName} Session`,
                        },
                    },
                };
            },
            submitNormalizer: classEventSubmitNormalizer,
            fields: [
                'pickup_dropoff_address',
                'payment_information',
                'total_charge',
            ],
        };
    },
    get calendarTrainingSearch() {
        return {
            fields: ['training_instructor', 'training_program'],
        };
    },
    get calendarClassesSearch() {
        return {
            fields: ['class_occurrences_location', 'class_occurrences_training_class'],
        };
    },
    get createPackageForm() {
        return {
            getFieldProps: () => {
                return {
                    package_size: {
                        minValue: 1,
                        maxValue: 32767,
                    },
                    package_expires_after: {
                        minValue: 0,
                        maxValue: 999999999,
                    },
                    package_price: {
                        minValue: 0.0,
                        maxValue: 9999999,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                };
            },
            submitNormalizer: packageNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40 package-create-modal__head',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0 package-create-modal__name',
                                    fields: ['package_name'],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['package_image'],
                        },
                    ],
                },
                'separator',
                'package_classes',
                'package_size',
                'package_expires_after',
                'package_price',
                'package_description',
            ],
        };
    },
    get editPackageForm() {
        return {
            getInitialValues: data => {
                const dataJS = data.size ? data.toJS() : data;
                return {
                    package_name: dataJS.name,
                    package_classes: dataJS.training_classes.map((value) => {
                        let label;
                        data.classes.forEach((item) => {
                            if (item.value === value) label = item.label;
                        });
                        return {
                            value,
                            label,
                            clearableValue: false,
                        };
                    }),
                    package_size: dataJS.quantity.toString(),
                    package_expires_after: getDaysInt(dataJS.expires_after).toString(),
                    package_price: formatMoney(dataJS.price),
                    package_description: dataJS.description,
                    package_image: dataJS.photo,
                };
            },
            getFieldProps: () => {
                return {
                    package_size: {
                        minValue: 1,
                        maxValue: 32767,
                    },
                    package_expires_after: {
                        minValue: 0,
                        maxValue: 999999999,
                    },
                    package_price: {
                        minValue: 0.0,
                        maxValue: 9999999,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                };
            },
            submitNormalizer: packageNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'package_name',
                                        'separator',
                                        'package_classes',
                                    ],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['package_image'],
                        },
                    ],
                },
                'package_size',
                'package_expires_after',
                'package_price',
                'package_description',
            ],
            fieldsWithoutClassesDropdown: [
                {
                    className: 'form__aligner form__aligner_60-40',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'package_name',
                                        'separator',
                                    ],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['package_image'],
                        },
                    ],
                },
                'package_size',
                'package_expires_after',
                'package_description',
            ],
        };
    },
    get createTrainingForm() {
        return {
            getFieldProps: () => {
                return {
                    program_sessions_size: {
                        minValue: 1,
                        maxValue: 32767,
                    },
                    program_expires_after: {
                        minValue: 0,
                        maxValue: 999999999,
                    },
                    program_price: {
                        minValue: 0.0,
                        maxValue: 9999999,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                    program_comission: {
                        minValue: 0,
                        maxValue: 100,
                    },
                };
            },
            submitNormalizer: trainingNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40 package-create-modal__head',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0 package-create-modal__name',
                                    fields: [
                                        'program_name',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_link_to_website',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_sessions_size',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_expires_after',
                                    ],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['program_image'],
                        },
                    ],
                },
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: [
                        'program_price',
                        'program_comission',
                    ],
                },
                'program_description',
            ],
        };
    },
    get editTrainingForm() {
        return {
            getInitialValues: data => {
                const dataJS = data.size ? data.toJS() : data;
                return {
                    program_name: dataJS.name,
                    program_link_to_website: dataJS.link_to_website,
                    program_sessions_size: dataJS.quantity.toString(),
                    program_expires_after: getDaysInt(dataJS.expires_after).toString(),
                    program_price: formatMoney(dataJS.price),
                    program_comission: dataJS.commission.toString(),
                    program_description: dataJS.description,
                    program_image: dataJS.photo,
                };
            },
            getFieldProps: () => {
                return {
                    program_sessions_size: {
                        minValue: 1,
                        maxValue: 32767,
                    },
                    program_expires_after: {
                        minValue: 0,
                        maxValue: 999999999,
                    },
                    program_price: {
                        minValue: 0.0,
                        maxValue: 9999999,
                        prefix: '$',
                        float: true,
                        disableSpinner: true,
                    },
                    program_comission: {
                        minValue: 0,
                        maxValue: 100,
                    },
                };
            },
            submitNormalizer: trainingNormalizer,
            fields: [
                {
                    className: 'form__aligner form__aligner_60-40',
                    fields: [
                        {
                            className: 'form__column',
                            fields: [
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_name',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_link_to_website',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_sessions_size',
                                    ],
                                },
                                {
                                    className: 'form__aligner_100-0',
                                    fields: [
                                        'program_expires_after',
                                    ],
                                },
                            ],
                        },
                        {
                            className: 'form__column',
                            fields: ['program_image'],
                        },
                    ],
                },
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: [
                        'program_price',
                        'program_comission',
                    ],
                },
                'program_description',
            ],
        };
    },
    get editCreateFormForm() {
        return {
            getInitialValues: formData => {
                const { notify_users, customer_dog, training_program, trainer, date, discount_price, preferred_date, name, } = formData;

                return {
                    notify_users_detail: notify_users,
                    customer_dog_detail: customer_dog,
                    training_program_for_detail: training_program,
                    trainer_for_detail: trainer,
                    date_for_detail: date,
                    discount_price: discount_price,
                    preferred_date_for_detail: preferred_date,
                    name,
                };
            },
            fields: [
                'edit_create_form_recipient',
                'customer_dog',
                'training_program',
                'trainer',
                'date',
                'discount_price',
                'preferred_date',
                'edit_create_form_name',
            ],
            submitNormalizer: formEditCreateDataNormalizer,
        };
    },
    get sellPackageModalDialog() {
        return {
            getInitialValues: ({ name, id }) => {
                return {
                    'customer': name || '',
                    'customerId': id || '',
                };
            },
            fields: [
                'sell_package_customer',
                'sell_package_select',
                'sell_package_payment_select',
                'sell_package_total_charge',
            ],
            submitNormalizer: formData => {
                return {
                    packageId: formData.get('package').value,
                    card: formData.get('card').value,
                    newCardNumber: formData.get('new_card_number'),
                    newCardExpirationMonth: formData.get('new_card_expiration_month'),
                    newCardExpirationYear: formData.get('new_card_expiration_year'),
                    newCardCvc: formData.get('new_card_cvc'),
                };
            },
        };
    },
    get sellTrainingModalDialog() {
        return {
            getInitialValues: ({ name, id }) => {
                return {
                    'customer': name || '',
                    'customerId': id || '',
                };
            },
            fields: [
                'sell_package_customer',
                'sell_training_dogs',
                'sell_training_programs_select',
                'sell_training_instructor',
                'sell_package_payment_select',
                'sell_training_discount',
                'sell_training_charge_info'
            ],
            submitNormalizer: formData => {
                console.log(formData.get('discount').value);
                return {
                    dog: formData.get('dog').value,
                    trainer: formData.get('trainer').value,
                    trainingProgram: formData.get('training_program').value,
                    card: formData.get('card').value,
                    newCardNumber: formData.get('new_card_number'),
                    newCardExpirationMonth: formData.get('new_card_expiration_month'),
                    newCardExpirationYear: formData.get('new_card_expiration_year'),
                    newCardCvc: formData.get('new_card_cvc'),
                    discount: formData.get('discount').value
                };
            },
        };
    },
    get trainerSellTrainingModalDialog() {
        return {
            getInitialValues: ({ name, id }, currentUser) => {
                return {
                    'customer': name || '',
                    'customerId': id || '',
                    'trainer': currentUser,
                };
            },
            fields: [
                'sell_package_customer',
                'sell_training_dogs',
                'sell_training_programs_select',
                'sell_training_instructor',
                'sell_package_payment_select',
                'sell_training_discount',
                'trainer_sell_training_charge_info'
            ],
            submitNormalizer: formData => {

                return {
                    dog: formData.get('dog').value,
                    trainer: formData.get('trainer').get("value"),
                    trainingProgram: formData.get('training_program').value,
                    card: formData.get('card').value,
                    newCardNumber: formData.get('new_card_number'),
                    newCardExpirationMonth: formData.get('new_card_expiration_month'),
                    newCardExpirationYear: formData.get('new_card_expiration_year'),
                    newCardCvc: formData.get('new_card_cvc'),
                    discount: formData.get('discount').value
                };
            },
        };
    },
    get customerTrainingModalDialog() {
        return {
            getInitialValues: ({ phone, email }) => {
                return {
                    'phone': phone || '',
                    'email': email || '',
                };
            },
            fields: [
                'training_dogs',
                'training_goals',
                'email',
                'phone',
            ],
        };
    },
    get customerAddNewDogModalDialog() {
        return {
            getInitialValues: () => {
                return {
                    is_altered: 'No',
                    allergies: 'No',
                    medical_notes_section: 'No',
                    can_contact_veterinarian: 'Yes',
                };
            },
            submitNormalizer: formData => {
                const year = formData.get('year').value;
                const month = formData.get('month').value;
                const intoleranceList = formData.get('intolerance_list');
                return {
                    name: formData.get('dog_name'),
                    gender: formData.get('gender').value,
                    weight: formData.get('weight').value,
                    breed: formData.get('breed').value,
                    veterinarian: formData.get('veterinarian_with_create') && formData.get(
                        'veterinarian_with_create').value,
                    can_contact_veterinarian: formData.get('can_contact_veterinarian').value,
                    intolerance_list: intoleranceList && intoleranceList.map(item => item.value),
                    is_altered: formData.get('is_altered') === 'Yes' ? 'true' : 'false',
                    birthday: `${year}-${month < 10 ? '0' + month : month}-01`,
                    medical_notes: formData.get('medical_notes'),
                };
            },
            fields: [
                'dog_name',
                'breed',
                'gender',
                'weight',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['month', 'year'],
                },
                'is_altered',
                'veterinarian_with_create',
                'separator',
                'allergies',
                'separator',
                'medical_notes_section',
            ],
        };
    },
    get customerEditDogInfoModalDialog() {
        return {
            getInitialValues: (dog) => {
                const info = dog.instance;
                let isAlerted = info.is_altered;
                if (typeof info.is_altered === 'string') {
                    isAlerted = info.is_altered === 'true';
                }
                return {
                    name: info.name,
                    breed: info.breed,
                    gender: info.gender,
                    weight: info.weight,
                    month: moment(info.birthday).get('month') + 1,
                    year: moment(info.birthday).get('year'),
                    is_altered: isAlerted ? 'Yes' : 'No',
                    markings: info.markings,
                };
            },
            submitNormalizer: CustomerDogDataNormalizer,
            updateNormalizer: CustomerDogDataNormalizer,
            fields: [
                'name',
                'breed',
                'gender',
                'weight',
                {
                    className: 'form__aligner form__aligner_50-50',
                    fields: ['month', 'year'],
                },
                'is_altered',
                'markings',
            ],
        };
    },
    get customerEditDogMedicalInfoModalDialog() {
        return {
            getInitialValues: (dog) => {
                const info = dog.instance;
                const canContact = info.can_contact_veterinarian === 'true' || info.can_contact_veterinarian === true;
                return {
                    intolerance_list: info.intolerance_list.map(item => {
                        return {
                            label: item,
                            value: item,
                            className: 'Select-create-option-placeholder',
                        };
                    }),
                    medical_notes: info.medical_notes,
                    can_contact_veterinarian: canContact ? 'Yes' : 'No',
                    veterinarian_with_create: info.veterinarian,
                };
            },
            submitNormalizer: CustomerDogMedicalDataNormalizer,
            updateNormalizer: CustomerDogMedicalDataNormalizer,
            fields: [
                'veterinarian_with_create',
                'intolerance_list',
                'medical_notes',
            ],
        };
    },
    get customerDeleteDog() {
        return {
            submitNormalizer: formData => {
                const data = formData.toJS();
                return {
                    removal_reason: data.dog_delete_select.value,
                };
            },
            fields: ['dog_delete_select'],
        };
    },
    get editUpcomingClassModalDialog() {
        return {
            getInitialValues: ({ context, classData }) => {
                const card = classData.getIn(['purchased_package_detail'])
                    ? {
                        value: classData.getIn(['purchased_package_detail', 'id']),
                        type: 'package',
                        label: `1 ${classData.getIn(['purchased_package_detail', 'package_detail', 'name'])} Credit`,
                        defaultCardId: classData.getIn(['card_detail', 'id']),
                    }
                    : classData.getIn(['card_detail', 'id']);

                return {
                    context,
                    dogId: classData.get('dog'),
                    customerId: classData.getIn(['owner_detail', 'id']),
                    classId: classData.get('id'),
                    classTypeId: classData.getIn(['class_occurrence_detail', 'training_class_detail', 'id']),
                    pickup_location: classData.get('pickup_location'),
                    drop_off_location: classData.get('drop_off_location'),
                    card,
                    valid_zip_codes: classData.getIn(
                        ['class_occurrence_detail', 'valid_zip_codes_array']) !== undefined
                        ? classData.getIn(['class_occurrence_detail', 'valid_zip_codes_array'])
                            .toJS()
                        : [],
                    purchased_package: classData.getIn(['purchased_package_detail', 'id']),
                };
            },
            getFieldProps: ({ classData, form }) => {
                return {
                    pickup_dropoff_address: {
                        ownerId: classData.getIn(['owner_detail', 'id']),
                        eventZipCodes: classData.getIn(
                            ['class_occurrence_detail', 'valid_zip_codes_array']) !== undefined
                            ? classData.getIn(['class_occurrence_detail', 'valid_zip_codes_array'])
                                .toJS()
                            : [],
                        form,
                    },
                    payment_information: {
                        ownerId: classData.getIn(['owner_detail', 'id']),
                        classTypeId: classData.getIn(['class_occurrence_detail', 'training_class_detail', 'id']),
                    },
                };
            },
            submitNormalizer: classEventSubmitNormalizer,
            fields: [
                'pickup_dropoff_address',
                'payment_information',
            ],
        };
    },
    get editTrainingModalDialog() {
        return {
            getInitialValues: ({ context, classData }) => {
                return {
                    context,
                    dogId: classData.getIn(['dog_detail', 'id']),
                    customerId: classData.getIn(['owner_detail', 'id']),
                    classId: classData.get('id'),
                    pickup_location: classData.get('pickup_location'),
                    drop_off_location: classData.get('drop_off_location'),
                    card: classData.getIn(['purchased_package_detail', 'id']) || classData.getIn(['card_detail', 'id']),
                    eventId: classData.get('event'),
                    productId: classData.get('id'),
                };
            },
            getFieldProps: ({ program_name, classData, form }) => {
                let paymentInformationData;
                if (program_name === undefined) {
                    paymentInformationData = '1 Training Program Session';
                } else {
                    paymentInformationData = `1 ${program_name} Session`;
                }

                return {
                    pickup_dropoff_address: {
                        ownerId: classData.getIn(['owner_detail', 'id']),
                        eventZipCodes: undefined,
                        form,
                    },
                    payment_information: {
                        disabled: true,
                        paymentValue: {
                            value: paymentInformationData,
                            label: paymentInformationData,
                        },
                    },
                };
            },
            submitNormalizer: classEventSubmitNormalizer,
            fields: [
                'pickup_dropoff_address',
                'payment_information',
            ],
        };
    },
    get classBookingModalDialog() {
        return {
            getFieldProps: ({ ownerId, eventPrice, classTypeId, eventZipCodes, form }) => {
                return {
                    total_charge: {
                        value: '$' + eventPrice,
                        label: '$' + eventPrice,
                    },
                    pickup_dropoff_address: {
                        ownerId,
                        eventZipCodes,
                        form,
                    },
                    payment_information: {
                        ownerId,
                        classTypeId,
                    },
                };
            },
            submitNormalizer: classEventSubmitNormalizer,
            fields: [
                'pickup_dropoff_address',
                'payment_information',
                'total_charge',
            ],
        };
    },
    get additionalContactsForm() {
        return {
            getInitialValues: (data) => {
                return {
                    name: data.name,
                    phone: data.phone_number,
                    relationship: data.relationship,
                };
            },
            submitNormalizer: formData => {
                return {
                    name: formData.get('name'),
                    phone_number: formData.get('phone'),
                    relationship: formData.get('relationship'),
                };
            },
            fields: [
                'name',
                'phone',
                'relationship',
            ],
        };
    },
    get addCreditCardFrom() {
        return {
            fields: [
                'credit_card',
            ],
        };
    },
    get messagesWidgetForm() {
        return {
            submitNormalizer: messageNormalizer,
            fields: ['message_input', 'message_photo'],
        };
    },
    get OverviewFilterForm() {
        return {
            getInitialValues: (start, end) => {
                return {
                    overview_date_interval: { start, end },
                };
            },
            fields: ['overview_date_interval'],
        };
    },
    get saveAsCampaignModalDialog() {
        return {
            getInitialValues: (params) => {
                const { target, date, report } = params;
                const result = { target };
                if (report === 'rebooking-percentage') {
                    result.params = {};
                    return result;
                }
                if (date.start) {
                    const { start, end } = date;
                    const date_begin = dateToServerFormat(start, false);
                    const date_end = dateToServerFormat(end, false);
                    result.params = { date_begin, date_end };
                } else {
                    const selected_date = dateToServerFormat(date, false);
                    result.params = { selected_date };
                }
                return result;
            },
            fields: ['save_as_campaign_name'],
        };
    },
};

export const getFormDefinition = formName => {
    return forms[formName];
};

export const getForm = ({ formName, formProps, initialValues, isOpened, additionalData, useFreeCreditPackageFields }) => {
    const form = getFormDefinition(formName);
    let fields = form ? form.fields : [];
    if (useFreeCreditPackageFields) {
        fields = form.fieldsWithoutClassesDropdown;
    }
    const isFieldDisabled = form ? form.isFieldDisabled : false;

    const renderFields = fields => {
        return fields.map((field, i) => {
            if (typeof field !== 'string') {
                // group field
                return (
                    <div className={field.className} key={'group_' + i}>
                        {field.title !== undefined &&
                            typeof field.title === 'string' &&
                            <div className='form__field-description'>{field.title}</div>}
                        {renderFields(field.fields)}
                    </div>
                );
            }

            if (field === 'separator') {
                return (
                    <div key={'separator_' + i}>
                        {FieldFactory.getField({ fieldName: field, initialValues, formProps, isOpened })}
                    </div>
                );
            }

            return FieldFactory.getField({
                key: i,
                fieldName: field,
                initialValues,
                formProps,
                isOpened,
                additionalData,
                isDisabled: isFieldDisabled ? isFieldDisabled(formProps, field) : false,
            });
        });
    };

    return renderFields(fields);
};
