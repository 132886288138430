import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconAdd } from '../../../assets/icons/icon-add.svg';
import { ReactComponent as IconAmericanExpress } from '../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../assets/icons/icon-visa.svg';
import Icon from '../../common/Icon/Icon';
import AvatarPlaceholder from '../AvatarPlaceholder';

const cardIcons = {
    'visa': IconVisa,
    'master-card': IconMasterCard,
    'american-express': IconAmericanExpress
};

export const dogCreationOptionRenderer = (data) => {
    return (
        <div className='select-control__overflow'>
            <div>{data.label}</div>
            <div className='select-control__extra'>{data.extra}</div>
        </div>
    );
};

export const classEventDogOptionRenderer = (data) => {
    const optionClassNames = classNames({
        'select-control__overflow option-event': true,
        'option-event__unavailable': !data.extra.isAvailable
    });
    const accessLevelClassNames = classNames({
        'option-event__dog-info_right': true,
        'option-event__warning': data.extra.accessLevel < data.extra.eventAccessLevel
    });

    const getRightBlock = () => {
        if (!data.extra.isAvailable) {
            return (
                <div className='option-event__dog-info_right'>
                    <div className='option-event__unavailable-access-level'>{data.extra.accessLevelDetail}</div>
                    <div className='option-event__unavailable-text'>
                        Already Scheduled for a Class. The dog is not available for booking.
                    </div>
                </div>
            );
        }

        if (data.extra.dog_age_months < 3) {
            return (
                <div className='option-event__dog-info_right'>
                    <div className='option-event__unavailable-access-level'>{data.extra.accessLevelDetail}</div>
                    <div className='option-event__unavailable-text'>
                        Does not meet age requirement.
                    </div>
                </div>
            );
        }

        if (data.extra.is_puppy_class & !data.extra.is_puppy) {
            return (
                <div className='option-event__dog-info_right'>
                    <div className='option-event__unavailable-access-level'>{data.extra.accessLevelDetail}</div>
                    <div className='option-event__unavailable-text'>
                        This class is available only for dogs from 3 to 6 months
                    </div>
                </div>
            );
        }

        return <div className={accessLevelClassNames}>{data.extra.accessLevelDetail}</div>;
    };

    return (
        <div className={optionClassNames}>
            <div className='option-event__dog-info'>
                {data.extra.photo !== null
                    ? (<div
                        className='option-event__avatar'
                        style={{
                            background: `url("${data.extra.photo}") no-repeat center center / cover`
                        }}/>)
                    : (<div className='option-event__avatar'><AvatarPlaceholder type='dog' size={36}/></div>)
                }
                <div>
                    <div>{data.label}</div>
                    <div className='option-event__secondary'>{data.extra.owner}</div>
                </div>
            </div>
            {getRightBlock()}
        </div>
    );
};

export const trainingEventDogOptionRenderer = (data) => {
    const optionClassNames = classNames({
        'select-control__overflow option-event': true,
        'option-event__unavailable': !data.disabled
    });
    const sessionsRemaining = classNames({
        'option-event__warning': data.extra.sessions === 0
    });

    const getRightBlock = () => {
        if (data.extra.isScheduled) {
            return (
                <div className='option-event__dog-info_right'>
                    <div className='option-event__unavailable-access-level'>{data.extra.sessions}</div>
                    <div className='option-event__unavailable-text'>Already Scheduled for the Training</div>
                </div>
            );
        }

        return <div className={sessionsRemaining}>{data.extra.sessions}</div>;
    };

    return (
        <div className={optionClassNames}>
            <div className='option-event__dog-info'>
                {data.extra.photo !== null
                    ? (<div
                        className='option-event__avatar'
                        style={{
                            background: `url("${data.extra.photo}") no-repeat center center / cover`
                        }} />)
                    : (<div className='option-event__avatar'><AvatarPlaceholder type='dog' size={36} /></div>)
                }
                <div>
                    <div>{data.label}</div>
                    <div className='option-event__secondary'>{data.extra.owner}</div>
                </div>
            </div>
            {getRightBlock()}
        </div>
    );
};

export const availableTrainerOptionRenderer = (data) => {
    const getExtra = () => {
        if (data.disabled) {
            return <div className='select-control__extra'>Has not sold the training</div>;
        }

        return null;
    };

    return (
        <div className='select-control__overflow'>
            <div>{data.label}</div>
            <div className='select-control__extra'>{getExtra()}</div>
        </div>
    );
};

export const paymentCardsOptionRenderer = (data) => {
    if ('value' in data && data.value !== 0) {
        const currIcon = cardIcons[data.type] || IconNeutralCard;
        return (
            <div className='select-control__overflow option-card'>
                <Icon
                    glyph={currIcon}
                    className='icon-payment-card-option card-detection__item card-detection__item_visa'/>
                <span className='option-card__label'>{data.label}</span>
            </div>
        );
    }
    return (
        <div className='select-control__overflow option-card'>
            <Icon glyph={IconAdd} className='icon-add-card'/>
            <span className='option-card__label'>Add New Card</span>
        </div>
    );
};

export const formRecipientOptionRenderer = data => {
    const { dogs = 'bob', email = '', role = '', photo, isGroup } = data.extra;
    const renderAvatar = () => {
        if (photo) {
            return (
                <div
                    className='option-form-recipient__avatar'
                    style={{
                        background: `url("${photo}") no-repeat center center / cover`
                    }}/>
            );
        }

        return (
            <div className='option-form-recipient__avatar'>
                <AvatarPlaceholder size={36} className='form-edit-create-page__search-avatar'/>
            </div>
        );
    };

    return (
        <div className='select-control__overflow option-form-recipient'>
            <div className='option-form-recipient__info'>
                {
                    !isGroup && renderAvatar()
                }
                <div className='option-form-recipient__text'>
                    {!isGroup ? data.label : data.label.toUpperCase()}
                    {
                        !isGroup && <div className='option-form-recipient__secondary'>{email}</div>
                    }

                </div>
            </div>
            <div className='option-form-recipient__info_right'>{role}</div>
        </div>
    );
};
