import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const TabSwitcher = ({ blockClassName, className, items, activePage, onNavbarClick }) => {
    const clickHandler = pageName => {
        if (pageName === activePage) {
            return false;
        }

        onNavbarClick(pageName);
        return undefined;
    };

    const getTabSwitcherClassName = pageName => {
        const defaultClassName = `${blockClassName}__item`;
        const activeClassName = defaultClassName + ` ${blockClassName}__item_active`;

        if (pageName === activePage) {
            return activeClassName;
        }

        return defaultClassName;
    };

    const switcherClassName = classNames([blockClassName, className]);
    return (
        <nav className={switcherClassName}>
            <div className={`${blockClassName}__list`}>
                {items.map((item) => {
                    return (
                        <a
                            key={item.page}
                            href=''
                            onClick={
                                e => {
                                    e.preventDefault();
                                    clickHandler(item.page);
                                }
                            }
                            className={getTabSwitcherClassName(item.page)}>
                            {item.title}
                        </a>
                    );
                })}
            </div>
        </nav>
    );
};

TabSwitcher.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    blockClassName: PropTypes.string.isRequired,
    activePage: PropTypes.string.isRequired,
    className: PropTypes.string,
    onNavbarClick: PropTypes.func.isRequired
};

export default TabSwitcher;
