/* eslint-disable camelcase */
import * as axios from 'axios';
import { fromJS, Map } from 'immutable';
import * as FormFactory from '../../components/common/Form/formFactory';
import { formatDateToSlashes } from '../../helpers/date';
import { getUserNameSpace, isTrainer } from '../../helpers/userRoles';
import { getReports } from '../dashboard/dogReportsActions';
import * as ModalActions from './modalActions';
import * as ModalSidebarActions from './modalSidebarActions';
import { store } from '../../index';

export const openPaymentCardDeleteModalDialog = (cardData, userData) => {
    return (dispatch, getState) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateCurrentCustomerPaymentCards',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'CUSTOMER_EDIT',
                alertData: { name: getState().getIn(['currentCustomer', 'instance', 'full_name']) },
                itemId: cardData.get('id'),
                url: `/v1/${getUserNameSpace('customers.payment-cards')}/customers/${userData.get('id')}/` +
                    `payment-cards/${cardData.get('id')}/`,
                title: 'Remove Card',
                text: `Are you sure you would like to remove the ${cardData.get('type')} card ending in *${cardData.get(
                    'last4',
                )}?`,
            }),
        );
    };
};

export const openUserAgreementDeleteModalDialog = (agreementData, userData) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateFormsTable',
                errorAction: 'handleModalSubmitError',
                itemId: agreementData.get('id'),
                url: `/v1/${getUserNameSpace('users.agreements')}/users/${userData.get('id')}/agreements/` +
                    `${agreementData.get('id')}/`,
                title: 'Delete Form',
                text: `Are you sure you would like to delete the ${agreementData.get('name')} form?`,
            }),
        );
    };
};

export const openUserAgreementEmployeeDeleteModalDialog = agreementData => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateFormsTableEmployee',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'FORM_DELETE',
                alertData: { name: agreementData.name },
                itemId: agreementData.id,
                url: `/v1/${getUserNameSpace('agreements')}/agreements/${agreementData.id}/`,
                title: 'Delete Form',
                text: `Are you sure you would like to delete the ${agreementData.name} form?`,
            }),
        );
    };
};

export const openVaccinationDeleteModalDialog = (vaccinationData, userData) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateCurrentCustomerVaccinationRecords',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOCUMENT_DELETE',
                alertData: { fileName: vaccinationData.get('document_file_name') },
                itemId: vaccinationData.get('id'),
                url: `/v1/${getUserNameSpace('customers.immunizations-docs')}/customers/${userData.get('id')}/` +
                    `immunizations-docs/${vaccinationData.get('id')}/`,
                title: 'Delete Vaccination Record',
                text: `Are you sure you would like to delete the ${vaccinationData.get('document_file_name')} file?`,
            }),
        );
    };
};

export const openDogDeleteModalDialog = dogData => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                title: `Delete ${dogData.get('name')}`,
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                itemId: dogData.get('id'),
                updateAction: 'switchToDogsList',
                submitAction: 'deleteItem',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_DELETE',
                alertData: { name: dogData.get('name') },
                buttonText: 'Delete',
                text: `Are you sure you would like to delete ${dogData.get('name')}?`,
                extraText:
                    'Once deleted, all profile and dog data will be removed forever and all classes will be canceled.',
            }),
        );
    };
};

export const openDogInfoEditModalDialog = dogData => {
    const form = 'dogInfoEditModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Edit ${dogData.get('name')}'s info`,
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_EDIT',
                alertData: { name: dogData.get('name') },
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogData),
            }),
        );
    };
};

export const openDogNameEditModalDialog = dogData => {
    const form = 'dogNameEditModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Edit dog\'s name',
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_EDIT',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogData),
            }),
        );
    };
};

export const openDogIntoleranceListEditModalDialog = dogData => {
    const form = 'dogIntoleranceListEditModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Edit ${dogData.get('name')}'s allergies or intolerances`,
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_EDIT',
                alertData: { name: dogData.get('name') },
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogData),
            }),
        );
    };
};

export const openDogMedicalNotesEditModalDialog = dogData => {
    const form = 'dogMedicalNotesEditModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Edit ${dogData.get('name')}'s medical notes`,
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                text: 'Enter any medical information below that may be helpful.',
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_EDIT',
                alertData: { name: dogData.get('name') },
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogData),
            }),
        );
    };
};

export const openDogAccessLevelEditModalDialog = dogData => {
    const form = 'dogAccessLevelEditModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Edit ${dogData.get('name')}'s access level`,
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'DOG_ACCESS_LEVEL',
                alertData: { name: dogData.get('name'), prev: dogData.get('access_level_detail') },
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogData),
            }),
        );
    };
};

export const openDogMarkDeceasedActionModalDialog = dogData => {
    return (dispatch, getState) => {
        const isDogDeceased = getState().getIn(['currentDog', 'instance', 'is_deceased']);
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                title: isDogDeceased ? 'Unmark dog as deceased' : 'Mark dog as deceased',
                text: isDogDeceased
                    ? `Are you sure you would like to unmark ${dogData.get('name')} as deceased?`
                    : `Are you sure you would like to mark ${dogData.get('name')} as deceased?`,
                extraText: isDogDeceased
                    ? 'Once you unmark a dog as deceased, the owner will be able to book any classes for the dog.'
                    : 'Once you mark a dog as deceased, the owner will not be able to book any classes for the dog.',
                buttonText: isDogDeceased ? 'Unmark as deceased' : 'Mark as deceased',
                url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                updateData: isDogDeceased ? { is_deceased: false } : { is_deceased: true },
                submitAction: 'markDogDeceased',
                updateAction: 'updateCurrentDog',
                errorAction: 'handleModalSubmitError',
                alertEvent: isDogDeceased ? 'DOG_NOT_DECEASED' : 'DOG_DECEASED',
                alertData: { name: dogData.get('name') },
            }),
        );
    };
};

export const openDogVeterinarianEditCreateTabModalDialog = dogData => {
    return dispatch => {
        // Should reset the form in modalActions getting the name from modal.data.
        dispatch(
            ModalActions.onModalOpen({
                type: 'tabModal',
                title: `Edit ${dogData.get('name')}'s veterinarian`,
                overflowShown: true,
                activeForm: 0,
                forms: [
                    {
                        url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                        submitAction: 'editItem',
                        updateAction: 'updateCurrentDog',
                        errorAction: 'handleModalSubmitError',
                        alertEvent: 'DOG_EDIT',
                        alertData: { name: dogData.get('name') },
                        form: 'dogVeterinarianEditModalDialog',
                        initialValues: FormFactory.getFormDefinition('dogVeterinarianEditModalDialog').getInitialValues(
                            dogData,
                        ),
                        tabName: 'Select from List of Vets',
                    },
                    {
                        url: `/v1/${getUserNameSpace('customers.veterinarian')}/customers/${dogData.get('owner')}/` +
                            'veterinarian/',
                        dogUrl: `/v1/${getUserNameSpace('dogs')}/dogs/${dogData.get('id')}/`,
                        submitAction: 'setDogVeterinarian',
                        updateAction: 'updateCurrentDogVetFromResponse',
                        errorAction: 'handleModalSubmitError',
                        alertEvent: 'DOG_EDIT',
                        alertData: { name: dogData.get('name') },
                        form: 'dogVeterinarianCreateModalDialog',
                        initialValues: FormFactory.getFormDefinition('dogVeterinarianCreateModalDialog')
                            .getInitialValues(),
                        tabName: 'Vet isn\'t listed',
                    },
                ],
            }),
        );
    };
};

export const openCancelCustomersUpcomingClassModalDialog = classData => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'cancelWaivingCustomersUpcomingClass',
                cancelAction: 'cancelChargingCustomersUpcomingClass',
                updateAction: 'updateCurrentCustomerClasses',
                errorAction: 'handleModalSubmitError',
                classData,
                title: 'Charge late fee?',
                // eslint-disable-next-line max-len
                text: `You are cancelling ${classData.dog_detail.name} attendance within the two hour cancellation window.`,
                extraText: 'Would you like to waive the late cancellation penalty?',
                yesButtonText: 'No, charge late cancellation fee',
                submittingText: 'Charging late cancellation fee...',
                noButtonText: 'Yes, waive late cancellation fee',
                cancelingText: 'Waiving late cancellation fee...',
                isChargingFeeSubmit: true,
            }),
        );
    };
};

export const openCancelDogsUpcomingClassModalDialog = classData => {
    const { noNotification } = classData;
    const notificationText = noNotification ?
        'A notification will not be sent to the owner.' :
        'A notification will be sent to the owner';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'cancelWaivingDogsUpcomingClass',
                cancelAction: 'cancelChargingDogsUpcomingClass',
                updateAction: 'updateCurrentDogClasses',
                errorAction: 'handleModalSubmitError',
                classData,
                title: 'Charge late fee?',
                // eslint-disable-next-line max-len
                text: `You are cancelling ${classData.dog_detail.name} attendance within the two hour cancellation window. ${notificationText}`,
                extraText: 'Would you like to waive the late cancellation penalty?',
                yesButtonText: 'No, charge late cancellation fee',
                submittingText: 'Charging late cancellation fee...',
                noButtonText: 'Yes, waive late cancellation fee',
                cancelingText: 'Waiving late cancellation fee...',
                isChargingFeeSubmit: true,
            }),
        );
    };
};

export const openCreateCustomerModalDialog = () => {
    const form = 'createCustomerModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Create new customer',
                url: `/v1/${getUserNameSpace('customers')}/customers/`,
                submitAction: 'postItem',
                alertEvent: 'CUSTOMER_CREATE',
                updateAction: 'updateCustomersList',
                errorAction: 'handleModalSubmitError',
                form,
            }),
        );
    };
};

export const openCreateDogReportModalDialog = ({ isEventBased, data }) => {
    const form = 'createDogReportModalDialog';
    if (isEventBased) {
        return dispatch => {
            dispatch(getReports(data.dog))
                .then(response => {
                    dispatch(
                        ModalActions.onModalOpen({
                            type: 'editCreateModal',
                            title: 'Create new snapshot',
                            url: `/v1/${getUserNameSpace('dog-reports')}/dog-reports/`,
                            submitAction: 'postItem',
                            submitButtonText: 'Create snapshot',
                            updateAction: 'updateReports',
                            errorAction: 'handleModalSubmitError',
                            shouldPassValues: true,
                            initialValues: FormFactory.getFormDefinition(form).getInitialValues(data),
                            form,
                        }),
                    );
                });
        };
    }
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Create new snapshot',
                url: `/v1/${getUserNameSpace('dog-reports')}/dog-reports/`,
                submitAction: 'postItem',
                submitButtonText: 'Create snapshot',
                updateAction: 'updateCurrentDogReports',
                errorAction: 'handleModalSubmitError',
                shouldPassValues: true,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(),
                form,
            }),
        );
    };
};

export const openEditDogReportModalDialog = ({ isEventBased, data }) => {
    const form = 'editDogReportModalDialog';
    if (isEventBased) {
        return dispatch => {
            dispatch(getReports(data.dog))
                .then(response => {
                    dispatch(
                        ModalActions.onModalOpen({
                            type: 'editCreateModal',
                            title: 'Edit snapshot',
                            url: `/v1/${getUserNameSpace('dog-reports')}/dog-reports/${data.id}/`,
                            submitAction: 'editItem',
                            submitButtonText: 'Edit snapshot',
                            updateAction: 'updateCurrentDogReportData',
                            errorAction: 'handleModalSubmitError',
                            shouldPassValues: true,
                            initialValues: FormFactory.getFormDefinition(form).getInitialValues(data),
                            form,
                        }),
                    );
                });
        };
    }
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Edit snapshot',
                url: `/v1/${getUserNameSpace('dog-reports')}/dog-reports/${data.id}/`,
                submitAction: 'editItem',
                submitButtonText: 'Edit snapshot',
                updateAction: 'updateCurrentDogReportData',
                errorAction: 'handleModalSubmitError',
                shouldPassValues: true,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(data),
                form,
            }),
        );
    };
};

export const openDeleteDogReportModalDialog = ({ isEventBased, data }) => {
    return (dispatch, getState) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                title: 'Delete snapshot',
                url: `/v1/${getUserNameSpace('dog-reports')}/dog-reports/${data.id}/`,
                itemId: data.id,
                submitAction: 'deleteItem',
                updateAction: 'deleteCurrentDogReportCard',
                buttonText: 'Delete',
                text: `Are you sure you would like to delete ${data.title} snapshot?`,
            }),
        );
    };
};

export const openCustomersPackageDeleteModalDialog = packageData => {
    let productName;
    let url;
    if (packageData.get('model_type') === 'purchasedpackage') {
        productName = 'Package';
        url = `/v1/${getUserNameSpace('customers.purchased-packages')}/customers/${packageData.get('customerId')}/` +
            `purchased-packages/${packageData.get('id')}/`;
    }
    if (packageData.get('model_type') === 'purchasedtrainingprogram') {
        productName = 'Training Program';
        url = `/v1/${getUserNameSpace('purchased-training-programs')}/purchased-training-programs/` +
            `${packageData.get('id')}/`;
    }
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                title: `Delete ${productName}`,
                url,
                itemId: packageData.get('id'),
                submitAction: 'deleteItem',
                updateAction: 'removeCustomerPackageOrTrainingProduct',
                errorAction: 'handleModalSubmitError',
                buttonText: 'Delete',
                text: `Are you sure you would like to delete ${productName} ${packageData.get(
                    'name')}?`,
            }),
        );
    };
};

export const openCustomersChangePackageTrainerModalDialog = packageData => {
    let productName;
    let url;
    if (packageData.get('model_type') === 'purchasedpackage') {
        productName = 'Package';
        url = `/v1/${getUserNameSpace('customers.purchased-packages')}/customers/${packageData.get('customerId')}/` +
            `purchased-packages/${packageData.get('id')}/`;
    }
    if (packageData.get('model_type') === 'purchasedtrainingprogram') {
        productName = 'Training Program';
        url = `/v1/${getUserNameSpace('purchased-training-programs')}/purchased-training-programs/` +
            `${packageData.get('id')}/`;
    }
    const form = 'changePackageTrainerModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Change trainer for ${productName}`,
                url,
                submitAction: 'editItem',
                updateAction: 'updateCustomersPackageOrTrainingProductTrainer',
                errorAction: 'handleModalSubmitError',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(packageData),
                overflowShown: true,
                text: `You are editing the trainer for ${productName} ${packageData.get('name')}`,
            }),
        );
    };
};

export const openCustomersChangePackageExpirationDateModalDialog = packageData => {
    let productName;
    let url;
    if (packageData.get('model_type') === 'purchasedpackage') {
        productName = 'Package';
        url = `/v1/${getUserNameSpace('customers.purchased-packages')}/customers/${packageData.get('customerId')}/` +
            `purchased-packages/${packageData.get('id')}/`;
    }
    if (packageData.get('model_type') === 'purchasedtrainingprogram') {
        productName = 'Training Program';
        url = `/v1/${getUserNameSpace('purchased-training-programs')}/purchased-training-programs/` +
            `${packageData.get('id')}/`;
    }
    const form = 'changePackageExpirationDateModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Change ${productName} expiration date`,
                url,
                submitAction: 'editItem',
                updateAction: 'updateCustomersPackageOrTrainingProductExpiresAt',
                errorAction: 'handleModalSubmitError',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(packageData),
                overflowShown: true,
                text: `You are editing the expiration date for ${productName} ${packageData.get('name')}`,
            }),
        );
    };
};

export const openCustomersManagePackageCreditsModalDialog = packageData => {
    let productName;
    let url;
    if (packageData.get('model_type') === 'purchasedpackage') {
        productName = 'Package';
        url = `/v1/${getUserNameSpace('customers.purchased-packages')}/customers/${packageData.get('customerId')}/` +
            `purchased-packages/${packageData.get('id')}/`;
    }
    if (packageData.get('model_type') === 'purchasedtrainingprogram') {
        productName = 'Training';
        url = `/v1/${getUserNameSpace('purchased-training-programs')}/purchased-training-programs/` +
            `${packageData.get('id')}/`;
    }
    const form = 'changePackageCreditsModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Add/Remove ${productName} Sessions`,
                url,
                submitAction: 'editItem',
                updateAction: 'updateCustomersPackageOrTrainingProductQuantityRemaining',
                errorAction: 'handleModalSubmitError',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(packageData),
                fieldProps: FormFactory.getFormDefinition(form).getFieldProps(packageData),
                text: `You are editing the ${productName} sessions for ${packageData.get('name')}`,
            }),
        );
    };
};

export const openCreateClassModalDialog = () => {
    const form = 'createClassModalDialog';
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'editCreateModal',
            title: 'Create new class',
            url: `/v1/${getUserNameSpace('classes')}/classes/`,
            photoUrl: `/v1/${getUserNameSpace('classes.photo')}/classes/{id}/photo/`,
            submitAction: 'postItem',
            submitButtonText: 'Create',
            updateAction: 'updateClasses',
            errorAction: 'handleModalSubmitError',
            alertEvent: 'CLASS_CREATE',
            error: null,
            shouldPassValues: true,
            initialValues: FormFactory.getFormDefinition(form).getInitialValues(),
            fieldProps: FormFactory.getFormDefinition(form).getFieldProps(),
            form,
        }));
    };
};

export const openConfirmClassCancellationModalDialog = (modalData) => {
    store.dispatch(ModalActions.onModalOpen({
        type: 'confirmClassCancellation',
        title: 'Confirm Class Cancellation',
        modalData,
    }));
}

export const openEditClassModalDialog = classData => {
    const form = 'editClassModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Edit class',
                url: `/v1/${getUserNameSpace('classes')}/classes/${classData.get('id')}/`,
                photoUrl: `/v1/${getUserNameSpace('classes.photo')}/classes/{id}/photo/`,
                submitAction: 'editItem',
                submitButtonText: 'Save',
                updateAction: 'updateClassForClassesSchedule',
                errorAction: 'handleClassScheduleEditError',
                alertEvent: 'CLASS_EDIT',
                shouldPassValues: false,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(classData),
                fieldProps: FormFactory.getFormDefinition(form).getFieldProps(classData),
                form,
            }),
        );
    };
};

export function openClassBookingModalDialog(formConfig, formData) {
    const form = 'classBookingModalDialog';
    const formValue = formData.toJS().dog;
    const dogName = formValue.label;
    const dogId = formValue.value;
    const ownerId = formValue.extra.ownerId;
    const owner = formValue.extra.owner;
    const className = formConfig.eventFormValues.training_class.label;
    const eventId = formConfig.currentEvent.getIn(['event', 'id']);
    const eventStartDate = formConfig.eventFormValues.start_date;
    const eventPrice = formConfig.eventFormValues.training_class.extra.price;
    const eventZipCodes = formConfig.currentEvent.getIn(['event', 'valid_zip_codes_array']) || fromJS([]);
    const classTypeId = formConfig.eventFormValues.training_class.value;

    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Book class ${className}`,
                url: `/v1/${getUserNameSpace('customers.class-occurrences')}/customers/${ownerId}/class-occurrences/` +
                    `${eventId}/book/`,
                submitAction: 'bookDogToEvent',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'Book',
                text: `You are booking ${dogName} for the ${className} scheduled on ${eventStartDate}`,
                additionalData: { eventType: 'class', ownerId, owner, dogId },
                fieldProps: FormFactory.getFormDefinition(form).getFieldProps({
                    ownerId, owner, eventPrice, classTypeId, eventZipCodes, form,
                }),
                form,
            }),
        );
    };
};


export function openTrainingBookingModalDialog(formConfig, formData) {
    const form = 'trainingBookingModalDialog';
    return dispatch => {
        const eventId = formConfig.currentEvent.getIn(['event', 'id']);
        const formValue = formData.toJS().dog;
        const programName = formConfig.eventFormValues.program.label;
        const dogName = formValue.label;
        const dogId = formValue.value;
        const ownerId = formValue.extra.ownerId;
        const product = formValue.extra.product;
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Book ${programName}`,
                url: `/v1/${getUserNameSpace('training-events.products')}/training-events/${eventId}/products/`,
                submitAction: 'bookDogToEvent',
                errorAction: 'handleTrainingEventProductPostError',
                submitButtonText: 'Book',
                text: `You are booking ${dogName} for the ${programName}`,
                additionalData: { eventType: eventId ? 'training' : 'new_training', product, ownerId, dogId, formData },
                fieldProps: FormFactory.getFormDefinition(form).getFieldProps({ programName, ownerId, form }),
                form,
            }),
        );
    };
};

export const openEventCancellationModalDialog = eventData => {
    const numOfDogs = eventData.products.length;
    const pluralOrSingular = numOfDogs > 1 ? 'dogs' : 'dog';
    const resourceMap = {
        class: 'class-occurrences',
        training: 'training-events-composite',
    };
    const modalConfig = {
        type: 'yesNoModal',
        title: `Cancel ${eventData.event.type}`,
        text: `Are you sure you want to cancel this ${eventData.event.type}?`,
        extraText: numOfDogs
            ? `As the result, ${numOfDogs} ${pluralOrSingular} will be cancelled from the ${eventData.event.type}.`
            : undefined,
        submitAction: 'deleteItem',
        updateAction: 'switchToCalendar',
        url: `/v1/${getUserNameSpace(resourceMap[eventData.event.type])}/${resourceMap[eventData.event.type]}/` +
            `${eventData.event.id}/`,
    };

    return dispatch => {
        dispatch(ModalActions.onModalOpen(modalConfig));
    };
};

export const openEventProductCancellationModalDialog = (eventData) => {
    if (eventData.type === 'class') {
        return dispatch => {
            const numOfDogs = eventData.productsToCancel.length;
            const namesOfDogs = eventData.productsToCancel.map(product => product.dog_detail.name).join(', ');
            const pluralOrSingular = numOfDogs > 1 ? 'dogs' : 'dog';

            dispatch(ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Editing a class event',
                submitAction: 'forceClassEventEditSubmit',
                updateAction: 'reinitializeEventForm',
                text: `The changes you made to this class will get ${numOfDogs} ${pluralOrSingular} (${namesOfDogs})
                cancelled from the class.`,
                extraText: 'Continue?',
                parentFormData: eventData,
            }));
        };
    }

    if (eventData.type === 'training') {
        return dispatch => {
            dispatch(ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Editing a training event',
                textComponent: 'trainingEventEditWarning',
                submitAction: 'forceTrainingEventEditSubmit',
                updateAction: 'reinitializeEventForm',
                extraText: 'Continue?',
                parentFormData: eventData,
            }));
        };
    }

    if (eventData.type === 'class-schedule') {
        return dispatch => {
            dispatch(ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Editing a class event',
                submitAction: 'forceClassScheduleEditSubmit',
                updateAction: 'updateClasses',
                text: 'Specified changes are incompatible with subscribed dogs.',
                extraText: 'Continue?',
                parentFormData: eventData,
            }));
        };
    }

    return null;
};


export const openTrainingEventProductCancellationModalDialog = (eventData) => {
    if (eventData.type === 'training') {
        return dispatch => {
            dispatch(ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Editing a training event',
                textComponent: 'trainingEventEditWarning',
                submitAction: 'forceTrainingEventProductPostSubmit',
                extraText: 'Continue?',
                parentFormData: eventData,
            }));
        };
    }

    if (eventData.type === 'new_training') {
        return dispatch => {
            dispatch(ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Creating a training event',
                textComponent: 'trainingEventCreateWarning',
                submitAction: 'forceTrainingEventPostSubmit',
                alertEvent: 'PROGRAM_SCHEDULE',
                extraText: 'Continue?',
                parentFormData: eventData,
            }));
        };
    }

    return null;
};

export const openEventKeepChangesModalDialog = () => {
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'yesNoModal',
            title: 'Leaving Calendar Details page',
            text: 'The changes you have made to the event will be lost.',
            extraText: 'Continue?',
            submitClickHandler: 'switchToCalendar',
        }));
    };
};

export const openTrainingProgramChangeWarningModalDialog = field => {
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'yesNoModal',
            title: 'Editing a training event',
            text: 'Changing the Program Type/Name will remove all of the dogs that do not have sessions for the new ' +
                'program. You will need to sell new sessions to any other dog then reschedule for the new program.',
            extraText: 'Are you sure you want to change the Program?',
            submitClickHandler: isTrainer() ? 'clearCurrentEventProducts' : 'changeCurrentEventTrainingProgram',
            field,
        }));
    };
};

export const openCreatePackageModalDialog = () => {
    const packageForm = 'createPackageForm';
    const trainingForm = 'createTrainingForm';
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'tabModal',
            title: 'Create new package or training program',
            activeForm: 0,
            name: 'create-package-modal',
            radioSwitcher: true,
            radioTabsLabel: 'Select the type to create:',
            formAdditionalClass: 'package-create-modal',
            forms: [
                {
                    url: `/v1/${getUserNameSpace('packages')}/packages/`,
                    photoUrl: `/v1/${getUserNameSpace('packages.photo')}/packages/{id}/photo/`,
                    submitAction: 'postItem',
                    updateAction: 'addNewPackageToPackageList',
                    errorAction: 'handleModalSubmitError',
                    alertEvent: 'PROGRAM_PACKAGE_CREATE',
                    form: packageForm,
                    submitButtonText: 'Create',
                    tabName: 'Package',
                    fieldProps: FormFactory.getFormDefinition(packageForm).getFieldProps(),
                },
                {
                    url: `/v1/${getUserNameSpace('training-programs')}/training-programs/`,
                    photoUrl: `/v1/${getUserNameSpace('training-programs.photo')}/training-programs/{id}/photo/`,
                    submitAction: 'postItem',
                    updateAction: 'addNewPackageToPackageList',
                    errorAction: 'handleModalSubmitError',
                    alertEvent: 'PROGRAM_PACKAGE_CREATE',
                    form: trainingForm,
                    submitButtonText: 'Create',
                    tabName: 'Training Program',
                    fieldProps: FormFactory.getFormDefinition(trainingForm).getFieldProps(),
                },
            ],
        }));
    };
};

export const openEditPackageModalDialog = data => {
    if (data.get('model_type') === 'trainingprogram') {
        const url = `/v1/${getUserNameSpace('training-programs')}/training-programs/${data.get('id')}/`;

        return dispatch => {
            return axios.get(url)
                .then((response) => {
                    const form = 'editTrainingForm';
                    dispatch(
                        ModalActions.onModalOpen({
                            type: 'editCreateModal',
                            title: 'Edit training program',
                            url,
                            photoUrl: `/v1/${getUserNameSpace(
                                'training-programs.photo')}/training-programs/{id}/photo/`,
                            submitAction: 'editItem',
                            submitButtonText: 'Save',
                            updateAction: 'updatePackageForPackageList',
                            errorAction: 'handleModalSubmitError',
                            alertEvent: 'PROGRAM_PACKAGE_EDIT',
                            shouldPassValues: false,
                            initialValues: FormFactory.getFormDefinition(form).getInitialValues(response.data),
                            fieldProps: FormFactory.getFormDefinition(form).getFieldProps(response.data),
                            form,
                        }),
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        };
    }

    const url = `/v1/${getUserNameSpace('packages')}/packages/${data.get('id')}/`;

    return (dispatch, getState) => {
        const classes = getState().getIn(['misc', 'options', 'class']);

        return axios.get(url)
            .then((response) => {
                const form = 'editPackageForm';
                response.data.classes = classes;
                dispatch(
                    ModalActions.onModalOpen({
                        type: 'editCreateModal',
                        title: 'Edit package',
                        url: `/v1/${getUserNameSpace('packages')}/packages/${data.get('id')}/`,
                        photoUrl: `/v1/${getUserNameSpace('packages.photo')}/packages/{id}/photo/`,
                        submitAction: 'editItem',
                        errorAction: 'handleModalSubmitError',
                        submitButtonText: 'Save',
                        updateAction: 'updatePackageForPackageList',
                        alertEvent: 'PROGRAM_PACKAGE_EDIT',
                        shouldPassValues: false,
                        initialValues: FormFactory.getFormDefinition(form).getInitialValues(response.data),
                        fieldProps: FormFactory.getFormDefinition(form).getFieldProps(response.data),
                        form,
                        packageId: data.get('id'),
                    }),
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const openPackageDeleteModalDialog = data => {
    return dispatch => {
        if (data.get('model_type') === 'trainingprogram') {
            dispatch(
                ModalActions.onModalOpen({
                    type: 'actionModal',
                    title: `Delete ${data.get('name')}`,
                    url: `/v1/${getUserNameSpace('training-programs')}/training-programs/${data.get('id')}/`,
                    itemId: data.get('id'),
                    updateAction: 'updatePackageList',
                    name: `Training program ${data.get('name')} was deleted`,
                    submitAction: 'deleteItem',
                    errorAction: 'handleModalSubmitError',
                    alertEvent: 'PROGRAM_PACKAGE_DELETE',
                    alertData: { name: data.get('name') },
                    buttonText: 'Delete',
                    text: `Are you sure you would like to delete training program ${data.get('name')}?`,
                }),
            );
        } else {
            dispatch(
                ModalActions.onModalOpen({
                    type: 'actionModal',
                    title: `Delete ${data.get('name')}`,
                    url: `/v1/${getUserNameSpace('packages')}/packages/${data.get('id')}/`,
                    itemId: data.get('id'),
                    name: `Package ${data.get('name')} was deleted`,
                    updateAction: 'updatePackageList',
                    submitAction: 'deleteItem',
                    errorAction: 'handleModalSubmitError',
                    alertEvent: 'PROGRAM_PACKAGE_DELETE',
                    alertData: { name: data.get('name') },
                    buttonText: 'Delete',
                    text: `Are you sure you would like to delete package ${data.get('name')}?`,
                }),
            );
        }
    };
};

export const openSellPackagesTrainingsDialog = (customerData) => {
    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'tabModal',
                title: 'Sell new package or training',
                activeForm: 0,
                radioTabsLabel: 'SELECT THE TYPE OF PACKAGE YOU WISH TO SELL:',
                radioSwitcher: true,
                formAdditionalClass: 'package-sell-modal',
                name: 'sell-package-training-modal',
                forms: [
                    {
                        submitAction: 'sellPackageOrTraining',
                        updateAction: 'addCustomerPackageOrTrainingProduct',
                        errorAction: 'handleModalSubmitError',
                        form: 'sellPackageModalDialog',
                        initialValues: FormFactory.getFormDefinition('sellPackageModalDialog')
                            .getInitialValues(customerData),
                        tabName: 'Package',
                        submitButtonText: 'Confirm sale',
                    }, {
                        submitAction: 'sellPackageOrTraining',
                        updateAction: 'addCustomerPackageOrTrainingProduct',
                        errorAction: 'handleModalSubmitError',
                        form: 'sellTrainingModalDialog',
                        initialValues: FormFactory.getFormDefinition('sellTrainingModalDialog')
                            .getInitialValues(customerData),
                        tabName: 'Training Program',
                        submitButtonText: 'Confirm sale',
                        currentCustomerId: customerData.id,
                    },
                ],
            }),
        );
    };
};

export const openSellTrainingsDialog = (customerData, currentUser) => {
    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Sell new training',
                hasCloseBtn: true,
                submitAction: 'sellPackageOrTraining',
                updateAction: 'addCustomerPackageOrTrainingProduct',
                errorAction: 'handleModalSubmitError',
                form: 'trainerSellTrainingModalDialog',
                initialValues: FormFactory.getFormDefinition('trainerSellTrainingModalDialog')
                    .getInitialValues(customerData, currentUser),
                submitButtonText: 'Confirm sale',
                currentCustomerId: customerData.id,
            }),
        );
    };
};

export const openCustomersTrainingModal = (trainingId) => {
    return (dispatch, getState) => {
        const phoneId = getState().getIn(['user', 'phoneId', 'id']);
        const availablePhones = getState().getIn(['user', 'available_phones']);
        const email = getState().getIn(['user', 'email']);
        const currentPhone = availablePhones.find(phone => phone.id === phoneId);
        const primaryPhone = currentPhone.phone_number;
        const isMobile = window.innerWidth <= 736;
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Kick old habits or acquire new skills with our behavioral and obedience training programs. No matter your budget or goals, we have something for you',
                hasCloseBtn: isMobile,
                trainingId,
                submitAction: 'postTrainingRequest',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'Submit',
                submitButtonClasses: 'modal-dialog__button_customer-order',
                headerClassNames: 'modal-one__header_transparent modal-one__header_big-title',
                form: 'customerTrainingModalDialog',
                initialValues: {
                    phone: primaryPhone,
                    email,
                },
            }),
        );
    };
};

export const openCustomerSuccessTrainingModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'trainingSuccessModal',
                headerClassNames: 'modal-one__header_transparent',
                content: 'We received your request for training and will have one of our Fitdog Specialists reach out to you with more information and questions. In the meantime, feel free to browse all of our classes or packages.',
            }),
        );
    };
};

export const openPurchasePackageCancelModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'goToUrl',
                url: '/packages',
                title: 'Purchase cancel',
                text: 'Are you sure you would like to cancel?',
            }),
        );
    };
};

export const openBookingCancelModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'goToUrl',
                url: '/classes',
                title: 'Cancel booking',
                text: 'Are you sure you would like to cancel?',
            }),
        );
    };
};

export const openBookingDogCancelModal = ({ id, dogName }) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'removeBookingDogFromList',
                url: '/classes',
                title: 'Remove dog',
                dogId: id,
                text: `Are you sure you want to remove ${dogName} from class?`,
            }),
        );
    };
};

export const openSuccessPurchasePackagesModal = (packageName) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'trainingSuccessModal',
                headerClassNames: 'modal-one__header_transparent',
                content: `Thank you for purchasing ${packageName}! Your package will
                auto deduct when you book a qualifying class. You can see your package credits in your profile.`,
                links: ['classes'],
            }),
        );
    };
};

export const openCreateVeterinarianModal = () => {
    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                url: '/v1/customers/me/add-custom-veterinarian/',
                type: 'editCreateModal',
                title: 'Add Veterinarian',
                hasCloseBtn: true,
                submitAction: 'postItem',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'Add Veterinarian',
                submitButtonClasses: 'modal-dialog__button_customer-order',
                headerClassNames: 'modal-one__header_transparent',
                updateAction: 'updateVeterinarianOptions',
                form: 'dogVeterinarianCreateModalDialog',
                initialValues: FormFactory.getFormDefinition('dogVeterinarianCreateModalDialog')
                    .getInitialValues(),
            }),
        );
    };
};

export const openCustomerAddNewDogModalDialog = () => {
    const form = 'customerAddNewDogModalDialog';
    return dispatch => {
        dispatch(
            ModalSidebarActions.onModalOpen({
                type: 'editCreateModalSidebar',
                title: 'Add dog',
                hasCancel: true,
                url: '/v1/customers/me/dogs/',
                submitAction: 'postItem',
                updateAction: 'updateCustomerDogs',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'add dog',
                form,
                shouldPassValues: true,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(),
                hasCancelModal: true,
                cancelModalContent: {
                    headline: {
                        title: 'Cancel',
                        subtitle: 'Are you sure you want to cancel? By cancelling, you will lose all progress.',
                    },
                    cancelText: 'Return to add dog',
                },
            }),
        );
    };
};

export const openClassDeleteModalDialog = (classData) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateClasses',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'CLASS_DELETE',
                alertData: { name: classData.get('name') },
                itemId: classData.get('id'),
                url: `/v1/${getUserNameSpace('classes')}/classes/${classData.get('id')}/`,
                title: 'Delete Class',
                text: `Are you sure you would like to delete the ${classData.get('name')} class?`,
            }),
        );
    };
};

export const openCustomerEditDogInfoModalDialog = (dogInfo) => {
    const form = 'customerEditDogInfoModalDialog';
    const id = dogInfo.instance.id;
    return (dispatch) => {
        dispatch(
            ModalSidebarActions.onModalOpen({
                type: 'editDogInfoModalSidebar',
                title: 'Dog profile',
                hasCancel: true,
                url: `/v1/customers/me/dogs/${id}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogInfo),
            }),
        );
    };
};

export const openCustomerDogDeleteModalDialog = dogData => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                headline: {
                    title: `Delete ${dogData.get('name')}’s Profile`,
                    subtitle: `Please select the reason you would like to delete ${dogData.get('name')}’s Profile.`,
                },
                url: `/v1/customers/me/dogs/${dogData.get('id')}/`,
                itemId: dogData.get('id'),
                hasCancel: true,
                updateAction: 'updateCustomerDogsList',
                submitAction: 'customerDeleteDog',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'Delete',
                headerClassNames: 'modal-one__header_transparent',
                form: 'customerDeleteDog',
            }),
        );
    };
};

export const openEditMedicalInfoModalDialog = (dogInfo) => {
    const form = 'customerEditDogMedicalInfoModalDialog';
    const id = dogInfo.instance.id;
    return (dispatch) => {
        dispatch(
            ModalSidebarActions.onModalOpen({
                type: 'editCreateModalSidebar',
                title: 'Medical info',
                hasCancel: true,
                url: `/v1/customers/me/dogs/${id}/`,
                submitAction: 'editItem',
                updateAction: 'updateCurrentDog',
                form,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(dogInfo),
            }),
        );
    };
};

export const openAddNewAddressModalDialog = () => {
    const form = 'AddNewAddressModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'locationAddModal',
                title: 'Add new location',
                hasCancel: true,
                url: '/v1/customers/me/dogs/',
                submitAction: 'customerAddNewDog',
                updateAction: 'updateCustomerDogs',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'add new dog',
                form,
            }),
        );
    };
};

export const openEditCustomerSchedule = (eventData) => {
    return (dispatch) => {
        dispatch(
            ModalSidebarActions.onModalOpen({
                type: 'editScheduleModalSidebar',
                title: eventData.eventName,
                eventId: eventData.id,
            }),
        );
    };
};

export const openEditCustomerScheduleInfo = ({
    title,
    subtitle,
    hasCancel = false,
    updateAction = null,
    buttonText
}) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                headline: {
                    title: title,
                    subtitle: subtitle,
                },
                hasCancel: hasCancel,
                updateAction: updateAction,
                buttonText: buttonText,
                headerClassNames: 'modal-one__header_transparent',
            }),
        );
    };
};

export const openCancelScheduleEventModalDialog = ({ cancelText, id }) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                headline: {
                    title: 'Cancel Class',
                    subtitle: cancelText,
                },
                url: `/v1/customers/me/class-occurrences/${id}/cancel-all-dogs/`,
                hasCancel: true,
                eventId: id,
                updateAction: 'updateCustomerSchedule',
                submitAction: 'cancelClass',
                errorAction: 'handleModalSubmitError',
                buttonText: 'Cancel class',
                headerClassNames: 'modal-one__header_transparent',
            }),
        );
    };
};

export const openFormEditCreateModalDialog = formConfig => {
    return dispatch => {
        const { itemId, form } = formConfig;
        const title = itemId ? 'Save Changes' : 'Create New Form';
        const buttonText = itemId ? 'Save and Send Updates' : 'Create New Form';
        const text = itemId
            ? 'Are you sure you want to save these changes? '
            : 'Are you sure you would like to create this form?';
        const extraText = itemId
            ? 'Once saved, your changes will be sent to your users to agree.'
            : 'Once created, your form will be sent to your users to agree.';
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                title,
                text,
                extraText,
                itemId,
                form,
                submitClickHandler: 'submitForm',
                buttonText,
            }),
        );
    };
};

export const openFormEditCreateDiscardChangesModalDialog = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Discard Changes?',
                text: 'You\'ve made changes to this form.',
                extraText: 'Cancelling will discard any changes that you have made. Would you like to continue?',
                submitClickHandler: 'goToUrl',
                yesButtonText: 'Continue and discard changes',
                noButtonText: 'Stay and save changes',
                url: '/dashboard/forms/',
            }),
        );
    };
};

export const openRemoveDogFromScheduleModalDialog = (eventData) => {
    const { titleText, removeText, id, productId, eventId } = eventData;
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'actionModal',
                headline: {
                    title: titleText,
                    subtitle: removeText,
                },
                url: `/v1/customers/me/upcoming-classes/${productId}/`,
                hasCancel: true,
                itemId: id,
                eventId,
                updateAction: 'updateCustomerScheduleEvent',
                submitAction: 'removeDogFromScheduleEvent',
                errorAction: 'handleModalSubmitError',
                buttonText: 'Remove dog',
                headerClassNames: 'modal-one__header_transparent',
            }),
        );
    };
};

export const openCancelFormActionModalDialog = data => {
    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                headerClassNames: 'modal-one__header_transparent',
                type: 'actionModal',
                buttonText: 'cancel',
                updateAction: 'closeSidebarModal',
                hasCancel: true,
                ...data
            }),
        );
    };
};

export const openEmployeeDeleteModalDialog = employeeData => {
    const { id, full_name, permission_level_detail } = employeeData;
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateDirectoryEmployees',
                errorAction: 'handleDeleteEmployeeSubmitError',
                alertEvent: 'EMPLOYEE_DELETE',
                alertData: { full_name },
                itemId: id,
                url: `/v1/super-admins/directory/${id}/`,
                // eslint-disable-next-line camelcase
                title: `Delete ${permission_level_detail}`,
                // eslint-disable-next-line camelcase
                text: `Are you sure you would like to delete ${full_name}?`,
            }),
        );
    };
};

export const openEditUpcomingClassModalDialog = ({ classData, context }) => {
    const form = 'editUpcomingClassModalDialog';

    if (!Map.isMap(classData)) {
        classData = fromJS(classData);
    }
    const dogsName = classData.getIn(['dog_detail', 'name']);
    const classDate = classData.getIn(['class_occurrence_detail', 'date']);
    const className = classData.getIn(['class_occurrence_detail', 'training_class_detail', 'name']);
    const classOccurrenceId = classData.getIn(['class_occurrence_detail', 'id']);
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'editCreateModal',
            title: `Edit ${dogsName}'s Class Info`,
            submitAction: 'editUpcomingClass',
            errorAction: 'handleModalSubmitError',
            text: `You are editing ${dogsName}'s information`,
            extraText: `for the ${className}, scheduled on ${formatDateToSlashes(classDate)}.`,
            classDate,
            className,
            classOccurrenceId,
            initialValues: FormFactory.getFormDefinition(form)
                .getInitialValues({ context, classData }),
            fieldProps: FormFactory.getFormDefinition(form)
                .getFieldProps({ classData, form }),
            form,
        }));
    };
};

export const openEditUpcomingTrainingModalDialog = ({ trainingData, context }) => {
    const form = 'editTrainingModalDialog';

    if (!Map.isMap(trainingData)) {
        trainingData = fromJS(trainingData);
    }
    const classData = trainingData;
    const dogsName = trainingData.getIn(['dog_detail', 'name']);
    const trainingDate = trainingData.getIn(['event_detail', 'start']);
    const programName = trainingData.getIn(['event_detail', 'program_name']);
    const trainingEventId = trainingData.getIn(['event_detail', 'id']);
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'editCreateModal',
            title: `Edit ${dogsName}'s Training Info`,
            submitAction: 'patchTrainingProduct',
            updateAction: 'updateUpcomingTrainingProductLocations',
            errorAction: 'handleModalSubmitError',
            text: `You are editing ${dogsName}'s information`,
            extraText: `for the ${programName}, scheduled on ${formatDateToSlashes(trainingDate, true)}.`,
            initialValues: FormFactory.getFormDefinition(form)
                .getInitialValues({ context, classData }),
            fieldProps: FormFactory.getFormDefinition(form)
                .getFieldProps({ programName, classData, form }),
            form,
        }));
    };
};

export const openEditTrainingModalDialog = ({ classData, context, eventData }) => {
    const form = 'editTrainingModalDialog';

    if (!Map.isMap(classData)) {
        classData = fromJS(classData);
    }

    const { start, program_name, id } = eventData;
    const dogsName = classData.getIn(['dog_detail', 'name']);

    if (id === undefined) {
        return dispatch => {
            dispatch(ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: `Edit ${dogsName}'s Training Info`,
                updateAction: 'updateEventProductLocations',
                errorAction: 'handleModalSubmitError',
                text: `You are editing ${dogsName}'s information`,
                // extraText: `for the ${program_name}, scheduled on ${formatDateToSlashes(start)}.`,
                initialValues: FormFactory.getFormDefinition(form).getInitialValues({ context, classData }),
                fieldProps: FormFactory.getFormDefinition(form).getFieldProps({ program_name, classData, form }),
                form,
            }));
        };
    }
    return dispatch => {
        dispatch(ModalActions.onModalOpen({
            type: 'editCreateModal',
            title: `Edit ${dogsName}'s Training Info`,
            submitAction: 'patchTrainingProduct',
            updateAction: 'updateEventProduct',
            errorAction: 'handleModalSubmitError',
            text: `You are editing ${dogsName}'s information`,
            extraText: `for the ${program_name}, scheduled on ${formatDateToSlashes(start)}.`,
            initialValues: FormFactory.getFormDefinition(form).getInitialValues({ context, classData }),
            fieldProps: FormFactory.getFormDefinition(form).getFieldProps({ program_name, classData, form }),
            form,
            trainingName: program_name,
            trainingDate:  start.split('T')[0],
            trainingOccurrenceId: id,
        }));
    };
};

export const openAdditionalContactsModalDialog = (data) => {
    return (dispatch) => {
        dispatch(
            ModalSidebarActions.onModalOpen({
                type: 'editAdditioanlContacts',
                ...data
            }),
        );
    };
};

export const openEditAdditionalContactModalDialog = ({ data, contactsType }) => {
    const form = 'additionalContactsForm';

    const modalConfig = {
        'additional_owners': {
            title: 'Edit additional owner',
            url: `/v1/${getUserNameSpace('me')}/me/additional-owners/${data.id}/`,
            updateAction: 'updateCustomersAdditionalOwner',
        },
        'emergency_contacts': {
            title: 'Edit emergency contact',
            url: `/v1/${getUserNameSpace('me')}/me/emergency-contacts/${data.id}/`,
            updateAction: 'updateCustomersEmergencyContact',
        },
    };

    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                hasCancel: true,
                submitAction: 'editItem',
                form,
                headerClassNames: 'modal-one__header_transparent',
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(data),
                errorAction: 'handleModalSubmitError',
                ...modalConfig[contactsType]
            }),
        );
    };
};

export const openCreateAdditionalContactModalDialog = ({ contactsType }) => {
    const form = 'additionalContactsForm';

    const modalConfig = {
        'additional_owners': {
            title: 'Create additional owner',
            url: `/v1/${getUserNameSpace('me')}/me/additional-owners/`,
            updateAction: 'addCustomersAdditionalOwner',
        },
        'emergency_contacts': {
            title: 'Create emergency contact',
            url: `/v1/${getUserNameSpace('me')}/me/emergency-contacts/`,
            updateAction: 'addCustomersEmergencyContact',
        },
    };

    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                hasCancel: true,
                submitAction: 'postItem',
                form,
                headerClassNames: 'modal-one__header_transparent',
                errorAction: 'handleModalSubmitError',
                ...modalConfig[contactsType]
            }),
        );
    };
};

export const openDeleteAdditionalContactModalDialog = ({ data, contactsType }) => {
    const modalConfig = {
        'additional_owners': {
            title: 'Delete additional owner',
            url: `/v1/${getUserNameSpace('me')}/me/additional-owners/${data.id}`,
            updateAction: 'deleteCustomersAdditionalOwner',
        },
        'emergency_contacts': {
            title: 'Delete emergency contact',
            url: `/v1/${getUserNameSpace('me')}/me/emergency-contacts/${data.id}`,
            updateAction: 'deleteCustomersEmergencyContact',
        },
    };

    return (dispatch) => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                hasCancel: true,
                itemId: data.id,
                submitAction: 'deleteItem',
                headerClassNames: 'modal-one__header_transparent',
                errorAction: 'handleModalSubmitError',
                text: `Are you sure you would like to delete the ${data.name}?`,
                ...modalConfig[contactsType]
            }),
        );
    };
};

export const openDeleteAvailableAddressesModalDialog = (address) => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Delete address',
                id: address.id,
                extraText: `Are you sure you would like to delete the ${address.name}?`,
                submitAction: 'deleteItem',
                updateAction: 'handleRemoveCustomersAvailableAddress',
                url: `/v1/customers/me/available-addresses/${address.id}/`,
                headerClassNames: 'modal-one__header_transparent',
            }),
        );
    };
};

export const openAddCustomersCreditCardModalDialog = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                url: '/v1/customers/me/payment-cards/',
                title: 'Add Credit Card',
                hasCancel: true,
                submitAction: 'sendCustomersPaymentCard',
                errorAction: 'handleModalSubmitError',
                submitButtonText: 'Save',
                headerClassNames: 'modal-one__header_transparent',
                form: 'addCreditCardFrom',
            }),
        );
    };
};

export const openDeleteCustomersCreditCardModalDialog = id => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'deleteCustomerPaymentCard',
                errorAction: 'handleModalSubmitError',
                itemId: id,
                url: `/v1/customers/me/payment-cards/${id}/`,
                title: 'Delete credit card',
                text: 'Are you sure you would like to delete the credit card?',
                headerClassNames: 'modal-one__header_transparent',
            }),
        );
    };
};

export const openForceDeleteEmployeeModalDialog = (errors) => {
    return dispatch => {
        const errorMessages = [];
        const suppress = [];
        errors.map(item => { // eslint-disable-line
            errorMessages.push(item[1]);
            suppress.push(item[0]);
        });
        const text = `${errorMessages.length} dog${errorMessages.length > 1
            ? 's'
            : ''} will be cancelled. Are you sure?`;
        const employeeId = parseInt(suppress[0].match(/\d+/g)[2], 10);
        return dispatch(ModalActions.onModalOpen({
            type: 'yesNoModal',
            submitAction: 'forceDeleteEmployee',
            updateAction: 'updateDirectoryEmployees',
            errorAction: 'handleModalSubmitError',
            alertEvent: 'EMPLOYEE_DELETE',
            title: 'Delete employee',
            full_name: errorMessages[0].split(' ')[1],
            url: `/v1/super-admins/directory/${employeeId}/`,
            itemId: employeeId,
            text,
            request: { suppress },
        }));
    };
};

export const openSaveAsCampaignModalDialog = ({ params }) => {
    const form = 'saveAsCampaignModalDialog';
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'editCreateModal',
                title: 'Save As Campaign',
                url: `/v1/${getUserNameSpace('custom-reports')}/campaigns/custom-targets/`,
                submitAction: 'postItem',
                updateAction: 'goToNewCampaignWithPreselectedNewTarget',
                errorAction: 'handleModalSubmitError',
                initialValues: FormFactory.getFormDefinition(form).getInitialValues(params),
                form,
            }),
        );
    };
};
