/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ShareButtons } from 'react-share';
import { Carousel } from 'react-responsive-carousel';
import { isEqual } from 'lodash';
import { ReactComponent as IconFb } from '../../../../../../assets/icons/facebook.svg';
import { ReactComponent as IconDownload } from '../../../../../../assets/icons/icon-download.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconDelete } from '../../../../../../assets/icons/icon-delete.svg';
import { ReactComponent as IconView } from '../../../../../../assets/icons/icon-view.svg';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { isMobile } from '../../../../../../helpers/device';
import { reportCardIcons } from '../../../../../../helpers/options';
import AvatarPlaceholder from '../../../../../common/AvatarPlaceholder';
import Icon from '../../../../../common/Icon';
import DogReportVideo from '../../../../../common/DogReportVideo';

const youtubeUrlParser = new RegExp('/(?!.*/)(.*)$');

class ReportCard extends PureComponent {
    state = {
        images: [],
        canvas: false,
        carousel_index: 0,
        max_height: 0
    };

    componentDidMount() {
        const photos = this.getPhotos(this.props);
        this.loadImages(photos);
    }

    componentWillReceiveProps(nextProps) {
        const { carousel_index, images } = this.state;
        if ((images.length > 0) && (carousel_index >= images.length)) { // Can occur when the last image is deleted
          this.setState({ carousel_index: images.length - 1 });
        }
      }

      componentDidUpdate(prevProps, prevState) {
        const prev_photos = this.getPhotos(prevProps);
        const cur_photos = this.getPhotos(this.props);

        if (!isEqual(prev_photos, cur_photos)) {
            this.loadImages(cur_photos);
        }
    }

    getPhotos = (props, getPhoto) => {
        if (!props) {
            return undefined;
        }

        if (!getPhoto) {
            getPhoto = (obj) => (isMobile ? obj.photo_mobile : obj.photo_desktop);
        }

        const photos = [];
        // Get snapshot image from old 'photo' field
        if (props.data.photo) { 
            photos.push(getPhoto(props.data));
        }

        // Get 1 or more additional snapshots from the new snapshots relation
        props.data.snapshots.forEach(snapshot => { 
            photos.push(getPhoto(snapshot));
        });

        return photos;
    }

    getReportIcons = () => {
        const { data } = this.props;
        return (
            <div className='dog-report__icons'>
                {data.icons.map((iconItem, itemIndex) => {
                    let _found = false;
                    const currentIcon = {
                        icon: IconView,
                        iconClass: 'dog-report__icon-overlay',
                        label: iconItem.label,
                        type: iconItem.type,
                    };
                    reportCardIcons.every(iconData => {
                        if (iconData.type === iconItem.type) {
                            _found = true;
                            currentIcon.icon = iconData.icon;
                            currentIcon.iconClass = iconData.iconClass;
                            currentIcon.type = iconData.label;
                        }
                        return !_found;
                    });
                    return (
                        <div
                            className='dog-report__icon-container'
                            key={data.title + '-' + currentIcon.type + '-' + itemIndex}>
                            <div className='dog-report__icon-view-column'>
                                <Icon
                                    glyph={currentIcon.icon}
                                    className={`${currentIcon.iconClass} dog-report__icon-view`}/>
                            </div>
                            <div className='dog-report__icon-description-column'>
                                <div className='dog-report__icon-type-row'>
                                    {currentIcon.type || 'Type not set'}
                                </div>
                                <div className='dog-report__icon-label-row'>
                                    {currentIcon.label || 'Label not set'}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    
    getCarousel = () => {
        const { data: { snapshot_video_urls } } = this.props;
        const { images } = this.state;
        const hasMultiplePhotos = images.length > 1;

        const children = [];

        if (images.length > 0) {
            images.forEach((image, index) => {
                children.push(
                    <div key={index}>
                        <img height={'' + (image.height / 2)} alt={'Photo ' + index} src={image.src}/>
                    </div>
                );
            });
        }

        if (snapshot_video_urls.length > 0) {
            snapshot_video_urls.forEach((videoUrlObj) => {
                children.push(
                    <DogReportVideo videoUrl={videoUrlObj.snapshot_video_url} />
                );
            });
        }

        if (children.length > 0) {
            return (
                <Carousel 
                    showThumbs={false} 
                    onChange={this.carouselChanged} 
                    selectedItem={this.state.carousel_index} 
                    showStatus={hasMultiplePhotos}
                    showIndicators={hasMultiplePhotos}>
                    {children}
                </Carousel>
            );
        }

        return <AvatarPlaceholder type='dog' size='report-card'/>;
    };

    getDownloadPhoto() {
        const { data } = this.props;
        const { carousel_index, images } = this.state;
        let filename;
        let mediaUrl;

        if (carousel_index < images.length) {
            const photos = this.getPhotos(this.props, (obj) => obj.photo_processed);
            mediaUrl = photos[carousel_index];
            filename = `${data.title} - (${carousel_index + 1} of ${photos.length})`;
        } else if (data.snapshot_video_urls.length > 0) {
            const index = carousel_index - images.length;
            const youtubeEmbedUrl = data.snapshot_video_urls[index].snapshot_video_url;
            const youtubeVideoId = youtubeUrlParser.exec(youtubeEmbedUrl)[1];
            mediaUrl = `https://www.youtube.com/watch?v=${youtubeVideoId}`;
        }

        return { mediaUrl, filename };
    }

    downloadImage(image, filename) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        const url = canvas.toDataURL();

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
    }

    loadImage = (imageUrl) => {
        return new Promise((resolve) => {
            const image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = imageUrl;
            image.onload = () => {
                resolve(image);
            };
        });
    }

    loadImages = (imageUrls) => {
        const promises = imageUrls.map(this.loadImage);
        Promise.all(promises)
        .then(images => {
            let max_height = 0;
            images.forEach(image => {
                if (image.height > max_height) {
                    max_height = image.height;
                }
            });

            this.setState({ images, max_height });
        });
    }

    downloadCurrentPhoto() {
        const { mediaUrl, filename } = this.getDownloadPhoto();

        this.loadImage(mediaUrl).then(image => {
            this.downloadImage(image, filename);
        });
    }

    carouselChanged = (new_index) => {
        const { carousel_index } = this.state;

        if (carousel_index !== new_index) {
            this.setState({ carousel_index: new_index });
        }
        
        return new_index;
    }

    render() {
        const { data, editHandler, deleteHandler } = this.props;
        const { carousel_index, images } = this.state;
        const { mediaUrl } = this.getDownloadPhoto();

        const numOfImages = images.length;
        const showDownloadButton = (numOfImages > 0) && (carousel_index < numOfImages);

        const { FacebookShareButton } = ShareButtons;

        const titleStyles = data.title.length > 30 ?
            { fontSize: '1rem', lineHeight: '1.5rem' } :
            {};

        return (
            <div className='dog-report__card'>
                <div className='dog-report__card-header'>
                    {(showDownloadButton) &&
                        <a
                            className='dog-report__card-header-link'
                            onClick={() => this.downloadCurrentPhoto()}>
                            Download:
                            <Icon glyph={IconDownload} className='dog-report__icon-header dog-report__icon-download'/>
                        </a>
                    }
                    <FacebookShareButton url={mediaUrl}>
                        <a className='dog-report__card-header-link' href=''>
                            Share:<Icon glyph={IconFb} className='dog-report__icon-header dog-report__icon-fb'/>
                        </a>
                    </FacebookShareButton>
                </div>
                <div className='dog-report__image-container'>
                    {editHandler && 
                    <div
                        className='dog-report__edit-icon'
                        onClick={() => {
                            editHandler({ isEventBased: false, data });
                        }}>
                        <Icon glyph={IconEdit} className='icon_edit'/>
                    </div>
                    }
                    {deleteHandler &&
                    <div
                        className='dog-report__delete-icon'
                        onClick={() => {
                            deleteHandler({ isEventBased: false, data });
                        }}>
                        <Icon glyph={IconDelete} className='icon_delete'/>
                    </div>
                    }
                    <div style={{ position: 'relative' }}>
                        {this.getCarousel()}
                        {(images.length > 0) && <div className='dog-report__link'/>}
                    </div>
                    {this.getReportIcons()}
                </div>
                <div className='dog-report__info'>
                    <div className='dog-report__title' style={titleStyles}>
                        {data.title}
                    </div>
                    <div className='dog-report__date'>
                        {formatDateToSlashes(data.date)}
                    </div>
                </div>
            </div>
        );
    }
}

ReportCard.propTypes = {
    data: PropTypes.shape({}),
    editHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
};

export default ReportCard;
