import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

export default class renderPasswordControl extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        input: PropTypes.shape({}),
        label: PropTypes.string,
        meta: PropTypes.shape({}),
        helper: PropTypes.string,
        formError: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string
        ])
    };

    state = {
        isHidden: true
    };

    handleTogglerClick = () => {
        const { isHidden } = this.state;
        this.setState({ isHidden: !isHidden });
    };

    render() {
        const { name, input, label, meta: { touched, error }, helper, formError } = this.props;
        const { isHidden } = this.state;
        const InputClassNames = classNames({
            'input-control__input': true,
            'input-control__input_touched': touched,
            'input-control__input_failed': ((touched && error) || formError),
            'input-control__input_empty': (input.value.length === 0 && !((touched && error) || formError))
        });
        const PlaceholderClassNames = classNames({
            'input-control__placeholder': true,
            'input-control__placeholder_touched': touched,
            'input-control__placeholder_failed': (touched && error),
            'input-control__placeholder_empty': (input.value.length === 0 && !(touched && error))
        });
        const InfoClassNames = classNames({
            'input-control__info': true,
            'input-control__info_multi': (touched && error)
        });
        const TogglerIconClassNames = classNames({
            'input-control__toggler-icon': true,
            'input-control__toggler-icon_shown': !isHidden,
            'input-control__toggler-icon_hidden': isHidden
        });
        return (
            <div className='input-control'>
                <input
                    {...input}
                    // id='test'
                    type={isHidden ? 'password' : 'text'}
                    className={InputClassNames}/>
                <span className={PlaceholderClassNames}>{label}</span>
                <a className='input-control__toggler' onClick={this.handleTogglerClick}>
                    <div className={TogglerIconClassNames}/>
                </a>
                <div className={InfoClassNames}>
                    {touched && error && <span className='input-control__error'>{error}</span>}
                    {helper && <Link to='/forgot-password' className='input-control__helper'>{helper}</Link>}
                </div>
            </div>
        );
    }
}
