import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

import { ReactComponent as IconCancel } from '../../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import {
    createEventAttendingDogsDropdownItems,
    shouldDisplayEventProductDropdown
} from '../../../../../../helpers/dropdownItems';
import AvatarPlaceholder from '../../../../../common/AvatarPlaceholder';
import Dropdown from '../../../../../common/Dropdown';
import AttendedIcon from '../../../../../common/AttendedIcon';
import Icon from '../../../../../common/Icon/Icon';

class AttendingDogsCard extends React.PureComponent {
    static propTypes = {
        eventData: PropTypes.shape({}),
        data: PropTypes.shape({}).isRequired,
        attendingDogsActions: PropTypes.shape({}),
    };

    state = {
        expanded: true
    };

    formatAddress = locationDetail => {
        return `${locationDetail.address1}${locationDetail.address2 ? (' ' + locationDetail.address2) : ''}, ${locationDetail.city} ${locationDetail.state} ${locationDetail.zip_code}`;
    };

    formatLocationNotes = locationDetail => {
        return locationDetail.location_notes ? `${locationDetail.location_notes}` : <div className='no-info'>—</div>
    };

    handleExpandButtonClick = e => {
        e.preventDefault();
        this.setState({
            expanded: !this.state.expanded
        });
    };

    renderReportCardItem = () => {
        const { data, eventData } = this.props;

        if (eventData.type === 'class') {
            return (
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Snapshot</div>
                    <div className='attending-dogs-card__item-content'>
                        {
                            data.reports.length ? <Icon glyph={IconCheckMark} className='icon_check-mark'/> :
                            <Icon glyph={IconCancel} className='icon_cancel'/>
                        }
                    </div>
                </div>
            );
        }

        return null;
    };

    renderContent = () => {
        if (!this.state.expanded) {
            return null;
        }

        const { data } = this.props;

        return (
            <div className='attending-dogs-card__content'>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Pickup</div>
                    <div className='attending-dogs-card__item-content'>{this.formatAddress(
                        data.pickup_location_detail)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Pickup Notes</div>
                    <div className='attending-dogs-card__item-content'>{this.formatLocationNotes(
                        data.pickup_location_detail)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Drop Off</div>
                    <div className='attending-dogs-card__item-content'>{this.formatAddress(
                        data.drop_off_location_detail)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Drop Off Notes</div>
                    <div className='attending-dogs-card__item-content'>{this.formatLocationNotes(
                        data.drop_off_location_detail)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Attended or Cancelled</div>
                    <div className='attending-dogs-card__item-content'>
                        <AttendedIcon status={data.status}/>
                    </div>
                </div>
                {this.renderReportCardItem()}
            </div>
        );
    };

    renderExpandButton = () => {
        const text = this.state.expanded ? 'See Less' : 'See More';

        return (
            <div className='attending-dogs-card__expand-button-wrapper'>
                <a
                    href=''
                    onClick={this.handleExpandButtonClick}
                    className='attending-dogs-card__expand-button'>
                    {text}
                </a>
            </div>
        );
    };
    
    renderDropdown = () => {
        const { data, eventData, attendingDogsActions } = this.props;
        
        if (!shouldDisplayEventProductDropdown(data)) {
            return null;
        }
        
        return (
            <Dropdown
                data={data}
                classNames='dropdown_attending-dogs-card'
                icon={IconDots}
                iconClass='icon_chevron_down'
                items={createEventAttendingDogsDropdownItems({
                    eventData, rowData: data, actions: attendingDogsActions, context: 'dogs'
                })}/>
        );
    };

    render() {
        const { data } = this.props;
        return (
            <div className='attending-dogs-card-container'>
                <div className='attending-dogs-card'>

                    {this.renderDropdown()}

                    <div className='attending-dogs-card__header'>
                        <div className='attending-dogs-card__img-container'>
                            {data.dog_detail.photo_processed !== undefined
                                ? (<img
                                    className='attending-dogs-card__img'
                                    src={data.dog_detail.photo_processed}
                                    alt={`Dog ${data.dog_detail.name}`}/>)
                                : (<AvatarPlaceholder type='dog' size={70}/>)
                            }
                        </div>
                        <div className='attending-dogs-card__header-title'>{data.dog_detail.name}</div>
                        <div className='attending-dogs-card__header-text'>
                            <Link to={`/dashboard/customer/${data.owner_detail.id}/details`}>
                                {data.owner_detail.full_name}
                            </Link>
                        </div>
                    </div>

                    {this.renderContent()}
                    {this.renderExpandButton()}

                </div>
            </div>
        );
    }
}

export default AttendingDogsCard;
