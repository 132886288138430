import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleCurrentCustomerNotesIncidentsSubmit } from '../../../../../actions/dashboard/notesIncidentsActions';
import { formatDateToSlashes } from '../../../../../helpers/date';
import NotesForm from './NotesForm';
import { isTrainer } from '../../../../../helpers/userRoles';

const NotesPage = ({ notes_incidents, handleSubmit, routeParams }) => {
    return (
        <div className='notes-incidents'>
            <div className='notes-incidents__content'>
                <div className='notes-incidents__header'>
                    Notes and Incidents
                </div>
                <NotesForm
                    onSubmit={data => handleSubmit(routeParams.customer, data)}/>
                {notes_incidents.map((item) => {
                    return (
                        <div className='notes-incidents__item' key={'note_' + item.id}>
                            <div className='notes-incidents__item-header'>
                                <img
                                    src={item.author.photo || 'https://mir-s3-cdn-cf.behance.net/project_modules/disp/8e427328807052.56053ef96e121.jpg'}
                                    alt={item.author.full_name || item.author.email}
                                    className='notes-incidents__item-img'/>
                                <div>
                                    <div
                                        className='notes-incidents__item-name'>{item.author.full_name || item.author.email}</div>
                                    <div className='notes-incidents__item-date'>
                                        {formatDateToSlashes(item.modified_at, true)}
                                    </div>
                                </div>
                            </div>
                            <div className='notes-incidents__item-text'>
                                {item.body}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

NotesPage.propTypes = {
    routeParams: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    notes_incidents: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => {
    return {
        notes_incidents: state.getIn(['currentCustomer', 'notes_incidents', 'items']).toJS()
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSubmit: bindActionCreators(handleCurrentCustomerNotesIncidentsSubmit, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesPage);
