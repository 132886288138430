import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as IconCancel } from '../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconNotes } from '../../../../../assets/icons/icon-notes.svg';
import { ReactComponent as IconDots } from '../../../../../assets/icons/icon-dots.svg';
import {
    createEventAttendingDogsDropdownItems,
    shouldDisplayEventProductDropdown
} from '../../../../../helpers/linearDashboardDropdownItems';
import AttendedIcon from '../../../../common/AttendedIcon';
import Icon from '../../../../common/Icon/Icon';
import Table from '../../../../common/Table';
import { prettifyPhone } from '../../../../../helpers/normalize';
import { timePrettify, formatTrainingDateTimeToAMPM } from '../../../../../helpers/date';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder';
import Dropdown from '../../../../common/Dropdown';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';
import * as classesActions from '../../../../../actions/dashboard/classesActions';
import * as EventActions from '../../../../../actions/dashboard/eventActions';
import * as dashboardPageActions from '../../../../../actions/dashboard/dashboardPageActions';


class LinearDashboardTable extends Table {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})),
        handleEditingUpcomingClass: PropTypes.func,
        handleEditingTraining: PropTypes.func,
        handleEditingUpcomingClassStatus: PropTypes.func,
        handleEditingPreviousClassStatus: PropTypes.func,
        handleOpeningCancelUpcomingClassModal: PropTypes.func,
        handleCancellingUpcomingClass: PropTypes.func,
        cancelTrainingProduct: PropTypes.func,
        handleSetDogAttendedStatus: PropTypes.func,
        handleSetProductCancelStatus: PropTypes.func,
        handleSetProductNoShowStatus: PropTypes.func,
        handleOpeningCreateDogReportModalDialog: PropTypes.func,
        handleOpeningEditDogReportModalDialog: PropTypes.func,
        handleSettingClassEmptyReports: PropTypes.func,
    };

    cellClassName = 'event-detail-table__td';

    dog = row => {
        const dog_avatar = !row.original.dog_photo_processed ? <AvatarPlaceholder type='dog' size={40}/> :
                                                <div className='class-event-card__attendance-avatar-image'
                                                style={{
                                                    background: `url(${row.original.dog_photo_processed}) center center / cover no-repeat`,
                                                }}/>
        return <div style={{display: "flex", alignItems: "center"}}><div>{dog_avatar}</div><div style={{marginLeft: "10px"}}><Link to={`/dashboard/dog/${row.original.dog}/details`}>{row.value}</Link></div></div>
    }

    time = row => {
        return <div>{row.value.start ? timePrettify(formatTrainingDateTimeToAMPM(row.value.start), 'h:mmA') : timePrettify(row.value.pickup_end_time, 'h:mmA')}&nbsp;&mdash;&nbsp;{row.value.end ? timePrettify(formatTrainingDateTimeToAMPM(row.value.end), 'h:mmA') : timePrettify(row.value.dropoff_start_time, 'h:mmA')}</div>;
    }

    formatAddress = row => {
        return <div>{row.value.pickup_location_detail.name} / {row.value.drop_off_location_detail.name}</div>;
    }

    notes = row => {
        if (row.value.length) {
            return <Tooltip
                    trigger={['hover']}
                    overlayClassName='notes__tooltip'
                    placement='top'
                    overlay={row.value}
                    arrowContent={<div className='rc-tooltip-arrow-inner'/>}>
                    <div className='notes__content'><Icon glyph={IconNotes} className='icon_notes'/></div>
                </Tooltip>
        }
        return <div className='no-info'>—</div>
    }

    program = row => {
        const type = row.original.class_occurrence ? 'classes' : 'trainings'
        const event = row.original.class_occurrence ? row.original.class_occurrence_detail : row.original.event
        return <Link to={`/dashboard/calendar/${type}/${event.id}`}>{row.value}</Link>
    }

    contact = row => {
        const email = row.value.replace("@", "@\n")
        return <div><div style={{ marginBottom: "10px" }}>{prettifyPhone(row.original.owner_detail.primary_phone.phone_number)}</div><div><a href={'mailto:' + row.value}>{email}</a></div></div>
    }

    reports = row => {
        if (row.value.reports === undefined) {
            return ""
        } else {
            return row.value.reports.length
                ? <Icon glyph={IconCheckMark} className='icon_check-mark'/>
                : <Icon glyph={IconCancel} className='icon_cancel'/>
        }
    }

    renderLinearDashboardTable = columns => {
        const { isScrollable } = this.state;

        const {
            handleEditingUpcomingClass, handleEditingTraining, handleEditingUpcomingClassStatus, handleEditingPreviousClassStatus,
            handleCancellingUpcomingClass, handleOpeningCancelUpcomingClassModal, cancelTrainingProduct, handleSetDogAttendedStatus,
            handleSetProductCancelStatus, handleSetProductNoShowStatus, handleOpeningCreateDogReportModalDialog, handleOpeningEditDogReportModalDialog,
            handleSettingClassEmptyReports

        } = this.props;

        const dropDownColumn = {
            id: 'dropdown',
            Header: '',
            headerClassName: 'classes-table__th',
            className: this.cellClassName,
            width: 64,
            resizable: false,
            sortable: false,
            accessor: d => d,
            Cell: row => {
                const type = row.value.class_occurrence ? 'class' : 'training'
                const eventData = type === 'class' ? row.value.class_occurrence_detail : row.value.event
                const attendingDogsActions = {
                    'edit': type === 'class' ? handleEditingUpcomingClass : handleEditingTraining,
                    'attended': type === 'class' ? handleEditingUpcomingClassStatus : handleSetDogAttendedStatus,
                    'attendedChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetDogAttendedStatus,
                    'cancel': type === 'class' ? handleCancellingUpcomingClass : cancelTrainingProduct,
                    'cancelPast': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductCancelStatus,
                    'cancelPenaltyChangeTo': type === 'class' ? handleEditingPreviousClassStatus : null,
                    'cancelWaiveChangeTo': type === 'class'? handleEditingPreviousClassStatus : null,
                    'cancelLate': type == 'class' ? handleOpeningCancelUpcomingClassModal : null,
                    'noShow': type === 'class' ? handleEditingUpcomingClassStatus : handleSetProductNoShowStatus,
                    'noShowChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductNoShowStatus,
                    'reportCreate': handleOpeningCreateDogReportModalDialog,
                    'reportEdit': handleOpeningEditDogReportModalDialog,
                    'reportDelete': handleSettingClassEmptyReports,
                };

                if (!shouldDisplayEventProductDropdown(row.value)) {
                    return null;
                }

                return (<Dropdown
                    data={row.value}
                    classNames='dropdown_table'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={createEventAttendingDogsDropdownItems({
                        eventData, rowData: row.value, actions: attendingDogsActions, context: 'dogs', type: type
                    })}/>);
            }
        };

        columns.push(dropDownColumn);

        return this.renderTable({ isScrollable, columns });
    };


    render() {

        const columns = [
            {
                id: 'dog',
                Header: 'Dog',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.dog_detail.name,
                Cell: (row) => this.dog(row),
            },
            {
                id: 'owner',
                Header: 'Owner',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.owner_detail.full_name,
                Cell: (row) => <Link to={`/dashboard/customer/${row.original.owner_detail.id}/details`}>{row.value}</Link>,
            },
            {
                id: 'contact',
                Header: 'Contact',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.owner_detail.email,
                Cell: row => this.contact(row)
            },
            {
                id: 'time',
                Header: 'Time',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.class_occurrence ? d.class_occurrence_detail : d.event,
                Cell: row => this.time(row),
                sortable: false
            },
            {
                id: 'notes',
                Header: 'Notes',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.class_occurrence ? d.class_occurrence_detail.notes : d.event.notes,
                Cell: row => this.notes(row),
                sortable: false
            },
            {
                id: 'class_program',
                Header: 'Class/Program',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.class_occurrence ? d.class_occurrence_detail.program_name : d.event.program_name,
                Cell: row => this.program(row)
            },
            {
                id: 'pickup_dropoff',
                Header: 'P/U D/O',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d,
                Cell: row => this.formatAddress(row)
            },
            {
                id: 'attending',
                Header: 'Attending/Cancelled',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.status,
                Cell: row => <AttendedIcon status={row.value}/>
            },
            {
                id: 'reportCard',
                Header: 'Snapshot',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d,
                Cell: row => this.reports(row),
                sortable: false
            }
        ];
        return (
            <div className='attending-dogs-tabe'>
                {this.renderLinearDashboardTable(columns)}
            </div>
        );
    }

}
const mapDispatchToProps = (dispatch) => ({
    handleEditingUpcomingClass: bindActionCreators(ModalOpenActions.openEditUpcomingClassModalDialog, dispatch),
    handleEditingTraining: bindActionCreators(ModalOpenActions.openEditTrainingModalDialog, dispatch),
    handleEditingUpcomingClassStatus: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleEditingPreviousClassStatus: bindActionCreators(classesActions.markDogsPreviousClass, dispatch),
    handleOpeningCancelUpcomingClassModal: bindActionCreators(ModalOpenActions.openCancelDogsUpcomingClassModalDialog, dispatch),
    handleCancellingUpcomingClass: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    cancelTrainingProduct: bindActionCreators(EventActions.cancelTrainingProduct, dispatch),
    handleSetDogAttendedStatus: bindActionCreators(EventActions.setProductAttendedStatus, dispatch),
    handleSetProductCancelStatus: bindActionCreators(EventActions.setProductCancelStatus, dispatch),
    handleSetProductNoShowStatus: bindActionCreators(EventActions.setProductNoShowStatus, dispatch),
    handleOpeningCreateDogReportModalDialog: bindActionCreators(ModalOpenActions.openCreateDogReportModalDialog, dispatch),
    handleOpeningEditDogReportModalDialog: bindActionCreators(ModalOpenActions.openEditDogReportModalDialog, dispatch),
    handleSettingClassEmptyReports: bindActionCreators(dashboardPageActions.setClassEmptyReports, dispatch),
});

export default connect(null, mapDispatchToProps)(LinearDashboardTable);