import React from 'react';
import PropTypes from 'prop-types';

const DeleteItem = ({ text, onClickHandler, className = '' }) => {
    return (
        <a className={'delete-item ' + className} onClick={onClickHandler}>
            <div className='delete-item__icon'/>
            <div className='delete-item__text'>{ text }</div>
        </a>
    );
};

DeleteItem.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default DeleteItem;
