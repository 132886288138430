import { uploadFile } from '../../services/file';

export const uploadImmunization = (
    file,
    uploadProgressCallback,
    uploadCompleteCallback,
    uploadErrorCallback,
    cancelToken,
    uploadCancelCallback,
) => {
    return (dispatch) => {
        const uploadUrl = '/v1/customers/me/immunization-doc/upload/';
        dispatch(uploadFile({
            currentFile: file,
            url: uploadUrl,
            progressCallback: uploadProgressCallback,
            completeCallback: uploadCompleteCallback,
            errorCallback: uploadErrorCallback,
            cancelToken,
            cancelCallback: uploadCancelCallback
        }));
    };
};
