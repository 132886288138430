/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';
import { setPDLocationsEditDefaultData } from '../../../../actions/miscActions';
import { createFieldsObject } from '../../../../helpers/autocomplete';
import { PDLocationFields } from '../../../../helpers/options';
import ModalDialogFormBlock from '../../../common/ModalDialogFormBlock';
import validate from './validate';

class PickupDropoffEditModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        PDLocations: PropTypes.arrayOf(PropTypes.shape({})),
        customersName: PropTypes.string,
        changeFieldValue: PropTypes.func,
        handleAutocompleteSelectOrEnter: PropTypes.func,
    };

    state = {
        PDLocations: {},
        PDLocationsBlockCount: 0,
        areExternalPDLocationsLoaded: false
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    componentWillReceiveProps(nextProps) {
        const { areExternalPDLocationsLoaded } = this.state;
        if (nextProps.PDLocations.length && !areExternalPDLocationsLoaded) {
            const PDLocations = { ...this.state.PDLocations };
            const PDLocationsAmount = nextProps.PDLocations.length;

            nextProps.PDLocations.forEach((owner, i) => {
                PDLocations[i] = owner;
            });

            this.setState({
                PDLocations,
                PDLocationsBlockCount: PDLocationsAmount,
                areExternalPDLocationsLoaded: true
            });
        }

        // setting areExternalPDLocationsLoaded flag to false and empty PDLocations
        // to reinitiate the form with the actual additional owner from external store
        if (areExternalPDLocationsLoaded && !nextProps.isPDLocationsModalOpen) {
            this.setState({
                PDLocations: {},
                PDLocationsBlockCount: 0,
                areExternalPDLocationsLoaded: false
            });
        }
    }

    deletePDLocation = (locationKey) => {
        const PDLocations = { ...this.state.PDLocations };
        this.props.changeFieldValue(`${locationKey}.name`, 'deleted');
        this.props.changeFieldValue(`${locationKey}.address1`, '');
        this.props.changeFieldValue(`${locationKey}.address2`, '');
        this.props.changeFieldValue(`${locationKey}.city`, '');
        this.props.changeFieldValue(`${locationKey}.state`, '');
        this.props.changeFieldValue(`${locationKey}.zip_code`, '');
        this.props.changeFieldValue(`${locationKey}.location_notes`, '');
        delete PDLocations[locationKey];
        this.setState({ PDLocations });
    };

    render() {
        const { handleSubmit, invalid, submitting, customersName, handleAutocompleteSelectOrEnter, error } = this.props;
        const { PDLocations } = this.state;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const PDLocationsCount = Object.keys(PDLocations).length;
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    {PDLocationsCount
                        ? (Object.keys(PDLocations).map((locationKey) => {
                            return (
                                <ModalDialogFormBlock
                                    key={`form-block_owner_${locationKey}`}
                                    index={+locationKey}
                                    entityName='Location'
                                    blockName='Location'
                                    deleteBlockHandler={this.deletePDLocation}
                                    blockFields={PDLocationFields}
                                    handleAutocompleteSelectOrEnter={handleAutocompleteSelectOrEnter}
                                    fields={createFieldsObject(PDLocationFields, locationKey, true, true)}
                                    formName='PickupDropoffEditModalDialogForm'/>
                            );
                        }))
                        : (<div className='modal-dialog__form-title modal-dialog__form-title_empty'>
                            No Pickup/Dropoff Locations for {customersName}
                        </div>)
                    }
                </div>
                {error && <div className='form__error'>{error}</div>}
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {submitting
                            ? 'Updating...'
                            : 'Save'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'PickupDropoffEditModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const homeAddressId = state.getIn(['currentCustomer', 'initialInstance', 'address']);
    const PDLocations = state.getIn(['currentCustomer', 'initialInstance', 'available_addresses'])
        .toJS()
        .reduce((locationsArray, location, i) => {
            if (location.id !== 1) { // 1 - Fitdog HQ Address.
                locationsArray.push(location);
            }
            return locationsArray;
        }, []);
    const initialValues = PDLocations.reduce((initialValues, location, i) => {
        initialValues[i] = location;
        return initialValues;
    }, {});
    return {
        isPDLocationsModalOpen: state.getIn(['misc', 'modals', 'pDLocationsEdit']),
        PDLocations,
        initialValues
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setPDLocationsEditDefaultData, dispatch),
    handleAutocompleteSelectOrEnter: bindActionCreators(handleAutocompleteSelectOrEnter, dispatch),
    // eslint-disable-next-line object-shorthand
    changeFieldValue: function (field, value) {
        dispatch(change('PickupDropoffEditModalDialogForm', field, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(form(PickupDropoffEditModalDialogForm));
