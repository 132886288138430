import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropZone from 'react-dropzone';
import * as UploadActions from '../../../actions/modal/uploadActions';
import Button from '../Button/';

const FileUploader = ({
  setUploadModalOpened,
  uploadAction,
  updateFileProgress,
  setUploadFileStatus,
  bookingDogItem = false,
}) => {
  const upload = (files, index = 0) => {
    const onUploadProgress = (event) => {
      const propgress = Math.round((event.loaded * 100) / event.total);
      updateFileProgress(index, propgress);
    };
    const onUploadComplete = (data) => {
      setUploadFileStatus({ fileIndex: index, status: 'success' });
    };
    const onUploadError = (data) => {
      setUploadFileStatus({ fileIndex: index, status: 'error' });
    };
    const onCancelCallback = (data) => {
      setUploadFileStatus({ fileIndex: index, status: 'canceled' });
    };
    const cancelToken = files[index].token;
    uploadAction(
      files[index],
      onUploadProgress,
      onUploadComplete,
      onUploadError,
      cancelToken,
      onCancelCallback
    );
  };

  const onDrop = (acceptedFiles) => {
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const file = acceptedFiles[i];
      file.progress = 0;
      file.status = 'loading';
      file.token = source.token;
      file.cancel = () => {
        setUploadFileStatus({ fileIndex: i, status: 'canceled' });
        source.cancel('Operation canceled by the user.');
      };
      file.upload = () => {
        upload(acceptedFiles, i);
      };
      upload(acceptedFiles, i);
    }
    if (acceptedFiles.length) setUploadModalOpened(acceptedFiles);
  };

  const renderUploadNew = () => {
    return (
      <DropZone
        name={'immunizations'}
        className='immunizations-table__upload'
        accept='image/jpeg, image/png, image/jpg, image/tiff, application/pdf'
        maxSize={25 * 1024 * 1024}
        onDrop={onDrop}
      >
        {bookingDogItem ?
          <a className={'dogs-slider__upload-file'}>{'Upload Documents Here'}</a>
          : <Button modifierClassName='button__full_width'  text='Upload New' />}
      </DropZone>
    );
  };
  return renderUploadNew();
};

FileUploader.propTypes = {
  setUploadModalOpened: PropTypes.func,
  uploadAction: PropTypes.func,
  updateFileProgress: PropTypes.func,
  setUploadFileStatus: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  updateFileProgress: bindActionCreators(
    UploadActions.updateFileProgress,
    dispatch
  ),
  setUploadModalOpened: bindActionCreators(
    UploadActions.setUploadModalOpened,
    dispatch
  ),
  setUploadFileStatus: bindActionCreators(
    UploadActions.setUploadFileStatus,
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(FileUploader);
