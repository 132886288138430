import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkAgreements, signAgreements } from '../../../../actions/AgreementsActions';
import UpdateForm from './UpdateForm/';
import { isCustomer, isTrainer } from '../../../../helpers/userRoles';

class FormUpdatePage extends PureComponent {
    componentWillMount() {
        const { checkAgreements, routeParams: { id }, agreementsAreLoaded } = this.props;
        if (!agreementsAreLoaded) {
            checkAgreements(id);
        }
    }

    render() {
        const { agreementsAreLoaded, routeParams, agreements, signAgreements, shouldWelcomeShowed } = this.props;
        if (agreementsAreLoaded && agreements.length > 0) {
            const id = parseInt(routeParams.id, 10);
            let nextAgreementId = false;
            let agreementData = false;
            agreements.forEach((agreement) => {
                if (agreement.id === id) {
                    agreementData = agreement;
                } else if (!nextAgreementId) {
                    nextAgreementId = agreement.id;
                }
            });
            if (!agreementData) return null;

            let nextUrl;
            if (nextAgreementId) {
                nextUrl = `/form-update/${nextAgreementId}`;
            } else if (isCustomer()) {
                nextUrl = shouldWelcomeShowed ? '/welcome' : '/home';
            } else {
                nextUrl = '/dashboard';
            }

            return (<>
                    <UpdateForm
                        id={id}
                        data={agreementData}
                        routeParams={routeParams}
                        onSubmit={() => signAgreements(id, nextUrl)} />
                    </>

            );
        }
        return null;
    }
}

FormUpdatePage.propTypes = {
    routeParams: PropTypes.shape({}),
    checkAgreements: PropTypes.func,
    signAgreements: PropTypes.func,
    agreementsAreLoaded: PropTypes.bool,
    shouldWelcomeShowed: PropTypes.bool,
    agreements: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({}),
    ]),
};

const mapStateToProps = state => {
    return {
        agreements: state.getIn(['user', 'agreements', 'items']).toJS(),
        agreementsAreLoaded: state.getIn(['user', 'agreements', 'areLoaded']),
        shouldWelcomeShowed: state.getIn(['user', 'shouldWelcomeShowed']),
    };
};

const mapDispatchToProps = {
    checkAgreements,
    signAgreements
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdatePage);
