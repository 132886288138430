import PropTypes from 'prop-types';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import { ReactComponent as FacebookSVG } from '../../../assets/icons/facebook.svg';
import { ReactComponent as GoogleSVG } from '../../../assets/icons/google.svg';

import { FB_APP_ID, GOOGLE_CLIENT_ID } from '../../../config';
import Icon from '../Icon/Icon';

const SocialLogin = ({ socialLoginClassName, faceBookCB, googleCB }) => {
    return (
        <div className={socialLoginClassName}>
            <FacebookLogin
                appId={FB_APP_ID}
                fields='name,email,picture'
                scope='email,public_profile,user_friends'
                tag='button'
                cssClass='social-login__button social-login__button--facebook'
                icon={<Icon className='social-login__icon' size='lg' glyph={FacebookSVG}/>}
                textButton='Login with Facebook'
                callback={faceBookCB}
                reAuthenticate={false}/>
            <GoogleLogin
                tag='a'
                clientId={GOOGLE_CLIENT_ID}
                className='social-login__button social-login__button--google'
                offline={false}
                onSuccess={responseGoogle => googleCB(responseGoogle)}
                onFailure={responseGoogle => googleCB(responseGoogle)}>
                <Icon className='social-login__icon' size='lg' glyph={GoogleSVG}/>
                <span className='social-login__button-text'>Login with Google</span>
            </GoogleLogin>
        </div>
    );
};

SocialLogin.propTypes = {
    socialLoginClassName: PropTypes.string.isRequired,
    faceBookCB: PropTypes.func.isRequired,
    googleCB: PropTypes.func.isRequired
};

export default SocialLogin;
