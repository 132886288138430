import { createAction } from 'redux-actions';
import { reset, startSubmit, stopSubmit } from 'redux-form';
import { fromJS } from 'immutable';
import moment from 'moment';
import * as dashboardPageTypes from '../../types/dashboard/dashboardPageTypes';
import * as GlanceService from '../../services/glance';
import * as DailyNotesService from '../../services/dailyNotes';
import { DEFAULT_DATE_FORMAT } from '../../reducers/dashboardPageReducer';
import { createLocationOptions, createEventOptions } from '../../services/options';
import { setLocationOptions, setClassOptions } from '../miscActions';
import { setGlobalError } from '../errorHandlerActions';
import { deleteDogReport } from '../../services/dogReports';
import * as DogReportsActions from './dogReportsActions';
import * as EventActions from './eventActions';

export const resetDashboardPageReducer = createAction(dashboardPageTypes.RESET_DASHBOARD_PAGE_REDUCER);
export const startGlanceDataAllLoading = createAction(dashboardPageTypes.START_GLANCE_DATA_ALL_LOADING);
export const startGlanceTrainingDataAllLoading = createAction(dashboardPageTypes.START_GLANCE_TRAINING_DATA_ALL_LOADING);
export const finishGlanceDataAllLoading = createAction(dashboardPageTypes.FINISH_GLANCE_DATA_ALL_LOADING);
export const finishGlanceTrainingDataAllLoading = createAction(dashboardPageTypes.FINISH_GLANCE_TRAINING_DATA_ALL_LOADING);
export const startGlanceDataLocationLoading = createAction(dashboardPageTypes.START_GLANCE_DATA_LOCATION_LOADING);
export const startGlanceTrainingDataLocationLoading = createAction(dashboardPageTypes.START_GLANCE_TRAINING_DATA_LOCATION_LOADING);
export const finishGlanceDataLocationLoading = createAction(dashboardPageTypes.FINISH_GLANCE_DATA_LOCATION_LOADING);
export const finishGlanceTrainingDataLocationLoading = createAction(dashboardPageTypes.FINISH_GLANCE_TRAINING_DATA_LOCATION_LOADING);
export const setCurrentDate = createAction(dashboardPageTypes.SET_CURRENT_DATE);
export const filterLinearDashboardData = createAction(dashboardPageTypes.FILTER_LINEAR_DASHBOARD_DATA);
export const setCurrentNotesDate = createAction(dashboardPageTypes.SET_CURRENT_NOTES_DATE);
export const setCurrentFilter = createAction(dashboardPageTypes.SET_CURRENT_FILTER);
export const setSelectedZoneCard = createAction(dashboardPageTypes.SET_SELECTED_ZONE_CARD);
export const setSelectedTrainingZoneCard = createAction(dashboardPageTypes.SET_SELECTED_TRAINING_ZONE_CARD);
export const setGlanceTrainingAllItems = createAction(dashboardPageTypes.SET_GLANCE_TRAINING_ALL_ITEMS);
export const setGlanceAllItems = createAction(dashboardPageTypes.SET_GLANCE_ALL_ITEMS);
export const setGlanceLocationItems = createAction(dashboardPageTypes.SET_GLANCE_LOCATION_ITEMS);
export const setGlanceTrainingLocationItems = createAction(dashboardPageTypes.SET_GLANCE_TRAINING_LOCATION_ITEMS);
export const startDailyNotesLoading = createAction(dashboardPageTypes.START_DAILY_NOTES_LOADING);
export const finishDailyNotesLoading = createAction(dashboardPageTypes.FINISH_DAILY_NOTES_LOADING);
export const setDailyNotesItems = createAction(dashboardPageTypes.SET_DAILY_NOTES_ITEMS);
export const prependDailyNotesItems = createAction(dashboardPageTypes.PREPEND_DAILY_NOTES_ITEMS);
export const appendDailyNotesItems = createAction(dashboardPageTypes.APPEND_DAILY_NOTES_ITEMS);
export const startGlanceClassesProductsDataAll = createAction(dashboardPageTypes.START_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING);
export const finishGlanceClassesProductsDataAll = createAction(dashboardPageTypes.FINISH_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING);
export const startGlanceTrainingsProductsDataAll = createAction(dashboardPageTypes.START_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING);
export const finishGlanceTrainingsProductsDataAll = createAction(dashboardPageTypes.FINISH_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING);
export const setGlanceClassesProductsData = createAction(dashboardPageTypes.SET_GLANCE_CLASSES_PRODUCTS_DATA);
export const setGlanceTrainingsProductsData = createAction(dashboardPageTypes.SET_GLANCE_TRAININGS_PRODUCTS_DATA);
export const setGlanceView = createAction(dashboardPageTypes.SET_GLANCE_VIEW);
export const updateDashboardClassProductLocations = createAction(dashboardPageTypes.EDIT_CLASS_PRODUCT_LOCATIONS);
export const updateDashboardTrainingProductLocations = createAction(dashboardPageTypes.EDIT_TRAINING_PRODUCT_LOCATIONS);
export const changeClassProductStatus = createAction(dashboardPageTypes.CHANGE_CLASS_PRODUCT_STATUS);
export const changeTrainingProductStatus = createAction(dashboardPageTypes.CHANGE_TRAINING_PRODUCT_STATUS);
export const updateDashboardProductReports = createAction(dashboardPageTypes.UPDATE_DASHBOARD_PRODUCT_REPORTS);
export const removeDashboardProductReportCard = createAction(dashboardPageTypes.REMOVE_DASHBOARD_PRODUCT_REPORT_CARD);

export const getSetGlanceInitData = () => {
    return dispatch => {
        const date = moment().format(DEFAULT_DATE_FORMAT);
        dispatch(setCurrentDate(date));
        dispatch(setCurrentNotesDate(date));
        dispatch(startGlanceDataAllLoading());
        dispatch(startGlanceTrainingDataAllLoading());
        dispatch(startGlanceClassesProductsDataAll());
        dispatch(startGlanceTrainingsProductsDataAll());
        return Promise.all([
            dispatch(GlanceService.getGlanceLocationOptions()),
            dispatch(GlanceService.getClassOptions()),
            dispatch(GlanceService.getGlanceAll({ date })),
            dispatch(GlanceService.getGlanceTrainingAll({ date })),
            dispatch(GlanceService.getGlanceClassesProductsAll({ date })),
            dispatch(GlanceService.getGlanceTrainingsProductsAll({ date }))
        ]).then(([locatinOptionsResponse, classOptionsResponse, glanceAllResponse, glanceTrainingAllResponse, glanceClassesProductsAllResponse, glanceTrainingsProductsAllResponse]) => {
            dispatch(setGlanceAllItems(glanceAllResponse.data));
            dispatch(setGlanceTrainingAllItems(glanceTrainingAllResponse.data));
            dispatch(setGlanceClassesProductsData({ data: glanceClassesProductsAllResponse.data }));
            dispatch(setGlanceTrainingsProductsData({ data: glanceTrainingsProductsAllResponse.data }));
            dispatch(setClassOptions(fromJS(createEventOptions({ data: classOptionsResponse.data }))));
            dispatch(setLocationOptions(createLocationOptions({ data: locatinOptionsResponse.data })));
            dispatch(finishGlanceDataAllLoading());
            dispatch(finishGlanceTrainingDataAllLoading());
            dispatch(finishGlanceClassesProductsDataAll());
            dispatch(finishGlanceTrainingsProductsDataAll());
        }).catch(err => {
            dispatch(finishGlanceDataAllLoading());
            dispatch(finishGlanceTrainingDataAllLoading());
            dispatch(setGlobalError(err));
        });
    };
};

export const getGlanceAllData = ({ date }) => {
    return dispatch => {
        dispatch(startGlanceDataAllLoading());
        dispatch(GlanceService.getGlanceAll({ date }))
            .then(response => {
                dispatch(setGlanceAllItems(response.data));
            })
            .catch(err => {
                dispatch(finishGlanceDataAllLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getGlanceTrainingAllData = ({ date }) => {
    return dispatch => {
        dispatch(startGlanceTrainingDataAllLoading());
        dispatch(GlanceService.getGlanceTrainingAll({ date }))
            .then(response => {
                dispatch(setGlanceTrainingAllItems(response.data));
            })
            .catch(err => {
                dispatch(finishGlanceTrainingDataAllLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getGlanceClassesProductsAllData = ({ date }) => {
    return dispatch => {
        dispatch(startGlanceClassesProductsDataAll());
        dispatch(GlanceService.getGlanceClassesProductsAll({ date }))
            .then(response => {
                dispatch(setGlanceClassesProductsData({ data: response.data }));
            })
            .catch(err => {
                dispatch(finishGlanceClassesProductsDataAll());
                dispatch(setGlobalError(err));
            });
    };
};

export const getGlanceTrainingsProductsAllData = ({ date }) => {
    return dispatch => {
        dispatch(startGlanceTrainingsProductsDataAll());
        dispatch(GlanceService.getGlanceTrainingsProductsAll({ date }))
            .then(response => {
                dispatch(setGlanceTrainingsProductsData({ data: response.data }));
            })
            .catch(err => {
                dispatch(finishGlanceTrainingsProductsDataAll());
                dispatch(setGlobalError(err));
            });
    };
};

export const filterLinearDashboard = ({ name }) => {
    return dispatch => {
        dispatch(filterLinearDashboardData(name))
    }
}

export const getGlanceLocationData = ({ date, trainingClassName }) => {
    return dispatch => {
        dispatch(startGlanceDataLocationLoading());
        dispatch(GlanceService.getGlanceLocation({ date, trainingClassName }))
            .then(response => {
                dispatch(setGlanceLocationItems({ data: response.data, trainingClassName }));
            })
            .catch(err => {
                dispatch(finishGlanceDataLocationLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getGlanceTrainingLocationData = ({ date, trainingName }) => {
    return dispatch => {
        dispatch(startGlanceTrainingDataLocationLoading());
        dispatch(GlanceService.getGlanceTrainingLocation({ date, trainingName }))
            .then(response => {
                dispatch(setGlanceTrainingLocationItems({ data: response.data, trainingName }));
            })
            .catch(err => {
                dispatch(finishGlanceTrainingDataLocationLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getDailyNotesData = () => {
    const date = moment().format(DEFAULT_DATE_FORMAT)
    return dispatch => {
        dispatch(startDailyNotesLoading());
        dispatch(DailyNotesService.getDailyNotes({date}))
            .then(response => {
                dispatch(setDailyNotesItems(response.data));
            })
            .catch(err => {
                dispatch(finishDailyNotesLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getDailyNotes = ({ date }) => {
    return dispatch => {
        dispatch(startDailyNotesLoading());
        dispatch(DailyNotesService.getDailyNotes({date}))
            .then(response => {
                dispatch(setDailyNotesItems(response.data));
            })
            .catch(err => {
                dispatch(finishDailyNotesLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getMoreDailyNotesData = data => {
    const date = data['date']
    const page = data['nextPage']
    return dispatch => {
        dispatch(DailyNotesService.getDailyNotes({page, date}))
            .then(response => {
                dispatch(appendDailyNotesItems(response.data));
            })
            .catch(err => {
                dispatch(setGlobalError(err));
            });
    };
};

export const submitDailyNotesData = (date, data) => {
    return dispatch => {
        dispatch(startSubmit('employeeDailyNotesForm'));
        dispatch(DailyNotesService.postDailyNotes({ data, date }))
            .then(response => {
                dispatch(stopSubmit('employeeDailyNotesForm'));
                dispatch(reset('employeeDailyNotesForm'));
                dispatch(prependDailyNotesItems(response.data));
            })
            .catch(err => {
                dispatch(stopSubmit('employeeDailyNotesForm'));
                dispatch(setGlobalError(err));
            });
    };
};

export const setClassEmptyReports = (classData) => {
    const { id, reports } = classData;
    const event_id = id
    return (dispatch, getState) => {
        const reportRequests = Object.keys(reports).map((reportInfo) => {
            const { id } = reports[reportInfo];
            return dispatch(deleteDogReport({ id }))
                .then(() => {
                    dispatch(removeDashboardProductReportCard(event_id));
                });
        });
    };
};
