/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DropZone from 'react-dropzone';
import { getOrientation, rotateImage, validateImage } from '../../../../helpers/image';
import { dataStates } from '../../../../helpers/state';

class renderImageControl extends React.PureComponent {
    state = {
        file: null,
        image: null,
        error: null
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ image: this.props.image });
    }

    componentWillReceiveProps(nextProps) {
        const { isOpened, dataState } = this.props;
        const next = nextProps.isOpened;
        const nextDataState = nextProps.dataState;
        const { submitting, loaded, polling } = dataStates;

        const isClosing = (!nextProps.isOpened && (nextProps.isOpened !== this.props.isOpened));
        const isOpeningWithFileLoaded = (next && this.state.file) && (isOpened !== next);
        const isSuccessfulSubmitting = (nextDataState && this.state.file) && (dataState !== nextDataState) &&
            dataState === submitting && (nextDataState === loaded || nextDataState === polling);

        if (isClosing) {
            return this.clearPreview();
        }

        if (isOpeningWithFileLoaded) {
            this.setState({ file: null });
        }

        // clearing file state after complete submit in MessagesWidget
        if (isSuccessfulSubmitting) {
            return this.clearFiles();
        }

        return this.setState({ image: nextProps.image, error: null });
    }

    componentWillUnmount() {
        this.clearPreview();
    }

    // eslint-disable-next-line consistent-return
    onDropAccepted = (acceptedFiles) => {
        const { multiple, maxFiles } = this.props;
        if (maxFiles) {
            if (this.state.file !== null) {
                const uploadedFilesAmount = this.state.file.length;
                if (acceptedFiles.length + uploadedFilesAmount > maxFiles) {
                    acceptedFiles.splice(maxFiles - uploadedFilesAmount);
                    const newFiles = this.state.file.concat(acceptedFiles);
                    return this.updateFiles(newFiles);
                }
            }
            if (acceptedFiles.length > maxFiles) {
                acceptedFiles.splice(maxFiles);
                return this.updateFiles(acceptedFiles);
            }
        }

        if (multiple) {
            if (this.state.file === null) {
                return this.updateFiles(acceptedFiles);
            } else {
                const newFiles = this.state.file.concat(acceptedFiles);
                return this.updateFiles(newFiles);
            }
        } else {
            this.clearPreview();
            this.updateSingleFile(acceptedFiles[0]);
        }
    };

    onDropRejected = (rejectedFiles) => {
        console.log('onDropRejected: rejectedFiles', rejectedFiles);
        this.setState({ image: this.props.image, error: 'File is not a valid image' });
    };

    getImage = () => {
        const { image, file } = this.state;

        if (!image && !file) {
            return null;
        }

        if (this.props.multiple) {
            return file.map((fil) => {
                const { name, preview } = fil;
                return { name, preview };
            });
        }
        return file ? file.preview : image;
    };

    updateFiles = (file) => {
        this.setState({ file });
        this.props.input.onChange(file);
    };

    updateSingleFile = (file) => {
        const { input: { onChange } } = this.props;
        if (!this.props.multiple) {
            const newFile = file;

            validateImage(file)
            .then(({ validatedFile, validatedImage, needsRotation }) => {
                if (needsRotation) {
                    const reader = new FileReader();
                    const imagePreview = new Image();
                    imagePreview.onload = async () => {
                        const rotatedCanvas = await rotateImage({ imageObject: imagePreview });

                        if (rotatedCanvas) {
                            newFile.preview = rotatedCanvas.toDataURL();
                        }

                        this.setState({ file: newFile });
                        onChange([newFile]);
                    };
                    reader.onload = (e) => {
                        imagePreview.src = e.target.result;
                    };
                    reader.readAsDataURL(newFile);
                } else {
                    this.setState({ file });
                    onChange([file]);
                }
            })
            .catch(errorMessage => this.setState({ image: this.props.image, error: errorMessage }));
        } else {
            this.setState({ file });
            onChange([file]);
        }
    };

    clearPreview = () => {
        if (this.state.file) {
            // Avoid memory leaks.
            window.URL.revokeObjectURL(this.state.file.preview);
        }
        this.setState({ image: null });
    };

    deleteImage = (name) => {
        const { file } = this.state;

        let filteredFiles;
        if (file !== null) {
            filteredFiles = file.filter(img => {
                return img.name !== name;
            });
        }

        this.props.input.onChange(filteredFiles);
        this.setState({ file: filteredFiles });
    };

    clearFiles = () => {
        this.props.input.onChange();
        this.setState({ file: null });
    };

    dropzone = null;

    render() {
        const {
            meta: { touched, error }, preview, hint, className = '', multiple = false, disabled, input: { name },
        } = this.props;
        const UploadComponent = this.props.uploadComponent;
        const ImageContainerClassNames = classNames({
            'image-control': true,
            'image-control__zone_failed': touched && error,
        });
        return (
            <div className={`${ImageContainerClassNames} ${className}`}>
                <DropZone
                    ref={node => { this.dropzone = node; }}
                    name='imageControl'
                    className='image-control__input_hidden'
                    accept='image/jpeg, image/png, image/jpg, image/tiff'
                    multiple={multiple}
                    onDropRejected={this.onDropRejected}
                    onDropAccepted={this.onDropAccepted}/>
                <UploadComponent
                    image={this.getImage()}
                    disabled={disabled}
                    name={name}
                    deleteImage={name => this.deleteImage(name)}
                    clickHandler={() => this.dropzone.open()}
                    clearFiles={() => this.clearFiles()}/>
                {touched && error && <span className='input-control__error'>{error}</span>}
                {this.state.error && <span className='input-control__error'>{this.state.error}</span>}
                {hint && <span className='image-control__hint'>{hint}</span>}
            </div>
        );
    }
}

renderImageControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func,
    }),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    isTextarea: PropTypes.bool,
    formError: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    image: PropTypes.string,
    isOpened: PropTypes.bool,
    uploadComponent: PropTypes.func,
    preview: PropTypes.string,
    multiple: PropTypes.bool,
    dataState: PropTypes.string,
    maxFiles: PropTypes.number,
};

export default renderImageControl;
