import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SmsVerificationStepForm from './SmsVerificationStepForm';
import { sendVerificationRequest, verifyCustomersPhone } from '../../../../../services/customers';

class SmsVerificationStep extends PureComponent {
    componentWillMount() {
        const { sendVerificationRequest, customerPrimaryPhone, goToStep } = this.props;
        if (!customerPrimaryPhone) {
            goToStep(2);
        } else if (customerPrimaryPhone.id) {
            sendVerificationRequest(customerPrimaryPhone.id);
        }
    }

    render() {
        const { goToStep, customerPrimaryPhone,
                verifyCustomersPhone, sendVerificationRequest } = this.props;
        const phoneNumber = customerPrimaryPhone && customerPrimaryPhone.phone_number ? customerPrimaryPhone.phone_number : '';
        return (
            <div className='sms-modal__verification'>
                <div className='sms-modal__content'>
                    <h5 className='sms-modal__title'>We Sent You a Text Message</h5>
                    <p className='sms-modal__text'>We sent you a verification code to <strong>{phoneNumber}</strong>. To receive your automated updates, please enter the code below.</p>
                    <p className='sms-modal__verification__note'>
                        <span>Want to use a different phone number? </span>
                        <span><a onClick={() => goToStep(2)}>Click Here</a> to add a new number.</span>
                    </p>
                </div>
                <SmsVerificationStepForm
                    sendVerificationRequest={() => sendVerificationRequest(customerPrimaryPhone.id)}
                    onSubmit={(values) => {
                        return verifyCustomersPhone(customerPrimaryPhone.id, values.get('code'))
                            .then(() => goToStep(4));
                    }}
                    onCancelHandler={() => goToStep(3)} />
            </div>
        );
    }
}

SmsVerificationStep.propTypes = {
    goToStep: PropTypes.func.isRequired,
    sendVerificationRequest: PropTypes.func.isRequired,
    verifyCustomersPhone: PropTypes.func.isRequired,
    customerPrimaryPhone: PropTypes.shape({})
};

const mapDispatchToProps = {
    sendVerificationRequest,
    verifyCustomersPhone
};

export default connect(null, mapDispatchToProps)(SmsVerificationStep);
