import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as MiscActions from '../../../actions/miscActions';
import * as ModalSidebarActions from '../../../actions/modal/modalSidebarActions';
import { ReactComponent as IconLogoutCustomer } from '../../../assets/icons/icon-logout-customer.svg';
import { ReactComponent as IconLogout } from '../../../assets/icons/icon-logout.svg';
import { ReactComponent as IconMenuProfile } from '../../../assets/icons/icon-menu-profile.svg';
import { ReactComponent as IconProfile } from '../../../assets/icons/icon-profile.svg';
import { ReactComponent as IconSearch } from '../../../assets/icons/icon-search.svg';
import { isCustomer, isTrainer } from '../../../helpers/userRoles';
import { logout } from '../../../services/oauth';
import { loadProfileData } from '../../../services/profile';
import Logo from '../../common/Logo';
import CustomerNavbar from '../CustomerNavbar';
import Dropdown from '../Dropdown/Dropdown';
import Hamburger from '../Hamburger/';
import SearchBar from '../SearchBar';
import DailyNotifications from '../../containers/Dashboard/DailyNotifications';

const HeaderMenuButton = (props) => {
    const { avatar, onClick } = props; // eslint-disable-line
    return (
        <button className='icon-button page-header__button' onClick={onClick}>
            {avatar && <img className='page-header__button-img' src={avatar} alt=''/>}
            {!avatar &&
                <IconProfile className='icon_profile'/>
            }
        </button>
    );
};

class PageHeader extends React.PureComponent {

    state = {
        isMobileSearchBarOpened: false
    };

    componentWillMount() {
        const { isSetProfileData, handleLoadProfileData } = this.props;
        if (isSetProfileData) {
            handleLoadProfileData();
        }
    }

    setMobileSearchBarClosed = () => {
        this.setState({
            isMobileSearchBarOpened: false
        });
    };

    setMobileSearchBarOpened = () => {
        this.setState({
            isMobileSearchBarOpened: true
        });
    };

    toggleSidebar = () => {
        const { isModalSidebarOpened, setMobileSidebarClosed, setMobileSidebarOpened } = this.props;
        if (isModalSidebarOpened) {
            setMobileSidebarClosed();
        } else {
            setMobileSidebarOpened();
        }
    };

    renderHeaderMenuButton = (props) => {
        const { avatar } = this.props;
        return <HeaderMenuButton avatar={avatar} {...props} />;
    };

    renderSimpleHeader = () => {
        return (
            <header className='page-header'>
                <div className='page-header-logo page-header_item'>
                    <Logo logoClassName='logo logo_customer logo_white'/>
                </div>
            </header>
        );
    };

    renderCustomerHeader = () => {
        const { avatar, onModalClose } = this.props;
        return (
            <header className='page-header page-header_customer' onClick={onModalClose}>
                <div className='page-header-logo page-header_item'>
                    <Logo
                        logoTo={isCustomer()
                            ? '/home'
                            : '/dashboard'}
                        logoClassName='logo logo_customer logo_white'/>
                </div>
                <div className='page-header__customer-navbar page-header_item'>
                    <CustomerNavbar/>
                </div>
                <div className='page-header-buttons page-header_item'>
                    <div className='button-set'>
                        <Link to='/profile'>
                            <HeaderMenuButton avatar={avatar}/>
                        </Link>
                        <button
                            className='page-header__logout-btn'
                            onClick={(e) => {
                                this.props.logout();
                                e.preventDefault();
                            }}>
                            <IconLogoutCustomer className='icon_customer-logout'/>
                            Log Out
                        </button>
                    </div>
                </div>
            </header>
        );
    };

    renderHeader() {
        const { isMobileSearchBarOpened } = this.state;
        const mainMenuItems = [
            {
                iconClass: 'icon_menu-profile',
                icon: IconMenuProfile,
                text: 'Profile',
                link: '/dashboard/profile/edit'
            }, {
                iconClass: 'icon_edit',
                icon: IconLogout,
                text: 'Logout',
                onClickCB: this.props.logout
            }
        ];

        const searchBarClass = classNames({
            'page-header-search-bar': true,
            'page-header_item': true,
            'page-header-search-bar_opened': isMobileSearchBarOpened,
        });
        return (
            <div className='dashboard__header-alert-container'>
                <div className='dashboard__header'>
                    <header className='page-header page-header_dashboard'>
                        <div className='page-header-logo page-header_item'>
                            <Logo
                                logoTo={isCustomer()
                                    ? '/home'
                                    : '/dashboard'}
                                logoClassName='logo logo_33 logo_white'/>
                        </div>
                        <div className={searchBarClass}>
                            <SearchBar handleClose={this.setMobileSearchBarClosed}/>
                        </div>
                        <div className='page-header-buttons page-header_item'>
                            <div className='button-set'>
                                { !isTrainer() &&
                                    <DailyNotifications className='page-header__daily-notifications'/>
                                }
                                <IconSearch className='page-header__search-icon' onClick={this.setMobileSearchBarOpened}/>
                                <Dropdown
                                    classNames='page-header__avatar'
                                    items={mainMenuItems}
                                    ButtonRenderer={this.renderHeaderMenuButton}/>
                            </div>
                        </div>

                        <Hamburger className='page-header__hamburger' handleClick={this.toggleSidebar} />
                    </header>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.isSimpleHeader) {
            return this.renderSimpleHeader();
        }
        if (isCustomer()) {
            return this.renderCustomerHeader();
        }
        return this.renderHeader();
    }
}

PageHeader.propTypes = {
    isSetProfileData: PropTypes.bool,
    avatar: PropTypes.string,
    handleLoadProfileData: PropTypes.func,
    logout: PropTypes.func,
    onModalClose: PropTypes.func,
    isSimpleHeader: PropTypes.bool,
    isModalSidebarOpened: PropTypes.bool,
    setMobileSidebarOpened: PropTypes.func,
    setMobileSidebarClosed: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isSetProfileData: !state.getIn(['user', 'full_name']),
    avatar: state.getIn(['user', 'photo']) || '',
    isModalSidebarOpened: state.getIn(['misc', 'mobileSidebar', 'isOpened'])
});

const mapDispatchToProps = (dispatch) => ({
    handleLoadProfileData: bindActionCreators(loadProfileData, dispatch),
    logout: bindActionCreators(logout, dispatch),
    onModalClose: bindActionCreators(ModalSidebarActions.onModalClose, dispatch),
    setMobileSidebarOpened: bindActionCreators(MiscActions.setMobileSidebarOpened, dispatch),
    setMobileSidebarClosed: bindActionCreators(MiscActions.setMobileSidebarClosed, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
