import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import Icon from '../../common/Icon';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import { capitalizeEachWord } from '../../../helpers/text';
import LinkButton from '../../common/LinkButton';

class FilterTags extends React.PureComponent {
    state = {
        tags: fromJS([]),
    };

    filterTagsClearHandler = () => {
        const { filterForm } = this.props;

        if (filterForm) {
            filterForm.clearFilters.call(filterForm);
        }
    };

    renderOldFilterTags() {
        const { tags, tagsRemoveHandler } = this.props;
        if (tags && tags.length) {
            return tags.map((tag, i) => {
                return (
                    <div className='filter-tag' key={tag + i}>
                        {capitalizeEachWord(tag)}
                        <a
                            onClick={e => {
                                e.preventDefault();
                                tagsRemoveHandler(i);
                            }}>
                            <Icon glyph={IconClose} className='icon_close filter-tag-remove' />
                        </a>
                    </div>
                );
            });
        }

        return 'No Filter Selected';
    }

    renderFilterTags() {
        const { tags } = this.state;
        const { filterForm } = this.props;
        if (tags && tags.size) {
            return tags.toJS().map((tag, tagIndex) => {
                return (
                    <div className='filter-tag' key={tag.value + tag.query + tagIndex}>
                        <span className='filter-tag__text'>
                            {capitalizeEachWord(tag.label)}
                        </span>
                        <a
                            onClick={e => {
                                e.preventDefault();
                                if (filterForm) {
                                    filterForm.removeTagByIndex.call(filterForm, tagIndex);
                                }
                            }}>
                            <Icon glyph={IconClose} className='icon_close filter-tag-remove' />
                        </a>
                    </div>
                );
            });
        }

        return 'No Filter Selected';
    }

    renderClearButton() {
        const { tags } = this.state;
        if (tags.size) {
            return (
                <LinkButton
                    className='filter-tags__link-button'
                    text='Clear'
                    onClickHandler={this.filterTagsClearHandler}/>
            );
        }

        return null;
    }

    render() {
        const { newVersion = false, hideIfEmpty = false, className = '' } = this.props;
        if (!newVersion) {
            return (
                <div className={`${className && className + ' '}filter-tags`}>
                    <div className='filter-tags__list'>
                        {this.renderOldFilterTags()}
                    </div>
                </div>
            );
        }

        if (hideIfEmpty && !this.state.tags.size) return null;

        return (
            <div className={'filter-tags__container' + className && (className + ' ')}>
                <div className='filter-tags'>
                    <div className='filter-tags__list'>
                        {this.renderFilterTags()}
                    </div>
                </div>
                {this.renderClearButton()}
            </div>
        );
    }
}

FilterTags.propTypes = {
    className: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    tagsRemoveHandler: PropTypes.func,
    newVersion: PropTypes.bool,
    hideIfEmpty: PropTypes.bool,
    filterForm: PropTypes.shape({}),
};

export default FilterTags;
