/* eslint-disable camelcase */
import { createAction } from 'redux-actions';
import * as CustomReportsService from '../../services/customReports';
import * as CustomReportsTypes from '../../types/dashboard/customReportsTypes';
import { setGlobalError } from '../errorHandlerActions';

export const setReportDataLoading = createAction(CustomReportsTypes.SET_REPORT_DATA_LOADING);
export const setReportDataLoaded = createAction(CustomReportsTypes.SET_REPORT_DATA_LOADED);
export const setReportDataFailed = createAction(CustomReportsTypes.SET_REPORT_DATA_FAILED);
export const setReportData = createAction(CustomReportsTypes.SET_REPORT_DATA);
export const setFilterType = createAction(CustomReportsTypes.SET_FILTER_TYPE);
export const setFilterReportAndTarget = createAction(CustomReportsTypes.SET_FILTER_REPORT_AND_TARGET);
export const setReportDate = createAction(CustomReportsTypes.SET_REPORT_DATE);

export const getCustomReportData = (values) => {
    const dateNormalized = values.get('date') === 'None' ? values.get('date_range') : values.get('date');
    return dispatch => {
        dispatch(setReportDataLoading());
        dispatch(setFilterType(values.get('type')));
        dispatch(setFilterReportAndTarget(values.get('report')));
        dispatch(setReportDate(dateNormalized));
        return dispatch(CustomReportsService.loadCustomReportData(values.toJS()))
            .then(response => {
                console.log('customReportsActions', JSON.parse(JSON.stringify(response)));
                dispatch(setReportDataLoaded());
                dispatch(setReportData(response.data));
            })
            .catch(err => {
                dispatch(setReportDataFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const exportReportTo = values => {
    return dispatch => {
        return dispatch(CustomReportsService.loadCustomReportData(values, true))
            .then(response => {
                const { date, date_range, report, type } = values;

                let reportFileName = `${type}-${report}-report-`;
                if (date && date !== 'None') {
                    reportFileName += date;
                } else if (date_range && date_range.start !== 'None') {
                    reportFileName += date_range.start + '-' + date_range.end;
                }

                const link = document.createElement('a');
                link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data));
                link.setAttribute('download', `${reportFileName}.csv`);
                link.click();
            })
            .catch(err => {
                dispatch(setGlobalError(err));
            });
    };
};
