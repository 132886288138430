import { createAction } from 'redux-actions';
import { getUserNameSpace } from '../../helpers/userRoles';
import { postAvailableAddressAsync } from '../../services/availableAddresses';
import { setFormError, setGlobalError } from '../errorHandlerActions';
import * as TrainingsTypes from '../../types/dashboard/trainingsTypes';
import * as EventActions from './eventActions';
import {
    getPreviousTrainings,
    getUpcomingTrainings,
    patchPreviousTrainings,
    patchPreviousTrainingsAsync,
    patchUpcomingTrainings,
    patchUpcomingTrainingsAsync
} from '../../services/trainings';


export const markCustomersPreviousTrainingsLoaded = createAction(TrainingsTypes.MARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED);
export const unmarkCustomersPreviousTrainingsLoaded = createAction(TrainingsTypes.UNMARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED);
export const setCustomersPreviousTrainings = createAction(TrainingsTypes.SET_CUSTOMER_PREVIOUS_TRAININGS);
export const setCustomersPreviousTrainingDetails = createAction(TrainingsTypes.SET_CUSTOMER_PREVIOUS_TRAINING_DETAILS);
export const getCustomersPreviousTrainings = (customerId) => getPreviousTrainings({
    role: getUserNameSpace('customers.previous-trainings'),
    context: 'customers',
    id: customerId
});
export const patchCustomersPreviousTrainingStatus = (trainingData, status) => patchPreviousTrainings({
    role: getUserNameSpace('customers.previous-trainings'),
    context: 'customers',
    trainingData: {
        contextId: trainingData.owner_detail.id,
        trainingId: trainingData.id,
        status
    }
});

export const markCustomersUpcomingTrainingsLoaded = createAction(TrainingsTypes.MARK_CUSTOMER_UPCOMING_TRAININGS_LOADED);
export const unmarkCustomersUpcomingTrainingsLoaded = createAction(TrainingsTypes.UNMARK_CUSTOMER_UPCOMING_TRAININGS_LOADED);
export const setCustomersUpcomingTrainings = createAction(TrainingsTypes.SET_CUSTOMER_UPCOMING_TRAININGS);
export const setCustomersUpcomingTrainingDetails = createAction(TrainingsTypes.SET_CUSTOMER_UPCOMING_TRAINING_DETAILS);
export const getCustomersUpcomingTrainings = (customerId) => getUpcomingTrainings({
    role: getUserNameSpace('customers.upcoming-trainings'),
    context: 'customers',
    id: customerId
});

export const patchCustomersUpcomingTrainingStatus = (trainingData, status, queryParams) => {
    return patchUpcomingTrainingsAsync({
        role: getUserNameSpace('customers.upcoming-trainings'),
        context: 'customers',
        trainingData: {
            contextId: trainingData.owner_detail.id,
            trainingId: trainingData.id,
            status
        },
        queryParams
    });
};

export const removeCustomersUpcomingTraining = createAction(TrainingsTypes.REMOVE_CUSTOMERS_UPCOMING_TRAINING);
export const markCustomersUpcomingTraining = (trainingData, status) => {
    return dispatch => {
        dispatch(patchCustomersUpcomingTrainingStatus(trainingData, status))
            .then((response) => {
                dispatch(removeCustomersUpcomingTraining(response.data.id));
                dispatch(getCustomersPreviousTrainings(response.data.owner_detail.id));
            })
            .catch((error) => {
                dispatch(setGlobalError(error));
            });
    };
};

export const markDogsPreviousTrainingsLoaded = createAction(TrainingsTypes.MARK_DOG_PREVIOUS_TRAININGS_LOADED);
export const unmarkDogsPreviousTrainingsLoaded = createAction(TrainingsTypes.UNMARK_DOG_PREVIOUS_TRAININGS_LOADED);
export const setDogsPreviousTrainings = createAction(TrainingsTypes.SET_DOG_PREVIOUS_TRAININGS);
export const setDogsPreviousTrainingsDetails = createAction(TrainingsTypes.SET_DOG_PREVIOUS_TRAINING_DETAILS);
export const getDogsPreviousTrainings = (dogsId) => getPreviousTrainings({
    role: getUserNameSpace('dogs.previous-trainings'),
    context: 'dogs',
    id: dogsId
});
export const patchDogsPreviousTrainingStatus = (trainingData, status) => patchPreviousTrainings({
    role: getUserNameSpace('dogs.previous-trainings'),
    context: 'dogs',
    trainingData: {
        contextId: trainingData.dog,
        trainingId: trainingData.id,
        status
    }
});

export const markDogsUpcomingTrainingsLoaded = createAction(TrainingsTypes.MARK_DOG_UPCOMING_TRAININGS_LOADED);
export const unmarkDogsUpcomingTrainingsLoaded = createAction(TrainingsTypes.UNMARK_DOG_UPCOMING_TRAININGS_LOADED);
export const setDogsUpcomingTrainings = createAction(TrainingsTypes.SET_DOG_UPCOMING_TRAININGS);
export const setDogsUpcomingTrainingDetails = createAction(TrainingsTypes.SET_DOG_UPCOMING_TRAINING_DETAILS);
export const getDogsUpcomingTrainings = (dogsId) => getUpcomingTrainings({
    role: getUserNameSpace('dogs.upcoming-trainings'),
    context: 'dogs',
    id: dogsId
});
export const patchDogsUpcomingTrainingStatus = (trainingData, status, queryParams) => {
    return patchUpcomingTrainingsAsync({
        role: getUserNameSpace('dogs.upcoming-trainings'),
        context: 'dogs',
        trainingData: {
            contextId: trainingData.dog_detail.id,
            trainingId: trainingData.id,
            status,
        },
        queryParams
    });
};

export const patchDogsPreviousTrainingStatusAsync = (trainingData, status) => patchPreviousTrainingsAsync({
    role: getUserNameSpace('dogs.previous-trainings'),
    context: 'dogs',
    trainingData: {
        contextId: trainingData.dog,
        trainingId: trainingData.id,
        status,
    }
});
export const removeDogsUpcomingTraining = createAction(TrainingsTypes.REMOVE_DOGS_UPCOMING_TRAINING);
export const markDogsUpcomingTraining = (trainingData, status) => {
    return (dispatch, getState) => {
        dispatch(patchDogsUpcomingTrainingStatus(trainingData, status)).then((response) => {
            dispatch(removeDogsUpcomingTraining(response.data.id));
            dispatch(getDogsPreviousTrainings(response.data.dog_detail.id));
        });
    };
};