import React from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { weekDaysShort } from '../../../../../helpers/date';

class DateSlider extends React.PureComponent {
    static propTypes = {
        daysBefore: PropTypes.number.isRequired,
        daysAfter: PropTypes.number.isRequired,
        onClickHandler: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.swiper = null;
        this.days = this.getDays();
    }

    state = {
        activeIndex: this.props.daysBefore
    }

    componentDidMount() {
        window.addEventListener('load', () => {
            if (this.swiper) this.swiper.update(true);
        });
    }

    getDays() {
        const { daysBefore, daysAfter } = this.props;
        const days = [];
        const today = new Date().getDate();
        const getDay = (date, today = false) => {
            return {
                dayOfWeek: today ? 'today' : weekDaysShort[moment(date).day()],
                date: moment(date).format('MM/DD/YY'),
                dateISO: moment(date).format('YYYY-MM-DD')
            };
        };
        for (let i = 0; i < daysBefore + daysAfter + 1; i += 1) {
            const date = new Date().setDate(today - daysBefore + i);
            days.push(getDay(date, i === daysBefore));
        }
        return days;
    }

    setActiveSlide(index) {
        if (this.state.activeIndex === index) return;
        this.setState({
            activeIndex: index
        });
        this.props.onClickHandler(this.days[index].dateISO);
    }

    render() {
        const { daysBefore, daysAfter } = this.props;

        const params = {
            containerClass: 'date-slider',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 7,
            initialSlide: daysBefore,
            width: 722,
            onSlideChangeEnd: (swiper) => {
                swiper.update(true);
            },
            runCallbackOnInit: true,
            onInit: (swiper) => {
                this.swiper = swiper;
            },
            breakpoints: {
                1024: {
                    width: 612,
                    slidesPerView: 6,
                },
                736: {
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    width: window.innerWidth - 110
                }
            }
        };

        return (
            <div className='data-slider-wrapper'>
                <Swiper {...params}>
                    {
                        this.days.map((day, index) => {
                            const itemClass = classNames({
                                'date-slider__item': true,
                                'date-slider__item_active': index === this.state.activeIndex
                            });
                            return (
                                <div
                                    className={itemClass}
                                    key={'date-slide_' + index}
                                    onClick={this.setActiveSlide.bind(this, index)}>
                                    <div className='date-slider__day'>{day.dayOfWeek}</div>
                                    <div className='date-slider__date'>{day.date}</div>
                                </div>
                            );
                        })
                    }
                </Swiper>
            </div>
        );
    }
}

export default DateSlider;
