import PropTypes from 'prop-types';
import React from 'react';
import DailyNotesPage from '../DailyNotesPage';
import GlancePage from './GlancePage';
import TabSwitcher from './TabSwitcher';
import { isTrainer } from '../../../../helpers/userRoles';

class DashboardPage extends React.PureComponent {
    static propTypes = {
        activePage: PropTypes.string,
        currentDate: PropTypes.string,
        currentNotesDate: PropTypes.string,
        glanceView: PropTypes.string,
        currentFilter: PropTypes.shape({}),
        selectedZoneCard: PropTypes.shape({}),
        selectedTrainingZoneCard: PropTypes.shape({}),
        glanceDataAll: PropTypes.shape({}),
        glanceTrainingDataAll: PropTypes.shape({}),
        glanceDataLocation: PropTypes.shape({}),
        glanceTrainingDataLocation: PropTypes.shape({}),
        dailyNotesData: PropTypes.shape({}),
        locationOptions: PropTypes.shape({}),
        classOptions: PropTypes.shape({}),
        glanceClassesProductsAll: PropTypes.shape({}),
        glanceClassesProducts: PropTypes.shape({}),
        glanceTrainingsProductsAll: PropTypes.shape({}),
        glanceTrainingsProducts: PropTypes.shape({}),
        setCurrentDate: PropTypes.func,
        setCurrentNotesDate: PropTypes.func,
        setGlanceView: PropTypes.func,
        setCurrentFilter: PropTypes.func,
        onPageChange: PropTypes.func,
        onDailyNotesSubmit: PropTypes.func,
        setSelectedZoneCard: PropTypes.func,
        setSelectedTrainingZoneCard: PropTypes.func,
        getGlanceAll: PropTypes.func,
        getGlanceTrainingAll: PropTypes.func,
        getGlanceLocation: PropTypes.func,
        getGlanceTrainingLocation: PropTypes.func,
        getMoreDailyNotesData: PropTypes.func,
        getGlanceClassesProductsAllData: PropTypes.func,
        getGlanceTrainingsProductsAllData: PropTypes.func,
        getDailyNotes: PropTypes.func,
        filterLinearDashboard: PropTypes.func,
    };

    tabSwitcherItems = [
        {
            page: 'glance',
            title: 'At a glance'
        },
        {
            page: 'notes',
            title: 'Daily notes'
        }
    ];

    handleDateChange = newDate => {
        const { setCurrentDate, getGlanceAll, getGlanceTrainingAll, getGlanceClassesProductsAllData, getGlanceTrainingsProductsAllData, selectedZoneCard, setSelectedZoneCard, selectedTrainingZoneCard, setSelectedTrainingZoneCard, currentFilter } = this.props;
        setCurrentDate(newDate);
        getGlanceAll({ date: newDate });
        getGlanceTrainingAll({ date: newDate });
        getGlanceClassesProductsAllData({ date: newDate });
        getGlanceTrainingsProductsAllData({ date: newDate });

        if (selectedZoneCard !== undefined) {
            setSelectedZoneCard(undefined);
        }
        if (selectedTrainingZoneCard !== undefined) {
            setSelectedTrainingZoneCard(undefined);
        }
    };

    handleGlanceViewChange = ( e ) => {
        const { setGlanceView, glanceView } = this.props;
        e.preventDefault();
        setGlanceView(glanceView === 'list' ? 'grid' : 'list' )
    }

    handleNotesDateChange = newDate => {
        const { setCurrentNotesDate, getDailyNotes } = this.props;
        setCurrentNotesDate(newDate);
        getDailyNotes({ date: newDate });

    };

    handleFilterChange = (newValue, originalNewValue) => {
        const { setCurrentFilter, getGlanceAll, getGlanceTrainingAll, getGlanceClassesProductsAllData, getGlanceTrainingsProductsAllData, selectedZoneCard, setSelectedZoneCard, selectedTrainingZoneCard, setSelectedTrainingZoneCard, currentDate } = this.props;
        setCurrentFilter(originalNewValue);
        getGlanceAll({ date: currentDate });
        getGlanceTrainingAll({ date: currentDate });
        getGlanceClassesProductsAllData({ date: currentDate });
        getGlanceTrainingsProductsAllData({ date: currentDate });

        if (selectedTrainingZoneCard !== undefined) {
            setSelectedTrainingZoneCard(undefined);
        }
    };

    handleNavbarClick = pageName => {
        const { activePage, onPageChange } = this.props;

        if (pageName === activePage) {
            return;
        }

        onPageChange({ nextPageName: pageName });
    };

    handleZoneCardClick = ({ rowIndex, cardIndex, attendants, trainingClassName }) => {
        const {
            selectedZoneCard,
            setSelectedZoneCard,
            getGlanceLocation,
            currentDate,
            currentFilter,
            glanceDataLocation
        } = this.props;
        const selectedZoneCardJS = selectedZoneCard !== undefined ? selectedZoneCard.toJS() : {};

        if (selectedZoneCardJS.rowIndex === rowIndex && selectedZoneCardJS.cardIndex === cardIndex) {
            setSelectedZoneCard(undefined);
            return undefined;
        }
        setSelectedZoneCard({ rowIndex, cardIndex, attendants, trainingClassName });

        // If we don't have the state for this training class yet
        if (glanceDataLocation.get('trainingClassName') !== trainingClassName) {
            getGlanceLocation({
                date: currentDate,
                trainingClassName
            });
        }

        return undefined;
    };

    handleTrainingZoneCardClick = ({ rowIndex, cardIndex, attendants, trainingName }) => {
        const {
            selectedTrainingZoneCard,
            setSelectedTrainingZoneCard,
            getGlanceTrainingLocation,
            currentDate,
            currentFilter,
            glanceTrainingDataLocation
        } = this.props;

        const selectedTrainingZoneCardJS = selectedTrainingZoneCard !== undefined ? selectedTrainingZoneCard.toJS() : {};

        if (selectedTrainingZoneCardJS.rowIndex === rowIndex && selectedTrainingZoneCardJS.cardIndex === cardIndex) {
            setSelectedTrainingZoneCard(undefined);
            return undefined;
        }
        setSelectedTrainingZoneCard({ rowIndex, cardIndex, attendants, trainingName });

        // If we don't have the state for this training yet
        if (glanceTrainingDataLocation.get('trainingName') !== trainingName) {
            getGlanceTrainingLocation({
                date: currentDate,
                trainingName
            });
        }

        return undefined;
    };

    handleDropdownModalClose = () => {
        const { setSelectedZoneCard } = this.props;
        setSelectedZoneCard(undefined);
    };

    handleTrainingDropdownModalClose = () => {
        const { setSelectedTrainingZoneCard } = this.props;
        setSelectedTrainingZoneCard(undefined);
    };

    handleFilterLinearDashboard = (name) => {
        const { filterLinearDashboard } = this.props;
        filterLinearDashboard({name: name.target.value})
    }

    handleLoadMoreNotes = nextPage => {
        const { getMoreDailyNotesData } = this.props;
        getMoreDailyNotesData(nextPage);
    };

    render() {
        const {
            onDailyNotesSubmit,
            locationOptions,
            classOptions,
            glanceDataAll,
            glanceTrainingDataAll,
            glanceDataLocation,
            glanceTrainingDataLocation,
            glanceClassesProductsAll,
            glanceClassesProducts,
            glanceTrainingsProductsAll,
            glanceTrainingsProducts,
            dailyNotesData,
            selectedZoneCard,
            selectedTrainingZoneCard,
            currentDate,
            glanceView,
            currentNotesDate,
            currentFilter,
            activePage,
        } = this.props;

        return (
            <div className='dashboard__main'>
                <TabSwitcher
                    blockClassName='navbar'
                    className='dashboard-page__navbar'
                    items={this.tabSwitcherItems}
                    activePage={activePage}
                    onNavbarClick={this.handleNavbarClick}/>
                <div className='dashboard-page'>
                    {activePage === 'glance'
                        ? (<GlancePage
                            selectedZoneCard={selectedZoneCard}
                            selectedTrainingZoneCard={selectedTrainingZoneCard}
                            onDateChange={this.handleDateChange}
                            onGlanceViewChange={this.handleGlanceViewChange}
                            onFilterChange={this.handleFilterChange}
                            onZoneCardClick={this.handleZoneCardClick}
                            onTrainingZoneCardClick={this.handleTrainingZoneCardClick}
                            onDropdownModalClose={this.handleDropdownModalClose}
                            onTrainingDropdownModalClose={this.handleTrainingDropdownModalClose}
                            locationOptions={locationOptions}
                            classOptions={classOptions}
                            glanceDataAll={glanceDataAll}
                            glanceTrainingDataAll={glanceTrainingDataAll}
                            glanceDataLocation={glanceDataLocation}
                            glanceTrainingDataLocation={glanceTrainingDataLocation}
                            currentFilter={currentFilter}
                            currentDate={currentDate}
                            glanceView={glanceView}
                            glanceClassesProductsAll={glanceClassesProductsAll}
                            glanceClassesProducts={glanceClassesProducts}
                            glanceTrainingsProductsAll={glanceTrainingsProductsAll}
                            glanceTrainingsProducts={glanceTrainingsProducts}
                            onFilterLinearDashboardChange={this.handleFilterLinearDashboard}/>)
                        : (<DailyNotesPage
                            data={dailyNotesData}
                            onSubmit={onDailyNotesSubmit}
                            onDateChange={this.handleNotesDateChange}
                            currentDate={currentNotesDate}
                            onLoadMore={this.handleLoadMoreNotes}/>)}
                </div>
            </div>
        );
    }
}

export default DashboardPage;
