import * as axios from 'axios';
import { fromJS } from 'immutable';
import { remove } from 'lodash';
import { push } from 'react-router-redux';
import { reset, SubmissionError } from 'redux-form/immutable';
import * as EmployeeActions from '../actions/dashboard/employeeActions';
import { clearDogId, setDogId } from '../actions/onboarding/dogCreationActions';
import { skipOneStepAndFinishLater } from '../actions/onboarding/onBoardingStepsActions';
import { getMonthFromString } from '../helpers/date';
import { getUserNameSpace, getUserRole } from '../helpers/userRoles';
import { getScope } from './oauth';
import { addSubmitData, addStepData } from '../actions/steppedFormActions';
import * as AlertEvents from '../components/Alert/events';
import { generateAlertMeta } from '../components/Alert/actions';

export const postOnboardingDogData = ({ actionCreator, redirectPath }) => {
    return (dispatch, getState) => {
        const request = new FormData();
        request.append('name', getState().getIn(['dog', 'name']));
        request.append('gender', getState().getIn(['dog', 'gender']));
        request.append('weight', getState().getIn(['dog', 'weight']));
        request.append('breed', getState().getIn(['dog', 'breed']));
        console.log(getState().getIn(['dog', 'intolerance_list']));
        const intoleranceList = getState().getIn(['dog', 'intolerance_list']);
        if (intoleranceList.size) {
            const intoleranceListJS = intoleranceList.toJS();
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < intoleranceListJS.length; i++) {
                request.append('intolerance_list', intoleranceListJS[i]);
            }
        }
        request.append('is_altered', getState().getIn(['dog', 'is_altered']));
        request.append('birthday',
            `${getState().getIn(['dog', 'year'])}-${getMonthFromString(getState().getIn(['dog', 'month']))}-01`);
        request.append('medical_notes', getState().getIn(['dog', 'medical_notes']));
        request.append('is_deceased', getState().getIn(['dog', 'is_deceased']));

        request.append('scope', getScope());

        if (getState().getIn(['dog', 'id']) !== null) {
            return axios.patch(`/v1/customers/me/dogs/${getState().getIn(['dog', 'id'])}`, request)
                        .then(response => {
                            console.log('Server response: ', response);
                            console.log(redirectPath);

                            dispatch(setDogId(response.data.id));

                            if (actionCreator) {
                                dispatch(actionCreator());
                            }

                            if (redirectPath) {
                                // Clear the form on the `/introduction` page we
                                // can fill in info for another dog if we want.
                                if (redirectPath === '/introduction') {
                                    dispatch(clearDogId());
                                    dispatch(reset('dogForm'));
                                }
                                // FIT-247: timeout for fake async Stepper displaying and
                                // actual transition to those pages
                                if (redirectPath === '/vet-info') {
                                    setTimeout(() => {
                                        dispatch(push(redirectPath));
                                    }, 1000);
                                } else {
                                    dispatch(push(redirectPath));
                                }
                            } else {
                                // this is for the remote submit case 'Finish later' on Health Page
                                dispatch(skipOneStepAndFinishLater());
                                dispatch(push('/waiver'));
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            console.log(err.response);
                            if (err.response) {
                                if (err.response.status !== 200) {
                                    console.log('Unexpected error code from the API server: ', err.response.status);
                                    if (err.response.data) {
                                        throw new SubmissionError({
                                            _error: 'You should complete Step 1 first.'
                                        });
                                    }
                                }
                            }
                        });
        }
        return axios.post('/v1/customers/me/dogs/', request)
                    .then(response => {
                        console.log('Server response: ', response);
                        console.log(redirectPath);

                        dispatch(setDogId(response.data.id));
                        dispatch(EmployeeActions.appendDogs(response.data));
                        if (actionCreator) {
                            dispatch(actionCreator());
                        }

                        if (redirectPath) {
                            // Clear the form on the `/introduction` page we
                            // can fill in info for another dog if we want.
                            if (redirectPath === '/introduction') {
                                dispatch(clearDogId(response.data.id));
                                dispatch(reset('dogForm'));
                            }
                            // FIT-247: timeout for fake async Stepper displaying and
                            // actual transition to those pages
                            if (redirectPath === '/vet-info') {
                                setTimeout(() => {
                                    dispatch(push(redirectPath));
                                }, 1000);
                            } else {
                                dispatch(push(redirectPath));
                            }
                        } else {
                            // this is for the remote submit case 'Finish later' on Health Page
                            dispatch(skipOneStepAndFinishLater());
                            dispatch(push('/waiver'));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                if (err.response.data) {
                                    throw new SubmissionError({
                                        _error: 'You should complete Step 1 first.'
                                    });
                                }
                            }
                        }
                    });
    };
};

export const sendDashboardDogData = (dogData) => {
    return async (dispatch, getState) => {
        const request = new FormData();
        request.append('owner', dogData.get('owner'));
        request.append('name', dogData.get('name'));
        request.append('birthday',
            `${dogData.get('year').value}-${getMonthFromString(dogData.get('month').label)}-01`);
        request.append('gender', dogData.get('gender').value);
        request.append('weight', dogData.get('weight').value);
        request.append('breed', dogData.get('breed').value);
        if (dogData.get('intolerance_list') !== undefined) {
            const intoleranceList = dogData.get('intolerance_list').map((item) => {
                return item.value;
            });
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < intoleranceList.length; i++) {
                request.append('intolerance_list', intoleranceList[i]);
            }
        }
        // eslint-disable-next-line no-unneeded-ternary
        const isAltered = dogData.get('is_altered') === 'No' ? false : true;
        request.append('is_altered', isAltered);
        request.append('medical_notes', dogData.get('medical_notes'));
        // eslint-disable-next-line no-unneeded-ternary
        request.append('is_deceased', false);

        // FIT-698 - HAS YOUR DOG PASSED A BEHAVIORAL ASSESSMENT? - should be 'No' by default
        request.append('survey', JSON.stringify({ assessment: false }));

        request.append('scope', getScope());

        const result = await axios.post(`/v1/${getUserNameSpace('dogs')}/dogs/`, request);
        dispatch(generateAlertMeta({
            event: 'DOG_CREATE',
            responseData: result.data
        }));
        return result;
    };
};

export const getDogs = (filter = '', filterContent = '') => {
    return (dispatch) => {
        dispatch(EmployeeActions.startLoadingDogs());
        const nameSpace = getUserRole() === 'customer' ? 'customers/me' : getUserNameSpace('dogs');
        return axios.get(`/v1/${nameSpace}/dogs/${filter}${filterContent}`)
                    .then((response) => {
                        dispatch(EmployeeActions.setDogs(response.data));
                        dispatch(EmployeeActions.finishLoadingDogs());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};

export const getNextDogs = () => {
    return (dispatch, getState) => {
        dispatch(EmployeeActions.startLoadingDogs());
        const nextUrl = getState().getIn(['dogsList', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(EmployeeActions.setNextDogs(response.data));
                        dispatch(EmployeeActions.finishLoadingDogs());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};

export const getDogById = (dogId) => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace('dogs')}/dogs/${dogId}/`)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(EmployeeActions.clearCurrentDogData());
                        dispatch(EmployeeActions.setCurrentDog(fromJS(response.data)));
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};

export const getCustomersDogById = (dogId) => {
    return (dispatch) => {
        dispatch(EmployeeActions.startLoadingCurrentDog());
        return axios.get(`/v1/customers/me/dogs/${dogId}/`)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(EmployeeActions.customerSetCurrentDog(fromJS(response.data)));
                        dispatch(EmployeeActions.finishLoadingCurrentDog());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};

export const updateCastomerVeterinarian = (data) => {
    return dispatch => {
        const url = '/v1/customers/me/';
        return axios.patch(url, data)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};

export const removeBookingDog = (dogId) => {
    return async (dispatch, getState) => {
        const selectedDogs = getState().getIn(['steppedForm', 'booking', 'submitData', 'dogs']) || '';
        const dogsList = selectedDogs.split(',');
        remove(dogsList, selectedDogId => selectedDogId === dogId);
        dispatch(addSubmitData('booking', { name: 'dogs', data: dogsList.join(',') }));
    };
};

export const loadBookingAvailableDogs = (classId) => {
    return dispatch => {
        return axios.get(`/v1/customers/me/class-occurrences/${classId}/dogs/`)
            .then(({ data: { results } }) => {
                const availableDogs = [];
                results.map(dog => { // eslint-disable-line
                    if (dog.is_available) {
                        availableDogs.push(dog.id);
                    }
                });
                return availableDogs;
            });
    };
};

export const updateDogVeterinarian = ({ id, veterinarian, can_contact_veterinarian }) => {
    return () => {
        return axios.patch(`/v1/customers/me/dogs/${id}/`, {
            veterinarian,
            can_contact_veterinarian
        });
    };
};
