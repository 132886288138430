import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import renderSelectControl from '../SelectControl';
import * as openActions from '../../../../actions/modal/openActions';
import * as miscActions from '../../../../actions/miscActions';
import { renderError, renderWarning } from '../RadioControl/RadioControl';

class VeterinarianControl extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number,
        selectedOption: PropTypes.string,
        isOpened: PropTypes.bool,
        openCreateVeterinarianModal: PropTypes.func,
        getVeterinarianOptions: PropTypes.func,
        veterinarianOptions: PropTypes.shape({
            size: PropTypes.number,
        }),
        additional: PropTypes.shape({}),
        validate: PropTypes.func,
        changeFieldValue: PropTypes.func,
    };

    state = {
        selectedOption: this.props.value
    }

    componentDidMount() {
        const { veterinarianOptions, getVeterinarianOptions } = this.props;
        getVeterinarianOptions();
    }

    componentWillUpdate() {
        const { isOpened } = this.props;
        const { selectedOption } = this.state;
        if (selectedOption && !isOpened) {
            this.resetSelectedOption();
        }
    }

    componentDidUpdate(prevProps) {
        // if user added new vet, it selected automatically
        if (prevProps.isOpened !== this.props.isOpened && this.props.isOpened) {
            this.setState({
                selectedOption: this.props.value
            });
        }
        const { veterinarianOptions, name, changeFieldValue } = this.props;
        if (prevProps.veterinarianOptions.size &&
            veterinarianOptions.size > prevProps.veterinarianOptions.size) {
            changeFieldValue(name, veterinarianOptions.toJS()[0]);
            this.setState({
                selectedOption: veterinarianOptions.toJS()[0].value
            });
        }
    }

    resetSelectedOption() {
        this.setState({
            selectedOption: null
        });
    }

    renderRadioControl() {
        const { additional } = this.props;

        return (
            <div className='radio-control'>
                <div className='radio-control__description'>
                    <label className='radio-control__description-label'>
                        {additional.label}
                    </label>
                    <Field name='is_altered' component={renderError}/>
                    <Field name='is_altered' component={renderWarning}/>
                </div>
                <div className='radio-control__controls'>
                    {additional.options.map((option, i) => {
                        return (
                            <label
                                className='radio-control__controls-label'
                                key={`${additional.name}_${i}`}>
                                <Field
                                    name={additional.name}
                                    component='input'
                                    type='radio'
                                    value={option.value}
                                    warn={additional.warn ? additional.warn : undefined}
                                    validate={additional.validate}
                                    className='radio-control__controls-input'/>
                                <span className='radio-control__controls-text'>{option.label}</span>
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { openCreateVeterinarianModal, name, label, veterinarianOptions, validate } = this.props;
        const { selectedOption } = this.state;

        return (
            <div className='form__select-aligner'>
                <div className='form__help'>
                    <a
                        className='form__help-link'
                        onClick={openCreateVeterinarianModal}>
                        {'My vet isn\'t listed'}
                    </a>
                </div>
                <Field
                    name={name}
                    label={label}
                    type='text'
                    onChange={(e) => {
                        this.setState({
                            selectedOption: e.value
                        });
                    }}
                    options={veterinarianOptions.toJS()}
                    selectedValue={selectedOption}
                    validate={validate}
                    component={renderSelectControl}
                    searchable/>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        veterinarianOptions: state.getIn(['misc', 'options', 'veterinarian']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    openCreateVeterinarianModal: bindActionCreators(openActions.openCreateVeterinarianModal, dispatch),
    getVeterinarianOptions: bindActionCreators(miscActions.getVeterinarianOptions, dispatch),
    changeFieldValue: (field, value) => {
        dispatch(change('EditCreateModalSidebarDialogForm', field, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VeterinarianControl);
