import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import FilterForm from '../../../../common/FiterForm';
import DateSwitcher from '../DateSwitcher';
import DropdownModal from '../DropdownModal';
import Loader from '../../../../common/Loader/Loader';
import FormContainer from '../../../FormContainer'
import LinearDashboardTable from '../LinearDashboardTable'
import LinearDashboardGrid from '../LinearDashboardGrid'
import { ReactComponent as IconGrid } from '../../../../../assets/icons/icon-grid-view.svg';
import { ReactComponent as IconList } from '../../../../../assets/icons/icon-list-view.svg';
import Icon from '../../../../common/Icon';

const LOCATION_ALL = 1000;

const GlancePage = ({
    onDateChange,
    onGlanceViewChange,
    onFilterChange,
    onZoneCardClick,
    onTrainingZoneCardClick,
    onDropdownModalClose,
    onTrainingDropdownModalClose,
    currentDate,
    glanceView,
    currentFilter,
    selectedZoneCard,
    selectedTrainingZoneCard,
    locationOptions,
    classOptions,
    glanceDataAll,
    glanceTrainingDataAll,
    glanceDataLocation,
    glanceTrainingDataLocation,
    glanceClassesProductsAll,
    glanceClassesProducts,
    glanceTrainingsProductsAll,
    glanceTrainingsProducts,
    onFilterLinearDashboardChange
    }) => {
    const zoneCardsPerRow = 4;

    const filters = [{
        array: true,
        name: 'Classes',
        options: classOptions.toJS ? classOptions.toJS() : [],
        query: 'training_class',
        type: 'multibox'
    }];

    const addEmptyItems = (groupedOptions) => {
        const lastRowIndex = groupedOptions.length - 1;
        const lastRowLength = groupedOptions[lastRowIndex].length;

        if (lastRowLength < zoneCardsPerRow) {
            const extraItems = new Array(zoneCardsPerRow - lastRowLength);
            extraItems.fill({ empty: true });
            groupedOptions[lastRowIndex].push(...extraItems);
        }

        return groupedOptions;
    };

    const shouldRenderDropdown = (selectedZoneCardJS, currentRowIndex) => {
        if (selectedZoneCard === undefined) {
            return false;
        }

        return currentRowIndex === (selectedZoneCardJS.get('rowIndex') < 2 ? 0 : selectedZoneCardJS.get('rowIndex') - 1);
    };

    const shouldRenderTrainingDropdown = (selectedTrainingZoneCardJS, currentRowIndex) => {
        if (selectedTrainingZoneCard === undefined) {
            return false;
        }

        return currentRowIndex === (selectedTrainingZoneCardJS.get('rowIndex') < 2 ? 0 : selectedTrainingZoneCardJS.get('rowIndex') - 1);
    };

    const renderZoneRows = () => {
        const classes_rows = [];
        const trainings_rows = [];
        let numOfRows = 0;
        let trainingsNumOfRows = 0;
        if (glanceDataAll.get('items').size !== 0) {
            const glanceDataItems = glanceDataAll.get('items').toJS();
            const groupedOptions = addEmptyItems(glanceDataItems.map((item, i) => {
                return i % zoneCardsPerRow === 0 && glanceDataItems.slice(i, i + zoneCardsPerRow);
            }).filter(item => item));
            numOfRows = groupedOptions.length;

            groupedOptions.forEach((row, rowIndex) => {
                const rowClassNames = classNames({
                    'zone-row': true,
                    'zone-row_last': rowIndex + 1 === numOfRows
                });
                classes_rows.push(
                    <div className='zone-row-container' key={'zone-row-' + rowIndex}>
                        <div className={rowClassNames}>
                            {row.map((glanceDataItem, cardIndex) => {
                                const isCardSelected =
                                    selectedZoneCard &&
                                    selectedZoneCard.get('rowIndex') === rowIndex &&
                                    selectedZoneCard.get('cardIndex') === cardIndex;
                                const cardClassNames = classNames({
                                    'zone-card': true,
                                    'zone-card_selected': isCardSelected,
                                    'hidden': !!glanceDataItem.empty
                                });

                                const attendants = glanceDataItem.coming;

                                return (
                                    <div
                                        className={cardClassNames}
                                        key={'zone-card-' + cardIndex}
                                        onClick={() => {
                                            onZoneCardClick({
                                                rowIndex,
                                                cardIndex,
                                                attendants,
                                                trainingClassName: glanceDataItem.name
                                            });
                                        }}>
                                        <div className='zone-card__zone-number'>{glanceDataItem.coming}</div>
                                        <div className='zone-card__zone-name'>{glanceDataItem.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

                if (shouldRenderDropdown(selectedZoneCard, rowIndex)) {
                    classes_rows.push(<DropdownModal
                        key={'dropdown-modal'}
                        selectedZoneCard={selectedZoneCard}
                        glanceDataLocation={glanceDataLocation}
                        type="class"
                        onClose={onDropdownModalClose}/>);
                }
            });
        }

        if (glanceTrainingDataAll.get('items').size !== 0) {
            const glanceTrainingDataItems = glanceTrainingDataAll.get('items').toJS();
            const trainingsGroupedOptions = addEmptyItems(glanceTrainingDataItems.map((item, i) => {
                return i % zoneCardsPerRow === 0 && glanceTrainingDataItems.slice(i, i + zoneCardsPerRow);
            }).filter(item => item));
            trainingsNumOfRows = trainingsGroupedOptions.length;

            trainingsGroupedOptions.forEach((row, rowIndex) => {
                const rowClassNames = classNames({
                    'zone-row': true,
                    'zone-row_last': rowIndex + 1 === trainingsNumOfRows
                });
                trainings_rows.push(
                    <div className='zone-row-container' key={'zone-row-' + rowIndex}>
                        <div className={rowClassNames}>
                            {row.map((glanceDataItem, cardIndex) => {
                                const isCardSelected =
                                    selectedTrainingZoneCard &&
                                    selectedTrainingZoneCard.get('rowIndex') === rowIndex &&
                                    selectedTrainingZoneCard.get('cardIndex') === cardIndex;
                                const cardClassNames = classNames({
                                    'zone-card': true,
                                    'zone-card_selected': isCardSelected,
                                    'hidden': !!glanceDataItem.empty
                                });

                                const attendants = glanceDataItem.coming;
                                return (
                                    <div
                                        className={cardClassNames}
                                        key={'zone-card-' + cardIndex}
                                        onClick={() => {
                                            onTrainingZoneCardClick({
                                                rowIndex,
                                                cardIndex,
                                                attendants,
                                                trainingName: glanceDataItem.name
                                            });
                                        }}>
                                        <div className='zone-card__zone-number'>{glanceDataItem.coming}</div>
                                        <div className='zone-card__zone-name'>{glanceDataItem.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

                if (shouldRenderTrainingDropdown(selectedTrainingZoneCard, rowIndex)) {
                    trainings_rows.push(<DropdownModal
                        key={'dropdown-modal'}
                        selectedZoneCard={selectedTrainingZoneCard}
                        glanceDataLocation={glanceTrainingDataLocation}
                        type="training"
                        onClose={onTrainingDropdownModalClose}/>);
                }
            });
        }

        return (
            <div>
                <div className='attendance__zone-cards'>
                    <CSSTransitionGroup
                        transitionName='fade'
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                        key={'dropdown-modal-animation'}>
                        {classes_rows}
                    </CSSTransitionGroup>
                </div>
                <div className='attendance__zone-cards'>
                    <CSSTransitionGroup
                        transitionName='fade'
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                        key={'dropdown-modal-animation'}>
                        {trainings_rows}
                    </CSSTransitionGroup>
                </div>
            </div>
        );
    };

    const renderAttendanceContent = () => {
        if (glanceDataAll.get('isLoading') || glanceTrainingDataAll.get('isLoading')) {
            return (
                <div className='attendance__empty-content'>
                    <Loader
                        isVisible
                        loadingText='Loading Classes and Trainings Data...'
                        colorClassName='icon_loader-black'
                        modifierClassName='calendar__loader'/>
                </div>
            );
        }

        if (!classOptions.size || !locationOptions.size || (glanceDataAll.get('items') === undefined && glanceTrainingDataAll.get('items') === undefined)) {
            return (
                <div className='attendance__empty-content'>
                    No Data
                </div>
            );
        }

        if (glanceDataAll.get('items').size === 0 && glanceTrainingDataAll.get('items').size === 0) {
            return (
                <div className='attendance__empty-content'>
                    No classes and trainings scheduled today
                </div>
            );
        }

        return renderZoneRows();
    };

    const renderLinearDashboardTable = () => {
        const classesProducts = glanceClassesProducts.get('items').toJS();
        const trainingsProducts = glanceTrainingsProducts.get('items').toJS();
        const data = [...classesProducts, ...trainingsProducts]
        const defaultPageSize = classesProducts.size + trainingsProducts.size
        return (
            <div>
                <input
                    type="text"
                    class="linear-dashboard-search"
                    placeholder="Search by Dog, Owner, Class/Training Name"
                    onChange={onFilterLinearDashboardChange}
                />
                <LinearDashboardGrid
                    items={data}/>
                <LinearDashboardTable
                    data={data}
                    defaultPageSize={defaultPageSize}/>
            </div>
        )
    }

    const renderLinearDashboardContent = () => {
        if (glanceClassesProductsAll.get('isLoading') || glanceTrainingsProductsAll.get('isLoading')) {
            return (
                <div className='attendance__empty-content'>
                    <Loader
                        isVisible
                        loadingText='Loading Classes and Trainings Data...'
                        colorClassName='icon_loader-black'
                        modifierClassName='calendar__loader'/>
                </div>
            );
        }

        if (glanceClassesProductsAll.get('items') === undefined && glanceTrainingsProductsAll.get('items') === undefined) {
            return (
                <div className='attendance__empty-content'>
                    No Data
                </div>
            );
        }

        if (glanceClassesProductsAll.get('items').size === 0 && glanceTrainingsProductsAll.get('items').size === 0) {
            return (
                <div className='attendance__empty-content'>
                    No dogs signed up for today
                </div>
            );
        }

        return renderLinearDashboardTable()
    }

    const renderViewToggle = () => {
        const current =
            glanceView === 'grid'
                ? (<Icon glyph={IconList} className='icon_list-view dashboard__view-icon'/>)
                : (<Icon glyph={IconGrid} className='icon_grid-view dashboard__view-icon'/>);
        return (
            <a href='' className="glanceView" onClick={onGlanceViewChange}>
                {current}
            </a>
        );
    };
    
    return (
        <div className='glance-page'>
            <div className='attendance'>
                <div className='attendance__content'>
                    <div className='attendance__header'>
                        Today&#39;s scheduled attendance: {glanceDataAll.get('isLoading') || glanceTrainingDataAll.get('isLoading') ? '...' : glanceDataAll.get('total') + glanceTrainingDataAll.get('total')}
                    </div>
                    {renderViewToggle()}
                    <DateSwitcher
                        currentDate={currentDate}
                        onDateChange={onDateChange}/>
                    {glanceView === 'list' ? renderLinearDashboardContent() : renderAttendanceContent()}
                </div>

            </div>
        </div>
    );
};

GlancePage.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    onGlanceViewChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onZoneCardClick: PropTypes.func.isRequired,
    onTrainingZoneCardClick: PropTypes.func.isRequired,
    onDropdownModalClose: PropTypes.func.isRequired,
    onTrainingDropdownModalClose: PropTypes.func.isRequired,
    onFilterLinearDashboardChange: PropTypes.func.isRequired,
    currentDate: PropTypes.string.isRequired,
    glanceView: PropTypes.string.isRequired,
    currentFilter: PropTypes.shape({}),
    selectedZoneCard: PropTypes.shape({}),
    selectedTrainingZoneCard: PropTypes.shape({}),
    locationOptions: PropTypes.shape({}).isRequired,
    classOptions: PropTypes.shape({}).isRequired,
    glanceDataAll: PropTypes.shape({}).isRequired,
    glanceTrainingDataAll: PropTypes.shape({}).isRequired,
    glanceDataLocation: PropTypes.shape({}).isRequired,
    glanceTrainingDataLocation: PropTypes.shape({}).isRequired,
    glanceClassesProductsAll: PropTypes.shape({}).isRequired,
    glanceClassesProducts: PropTypes.shape({}).isRequired,
    glanceTrainingsProductsAll: PropTypes.shape({}).isRequired,
    glanceTrainingsProducts: PropTypes.shape({}).isRequired,

};

export default GlancePage;
