import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

export default class Section extends PureComponent {
    toggle = () => {
        this.props.handleClick(this.props.index);
    };

    render() {
        const { selected, header, content, children } = this.props;
        return (
            <div
                className={`accordion__section ${selected
                    ? 'accordion__section_selected'
                    : ''}`} >
                <div className='accordion__toggle' onClick={this.toggle}>
                    {header}
                </div>

                {selected && (
                    <div className='accordion__content'>
                        <div className='accordion__inner'>
                            {Children.map(content, (child) =>
                                cloneElement(child, { handleToggle: this.toggle })
                            )}
                            {children}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Section.propTypes = {
    handleClick: PropTypes.func,
    content: PropTypes.node,
    children: PropTypes.node,
    index: PropTypes.number,
    selected: PropTypes.bool,
    header: PropTypes.node,
};
