import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Summary from '../../../common/Summary';
import PackageSummary from '../../../common/PackageSummaryItem';
import { getPackageData } from '../../../../services/packages';
import { getCustomersPaymentCards } from '../../../../services/paymentCards';
import { openPurchasePackageCancelModal } from '../../../../actions/modal/openActions';
import SteppedForm from '../../../common/SteppedForm';
import AddCreditCardStepForm from '../StepForms/AddCreditCardStepForm';
import PaymentChangeStep from '../StepForms/PaymentChangeStep';
import PackagePurchaseConfirmationStep from '../StepForms/PackagePurchaseConfirmationStep';
import AjaxLoader from '../../../common/AjaxLoader';

class PurchasePackage extends PureComponent {
    state = {
        isLoading: true,
        currentPackage: null
    };

    componentWillMount() {
        const { packagesList, router: { params: { id } }, paymentCards,
                areCardsLoaded, getCustomersPaymentCards } = this.props;
        const actions = [];
        if (packagesList.length && id) {
            // get current package data if packages has been loaded
            let currentPackage = null;
            packagesList.map(item => { // eslint-disable-line
                if (item.id === +id) {
                    currentPackage = item;
                }
            });
            if (currentPackage) {
                this.setState({ currentPackage });
            }
        } else {
            actions.push(getPackageData(id));
        }
        if (!paymentCards.length && !areCardsLoaded) {
            actions.push(getCustomersPaymentCards());
        }

        if (actions.length) {
            Promise.all(actions).then((response) => {
                if (response && response.length && response.length > 1) {
                    const loadedPackage = response[0].data;
                    this.setState({
                        currentPackage: loadedPackage,
                        isLoading: false
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            });
        } else {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const summaryItems = this.state.currentPackage ? [this.state.currentPackage] : [];
        const { openPurchasePackageCancelModal, paymentCards, areCardsLoaded } = this.props;
        const steps = [
            {
                name: 'add-card',
                form: AddCreditCardStepForm,
                title: 'Purchasing a package',
            }, {
                name: 'purchase-confirmation',
                form: PackagePurchaseConfirmationStep,
                title: 'Purchase summary',
                submitText: 'Finish and pay',
                currentPackage: this.state.currentPackage,
            }, {
                name: 'payment-select',
                form: PaymentChangeStep,
                target: 'purchasePackage',
                nextStep: 1,
                title: 'Payment Methods',
                submitText: 'Confirm payment method',
            }
        ];
        const initialData = {
            cards: paymentCards
        };
        return (
            <section className='inner-page'>
                {this.state.isLoading
                    ? <AjaxLoader />
                    : (<div className='inner-page__container purchase-package__container'>
                        <div className='purchase-package__block purchase-package__block_main'>
                            <SteppedForm
                                name='purchasePackage'
                                onCancelHandler={openPurchasePackageCancelModal}
                                initialData={initialData}
                                items={steps} />
                        </div>
                        <div className='purchase-package__block purchase-package__block_summary'>
                            <Summary
                                title='Purchase Summary'
                                items={summaryItems}
                                onRemoveHandler={openPurchasePackageCancelModal}
                                itemRenderer={PackageSummary} />
                        </div>
                    </div>)
                }
            </section>
        );
    }
}
PurchasePackage.propTypes = {
    packagesList: PropTypes.arrayOf(PropTypes.shape({})),
    paymentCards: PropTypes.arrayOf(PropTypes.shape({})),
    areCardsLoaded: PropTypes.bool,
    router: PropTypes.shape({}),
    openPurchasePackageCancelModal: PropTypes.func,
    getCustomersPaymentCards: PropTypes.func
};

const mapStateToProps = state => ({
    packagesList: state.getIn(['packageList', 'items']).toJS(),
    isLoading: state.getIn(['packageList', 'isLoading']),
    paymentCards: state.getIn(['currentCustomer', 'payment_cards', 'cards']).toJS(),
    areCardsLoaded: state.getIn(['currentCustomer', 'payment_cards', 'areLoaded'])
});

const mapDispatchToProps = dispatch => ({
    openPurchasePackageCancelModal: bindActionCreators(openPurchasePackageCancelModal, dispatch),
    getCustomersPaymentCards: bindActionCreators(getCustomersPaymentCards, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePackage);
