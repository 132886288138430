/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import TemplateSelector from '../TemplateSelector';
import TemplateEditor from '../TemplateEditor';
import Form from '../Form';
import { FORM_NAME } from './form';

export const steps = {
    1: 'Step 1 - Enter Campaign Info',
    2: 'Step 2 - Choose Template',
    3: 'Step 3 - Enter Text and Images',
    4: 'Step 4 - Confirm'
};

const fieldsStep1 = ['target_array', 'name', 'subject'];
const blockFields = {
    1: ['photo', 'title', 'body', 'link', 'button_text'],
    2: ['photo', 'title', 'body', 'link', 'button_text'],
    3: ['photo', 'title', 'subtitle', 'title2', 'body'],
    4: ['photo1', 'photo2', 'photo3', 'photo4']
};

// Step 1 is complete if all its fields have values and no warnings.
const isCompleteStep1 = ({ form }) => {
    const { values, warnings } = form;

    if (!values) {
        return false;
    }

    if (!warnings) {
        return true;
    }

    return !fieldsStep1.some((field) => {
        return field in warnings;
    });
};
// Step 2 is complete if a template preview is selected.
const isCompleteStep2 = ({ model }) => {
    return model.get('templates').some(template => template.get('isActive'));
};
// Step 3 is complete if no warnings at all.
const isCompleteStep3 = ({ form }) => {
    const { values, warnings } = form;

    if (!values || !values.blocks) {
        return false;
    }

    const { blocks } = values;
    if (!blocks || !blocks.length) {
        return false;
    }

    return !blocks.some((block, i) => {
        if (!block) {
            return false;
        }

        const { block_id } = block;
        return blockFields[block_id].some((field) => {
            if (!(field in block)) {
                return true;
            }

            return (warnings && warnings.blocks && warnings.blocks[i] && field in warnings.blocks[i]);
        });
    });
};
// Always returns false because it's the last step.
const isCompleteStep4 = () => false;

const stepCompletionValidators = [
    isCompleteStep1,
    isCompleteStep2,
    isCompleteStep3,
    isCompleteStep4
];

export const lastStepSelector = ({ forms, model }) => {
    let lastStep = 0;
    stepCompletionValidators.some((validator, index) => {
        const isFormValid = validator({ form: forms[FORM_NAME], model });
        lastStep = index + 1;
        return !isFormValid;
    });
    return lastStep;
};

const stepComponents = [
    Form,
    TemplateSelector,
    TemplateEditor,
    TemplateEditor
];

export default stepComponents;
