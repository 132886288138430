import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as IconCancel } from '../../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import AvatarPlaceholder from '../../../../../common/AvatarPlaceholder';
import AttendedIcon from '../../../../../common/AttendedIcon';
import Icon from '../../../../../common/Icon/Icon';
import { prettifyPhone } from '../../../../../../helpers/normalize';
import { timePrettify, formatTrainingDateTimeToAMPM } from '../../../../../../helpers/date';
import {
    createEventAttendingDogsDropdownItems,
    shouldDisplayEventProductDropdown
} from '../../../../../../helpers/linearDashboardDropdownItems';
import Dropdown from '../../../../../common/Dropdown';
import * as ModalOpenActions from '../../../../../../actions/modal/openActions';
import * as classesActions from '../../../../../../actions/dashboard/classesActions';
import * as EventActions from '../../../../../../actions/dashboard/eventActions';
import * as dashboardPageActions from '../../../../../../actions/dashboard/dashboardPageActions';

class LinearDashboardCard extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        handleEditingUpcomingClass: PropTypes.func,
        handleEditingTraining: PropTypes.func,
        handleEditingUpcomingClassStatus: PropTypes.func,
        handleEditingPreviousClassStatus: PropTypes.func,
        handleOpeningCancelUpcomingClassModal: PropTypes.func,
        handleCancellingUpcomingClass: PropTypes.func,
        cancelTrainingProduct: PropTypes.func,
        handleSetDogAttendedStatus: PropTypes.func,
        handleSetProductCancelStatus: PropTypes.func,
        handleSetProductNoShowStatus: PropTypes.func,
        handleOpeningCreateDogReportModalDialog: PropTypes.func,
        handleOpeningEditDogReportModalDialog: PropTypes.func,
        handleSettingClassEmptyReports: PropTypes.func,
    };

    state = {
        expanded: true
    };

    formatAddress = data => {
        return `${data.pickup_location_detail.name} / ${data.drop_off_location_detail.name}`;
    }

    contact = data => {
        return <div><div style={{ marginBottom: "10px" }}>{prettifyPhone(data.owner_detail.primary_phone.phone_number)}</div><div><a href={'mailto:' + data.owner_detail.email}>{data.owner_detail.email}</a></div></div>
    }

    time = data => {
        return <div>{data.start ? timePrettify(formatTrainingDateTimeToAMPM(data.start), 'h:mmA') : timePrettify(data.pickup_end_time, 'h:mmA')}&nbsp;&mdash;&nbsp;{data.end ? timePrettify(formatTrainingDateTimeToAMPM(data.end), 'h:mmA') : timePrettify(data.dropoff_start_time, 'h:mmA')}</div>;
    }

    program = data => {
        const type = data.class_occurrence ? 'classes' : 'trainings'
        const event = data.class_occurrence ? data.class_occurrence_detail : data.event
        return <Link to={`/dashboard/calendar/${type}/${event.id}`}>{event.program_name}</Link>
    }

    renderNotes = notes => {
        return <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Notes</div>
                    <div className='attending-dogs-card__item-content'>{notes.length ? notes : <div className='no-info'>—</div>}</div>
               </div>
    }

    handleExpandButtonClick = e => {
        e.preventDefault();
        this.setState({
            expanded: !this.state.expanded
        });
    };

    renderReportCardItem = () => {
        const { data } = this.props;

        if (data.class_occurrence) {
            return (
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Snapshot</div>
                    <div className='attending-dogs-card__item-content'>
                        {
                            data.reports.length ? <Icon glyph={IconCheckMark} className='icon_check-mark'/> :
                            <Icon glyph={IconCancel} className='icon_cancel'/>
                        }
                    </div>
                </div>
            );
        }

        return null;
    };

    renderContent = () => {
        if (!this.state.expanded) {
            return null;
        }

        const { data } = this.props;

        return (
            <div className='attending-dogs-card__content'>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Class/Program</div>
                    <div className='attending-dogs-card__item-content'>{this.program(data)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Time</div>
                    <div className='attending-dogs-card__item-content'>{this.time(data.class_occurrence ? data.class_occurrence_detail : data.event)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>P/U D/O</div>
                    <div className='attending-dogs-card__item-content'>{this.formatAddress(data)}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Contact</div>
                    <div className='attending-dogs-card__item-content'>{this.contact(data)}</div>
                </div>
                {this.renderNotes(data.class_occurrence ? data.class_occurrence_detail.notes : data.event.notes)}
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Attended or Cancelled</div>
                    <div className='attending-dogs-card__item-content'>
                        <AttendedIcon status={data.status}/>
                    </div>
                </div>
                {this.renderReportCardItem()}
            </div>
        );
    }

    renderExpandButton = () => {
        const text = this.state.expanded ? 'See Less' : 'See More';

        return (
            <div className='attending-dogs-card__expand-button-wrapper'>
                <a
                    href=''
                    onClick={this.handleExpandButtonClick}
                    className='attending-dogs-card__expand-button'>
                    {text}
                </a>
            </div>
        );
    }

    renderDropdown = () => {
        const {
            handleEditingUpcomingClass, handleEditingTraining, handleEditingUpcomingClassStatus, handleEditingPreviousClassStatus,
            handleCancellingUpcomingClass, handleOpeningCancelUpcomingClassModal, cancelTrainingProduct, handleSetDogAttendedStatus,
            handleSetProductCancelStatus, handleSetProductNoShowStatus, handleOpeningCreateDogReportModalDialog, handleOpeningEditDogReportModalDialog,
            handleSettingClassEmptyReports, data

        } = this.props;
        const type = data.class_occurrence ? 'class' : 'training'
        const eventData = type === 'class' ? data.class_occurrence_detail : data.event
        const attendingDogsActions = {
            'edit': type === 'class' ? handleEditingUpcomingClass : handleEditingTraining,
            'attended': type === 'class' ? handleEditingUpcomingClassStatus : handleSetDogAttendedStatus,
            'attendedChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetDogAttendedStatus,
            'cancel': type === 'class' ? handleCancellingUpcomingClass : cancelTrainingProduct,
            'cancelPast': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductCancelStatus,
            'cancelPenaltyChangeTo': type === 'class' ? handleEditingPreviousClassStatus : null,
            'cancelWaiveChangeTo': type === 'class'? handleEditingPreviousClassStatus : null,
            'cancelLate': type == 'class' ? handleOpeningCancelUpcomingClassModal : null,
            'noShow': type === 'class' ? handleEditingUpcomingClassStatus : handleSetProductNoShowStatus,
            'noShowChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductNoShowStatus,
            'reportCreate': handleOpeningCreateDogReportModalDialog,
            'reportEdit': handleOpeningEditDogReportModalDialog,
            'reportDelete': handleSettingClassEmptyReports,
        };

        if (!shouldDisplayEventProductDropdown(data)) {
            return null;
        }

        return (
            <Dropdown
                data={data}
                classNames='dropdown_attending-dogs-card'
                icon={IconDots}
                iconClass='icon_chevron_down'
                items={createEventAttendingDogsDropdownItems({
                    eventData, rowData: data, actions: attendingDogsActions, context: 'dogs', type: type
                })}/>
        );
    };

    render() {
        const { data } = this.props;
        return (
            <div className='attending-dogs-card-container'>
                <div className='attending-dogs-card'>

                    {this.renderDropdown()}

                    <div className='attending-dogs-card__header'>
                        <div className='attending-dogs-card__img-container'>
                            {data.dog_photo_processed !== undefined
                                ? (<img
                                    className='attending-dogs-card__img'
                                    src={data.dog_photo_processed}
                                    alt={`Dog ${data.dog_detail.name}`}/>)
                                : (<AvatarPlaceholder type='dog' size={70}/>)
                            }
                        </div>
                        <div className='attending-dogs-card__header-title'><Link to={`/dashboard/dog/${data.dog}/details`}>{data.dog_detail.name}</Link></div>
                        <div className='attending-dogs-card__header-text'>
                            <Link to={`/dashboard/customer/${data.owner_detail.id}/details`}>
                                {data.owner_detail.full_name}
                            </Link>
                        </div>
                    </div>

                    {this.renderContent()}
                    {this.renderExpandButton()}

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    handleEditingUpcomingClass: bindActionCreators(ModalOpenActions.openEditUpcomingClassModalDialog, dispatch),
    handleEditingTraining: bindActionCreators(ModalOpenActions.openEditTrainingModalDialog, dispatch),
    handleEditingUpcomingClassStatus: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleEditingPreviousClassStatus: bindActionCreators(classesActions.markDogsPreviousClass, dispatch),
    handleOpeningCancelUpcomingClassModal: bindActionCreators(ModalOpenActions.openCancelDogsUpcomingClassModalDialog, dispatch),
    handleCancellingUpcomingClass: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    cancelTrainingProduct: bindActionCreators(EventActions.cancelTrainingProduct, dispatch),
    handleSetDogAttendedStatus: bindActionCreators(EventActions.setProductAttendedStatus, dispatch),
    handleSetProductCancelStatus: bindActionCreators(EventActions.setProductCancelStatus, dispatch),
    handleSetProductNoShowStatus: bindActionCreators(EventActions.setProductNoShowStatus, dispatch),
    handleOpeningCreateDogReportModalDialog: bindActionCreators(ModalOpenActions.openCreateDogReportModalDialog, dispatch),
    handleOpeningEditDogReportModalDialog: bindActionCreators(ModalOpenActions.openEditDogReportModalDialog, dispatch),
    handleSettingClassEmptyReports: bindActionCreators(dashboardPageActions.setClassEmptyReports, dispatch),
});

export default connect(null, mapDispatchToProps)(LinearDashboardCard);
