import moment from 'moment';
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { getOnlyUniqueItems } from '../../helpers/array';
import { addZero, getMonthIntervalFromDate, getWeekIntervalFromDate } from '../../helpers/date';
import * as CalendarTypes from '../../types/dashboard/calendarTypes';
import { clearCurrentEventProducts } from './eventActions';

export const changeCalendarType = createAction(CalendarTypes.CHANGE_CALENDAR_TYPE);
export const setCalendarView = createAction(CalendarTypes.SET_CALENDAR_VIEW);
export const setDaypickerShown = createAction(CalendarTypes.SET_DAYPICKER_SHOWN);
export const setDaypickerHidden = createAction(CalendarTypes.SET_DAYPICKER_HIDDEN);
export const goToEventPage = ({ context, id }) => {
    return dispatch => {
        if (id === 'new') {
            dispatch(clearCurrentEventProducts());
        }
        dispatch(push(`/dashboard/calendar/${context}/${id}`));
    };
};
export const setTrainingInstructorQuery = createAction(CalendarTypes.SET_TRAINING_INSTRUCTOR_QUERY);
export const clearTrainingInstructorQuery = createAction(CalendarTypes.CLEAR_TRAINING_INSTRUCTOR_QUERY);

export const setTrainingProgramQuery = createAction(CalendarTypes.SET_TRAINING_PROGRAM_QUERY);
export const clearTrainingProgramQuery = createAction(CalendarTypes.CLEAR_TRAINING_PROGRAM_QUERY);

export const setClassOccurrencesLocationQuery = createAction(CalendarTypes.SET_CLASS_OCCURRENCES_LOCATION_QUERY);
export const clearClassOccurrencesLocationQuery = createAction(CalendarTypes.CLEAR_CLASS_OCCURRENCES_LOCATION_QUERY);

export const setClassOccurrencesTrainingQuery = createAction(CalendarTypes.SET_CLASS_OCCURRENCES_TRAINING_QUERY);
export const clearClassOccurrencesTrainingQuery = createAction(CalendarTypes.CLEAR_CLASS_OCCURRENCES_TRAINING_QUERY);

export const setTrainersColorsList = createAction(CalendarTypes.SET_TRAINERS_COLORS_LIST);
export const getTrainersColorsList = () => {
    return (dispatch, getState) => {
        const classOccurrencesTrainersList = getState()
            .getIn(['classOccurrencesList', 'classOccurrencesTrainersList'])
            .toJS();
        const trainingsTrainersList = getState().getIn(['trainingsList', 'trainingsTrainersList']).toJS();
        const calendarColors = getState().getIn(['calendar', 'calendarEventsColors']).toJS();

        const allTrainersList = getOnlyUniqueItems(classOccurrencesTrainersList.concat(trainingsTrainersList));

        const trainersColorsList = {};
        let calendarColorsCounter = 0;

        allTrainersList.forEach((trainer) => {
            trainersColorsList[trainer] = calendarColors[calendarColorsCounter];
            // eslint-disable-next-line no-plusplus
            calendarColorsCounter++;
            if (calendarColorsCounter >= calendarColors.length - 1) {
                calendarColorsCounter = 0;
            }
        });

        dispatch(setTrainersColorsList(trainersColorsList));
    };
};

export const getCalendarSelectedRange = createAction(CalendarTypes.GET_CALENDAR_SELECTED_RANGE);

export const setRangeStart = createAction(CalendarTypes.SET_RANGE_START);
export const setRangeEnd = createAction(CalendarTypes.SET_RANGE_END);
export const setRangeStartEnd = createAction(CalendarTypes.SET_RANGE_START_END);

export const getDateInterval = ({ date, type }) => {
    return dispatch => {
        let dateInterval;
        let formattedAfterDate;
        let formattedBeforeDate;
        if (type === 'day') {
            const formattedTodayDate = `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${date.getDate()}`;
            formattedAfterDate = formattedTodayDate;
            formattedBeforeDate = formattedTodayDate;
            dispatch(setRangeStart(formattedTodayDate));
            dispatch(setRangeEnd(formattedTodayDate));
        }
        if (type === 'week') {
            dateInterval = getWeekIntervalFromDate({ date, isAccurate: true });
        }
        if (type === 'month') {
            dateInterval = getMonthIntervalFromDate(date);
        }

        if (type === 'week' || type === 'month') {
            const { startDate, endDate } = dateInterval;

            // formattedAfterDate = moment.(startDate).format('YYYY-MM-DD');
            formattedAfterDate =
                `${startDate.getFullYear()}-${addZero(startDate.getMonth() + 1)}-${addZero(startDate.getDate())}`;
            dispatch(setRangeStart(formattedAfterDate));

            // formattedBeforeDate = moment.(endDate).format('YYYY-MM-DD');
            formattedBeforeDate = `${endDate.getFullYear()}-${addZero(endDate.getMonth() + 1)}-${addZero(endDate.getDate())}`; // eslint-disable-line max-len
            dispatch(setRangeEnd(formattedBeforeDate));
        }

        return { formattedAfterDate, formattedBeforeDate };
    };
};
