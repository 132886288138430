import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ModalContent extends PureComponent {
    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }
    
    render() {
        return this.props.children;
    }
}

ModalContent.propTypes = {
    resetState: PropTypes.func,
    children: PropTypes.shape({})
};

export default ModalContent;
