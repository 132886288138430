import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

const renderPlacesAutocompleteControl = props => {
    const {
        name, input, label, type, meta: { touched, error, valid }, formError, className = '', disabled = false,
        onKeyPress, selectedValue, handleSelectOrEnter
    } = props;

    const inputProps = {
        value: selectedValue || input.value === undefined ? '' : input.value,
        onChange: value => input.onChange(value),
        onBlur: () => input.onBlur(input.value),
        type,
        placeholder: null,
        autoFocus: true,
    };

    const AutocompleteItem = ({ formattedSuggestion }) => (
        <div className='autocomplete-item__overflow'>
            <div>{formattedSuggestion.mainText}</div>
            <div className='autocomplete-item__extra'>{formattedSuggestion.secondaryText}</div>
        </div>
    );

    const InputClassNames = classNames({
        'input-control__input': true,
        'input-control__input_touched': touched,
        'input-control__input_failed': ((touched && error) || formError),
        'input-control__input_empty': (input.value.length === 0 && !(touched && error))
    });

    const PlaceholderClassNames = classNames({
        'input-control__placeholder': true,
        'input-control__placeholder_touched': (touched || valid),
        'input-control__placeholder_failed': (touched && error),
        'input-control__placeholder_empty': (input.value.length === 0 && !(touched && error))
    });

    const ClassNames = {
        root: 'input-control__autocomplete',
        input: InputClassNames,
        autocompleteContainer: 'Select-menu-outer Select-menu input-control__autocomplete-container',
        autocompleteItem: 'Select-option input-control__autocomplete-item',
        autocompleteItemActive: 'input-control__autocomplete-item_active'
    };

    const options = {
        // eslint-disable-next-line no-undef
        location: new window.google.maps.LatLng(34.024642, -118.474030),
        radius: 5000,
        types: ['geocode']
    };

    return (
        <div className={`input-control ${className}`}>
            <PlacesAutocomplete
                {...input}
                inputProps={inputProps}
                disabled={disabled}
                onKeyPress={onKeyPress}
                autocompleteItem={AutocompleteItem}
                classNames={ClassNames}
                onSelect={handleSelectOrEnter}
                onEnterKeyDown={handleSelectOrEnter}
                options={options}
                googleLogo={false}/>
            <span className={PlaceholderClassNames}>{label}</span>
            {touched && error && <span className='input-control__error'>{error}</span>}
        </div>
    );
};

renderPlacesAutocompleteControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    isTextarea: PropTypes.bool,
    formError: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isCardInput: PropTypes.bool,
    isDateInput: PropTypes.bool,
    isMasked: PropTypes.bool,
    mask: PropTypes.string,
    maskChar: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    onKeyPress: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    selectedValue: PropTypes.any,
    handleSelectOrEnter: PropTypes.func,
    formattedSuggestion: PropTypes.shape({})
};

export default renderPlacesAutocompleteControl;
