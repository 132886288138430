import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import AvatarPlaceholder from '../AvatarPlaceholder';
import Icon from '../Icon';

const FieldItem = ({ field }) => {
    if (!field.value) {
        return (
            <div>
                {`${field.fieldName}: `}
                <span className='content-card__item-content_requires'>
                    {field.notSetMessage || 'N/A'}
                </span>
            </div>
        );
    }

    if (field.isValid !== undefined && !field.isValid) {
        return (
            <div>
                {`${field.fieldName}: `}
                <span className='content-card__item-content_requires'>
                    {field.invalidMessage}
                </span>
            </div>
        );
    }

    return (
        <div>
            {`${field.fieldName}: ${field.value}`}
        </div>
    );
};

FieldItem.propTypes = {
    field: PropTypes.shape({}).isRequired
};

const ContentCard = ({
                         header, actionOnClick, actionIcon, actionClassName, items, anotherActionOnClick,
                         anotherActionIcon, anotherActionClassName, readOnly
                     }) => {
    return (
        <div className='content-card'>
            <div className='content-card__header'>
                <div className='content-card__header-text'>{header}</div>
                <div className='content-card__header-actions'>
                    {actionOnClick && !readOnly &&
                    <a onClick={actionOnClick} className='content-card__header-action'>
                        <Icon glyph={actionIcon} className={actionClassName}/>
                    </a>
                    }
                    {anotherActionOnClick && !readOnly &&
                    <a onClick={anotherActionOnClick} className='content-card__header-action'>
                        <Icon glyph={anotherActionIcon} className={anotherActionClassName}/>
                    </a>
                    }
                </div>
            </div>
            {items.map((item) => {
                const dogItemClassNames = classNames({
                    'content-card__item-content': true,
                    'content-card__item-content_requires': item.statusCode !== 'AVAILABLE_SCHEDULE'
                });
                return (
                    <div key={item.key} className='content-card__item'>
                        {item.header && <div className='content-card__item-header'>{item.header}</div>}
                        {item.data &&
                        <div className='content-card__item-content'>
                            {item.dataUrl
                                ? (item.header !== 'Phone 2' && item.header !== 'Phone' && item.header === 'Email'
                                    ? (<a href={item.dataUrl} className='content-card__item-content-data'>
                                        {item.data}
                                    </a>)
                                    : (item.header === 'Phone'
                                        ? (<div
                                            className='content-card__item-content-fake-url content-card__item-content_info'
                                            onClick={item.actionCB}>
                                            {item.data}
                                        </div>)
                                        : (item.header === 'Email'
                                            ? (<a
                                                href={'mailto:' + item.data}
                                                className='content-card__item-content-data'>
                                                {item.data}
                                            </a>)
                                            : (item.key.indexOf('owner') !== -1
                                                ? (<Link to={item.dataUrl} className='content-card__item-content-data'>
                                                    {item.data}
                                                </Link>)
                                                : (<div className='content-card__item-content-data'>{item.data}</div>)
                                            )
                                        )
                                    )
                                )
                                : (<div className='content-card__item-content-data'>
                                    {Array.isArray(item.data) ? item.data.map((field, i) => {
                                        return <FieldItem field={field} key={`${i}_${item.header}`}/>;
                                    }) : item.data}
                                </div>)
                            }
                            {item.infoUrl
                                ? (item.infoUrl.includes('tel:')
                                    ? (<div className='content-card__item-content-data'>{item.info}</div>)
                                    : (<a href={item.infoUrl} className='content-card__item-content-data'>
                                        {item.info}
                                    </a>))
                                : (item.actionCB
                                    ? (item.header === 'Phone'
                                        ? (<div className='content-card__item-content'>{item.info}</div>)
                                        : (<div
                                            className='content-card__item-content-fake-url content-card__item-content_info'
                                            onClick={item.actionCB}>
                                            {item.info}
                                        </div>))
                                    : (<div className='content-card__item-content-fake-url'>{item.info}</div>))
                            }
                        </div>
                        }
                        {item.content &&
                        <div className='content-card__item-content'>{item.content}</div>
                        }
                        {item.img &&
                        <div className='content-card__item content-card__item_dog'>
                            {
                                item.img === 'no-photo' ?
                                    <AvatarPlaceholder type='dog'/> :
                                    <img
                                        alt={item.name}
                                        src={item.img}
                                        className='content-card__item-img'/>
                            }
                            <div className='content-card__item-info'>
                                {item.nameUrl
                                    ? (<Link className={'content-card__item-header'} to={item.nameUrl}>
                                        {item.name}
                                    </Link>)
                                    : <div className='content-card__item-header'>{item.name}</div>}
                                <div className='content-card__item-content'>{item.breed}</div>
                                <div className={dogItemClassNames}>
                                    {item.status}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

ContentCard.propTypes = {
    readOnly: PropTypes.bool,
    header: PropTypes.string.isRequired,
    actionOnClick: PropTypes.func,
    actionIcon: PropTypes.string,
    actionClassName: PropTypes.string,
    items: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({})
    ]),
    anotherActionOnClick: PropTypes.func,
    anotherActionIcon: PropTypes.string,
    anotherActionClassName: PropTypes.string
};

export default ContentCard;
