import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconPdf } from '../../../../../assets/icons/icon-pdf.svg';
import Icon from '../../../../common/Icon';

const ExportReportTo = ({ exportReportTo, filterValues }) => {
    return (
        <div
            className='custom-reports__export-report-to'
            onClick={() => exportReportTo(filterValues)}>
            <div className='export-report-to__text'>Export to Excel:</div>
            <Icon glyph={IconPdf} className='icon_pdf'/>
        </div>
    );
};

ExportReportTo.propTypes = {
    exportReportTo: PropTypes.func.isRequired,
    filterValues: PropTypes.shape({}).isRequired,
};

export default ExportReportTo;
