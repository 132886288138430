import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { getPackagesClassOptions } from '../../../../../actions/miscActions';
import { openCreatePackageModalDialog } from '../../../../../actions/modal/openActions';
import { ReactComponent as IconGrid } from '../../../../../assets/icons/icon-grid-view.svg';
import { ReactComponent as IconList } from '../../../../../assets/icons/icon-list-view.svg';
import AddNewItem from '../../../../../components/common/AddNewItem';
import { isEmployee, isManager, isTrainer } from '../../../../../helpers/userRoles';
import * as PackagesService from '../../../../../services/packages';
import FilterTags from '../../../../common/FilterTags';
import FilterForm from '../../../../common/FiterForm';
import Icon from '../../../../common/Icon';
import LinkButton from '../../../../common/LinkButton';
import PackagesGrid from './PackagesGrid';
import PackagesTable from './PackagesTable';

class PackagesPage extends React.PureComponent {

    static propTypes = {
        packageList: PropTypes.shape({}),
        classesOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({}),
        ]),
        getPackageList: PropTypes.func.isRequired,
        getNextPackageList: PropTypes.func,
        handleOpenCreatePackageModal: PropTypes.func,
        handleLoadingClassOptions: PropTypes.func,
        buildQueryString: PropTypes.func,
    };

    state = {
        filterTags: [],
        view: 'list',
        query: '',
    };

    componentDidMount() {
        const { getPackageList, handleLoadingClassOptions } = this.props;
        getPackageList();
        handleLoadingClassOptions();
    }

    getFilterForm() {
        const { packageList, classesOptions } = this.props;

        if (packageList.get('isLoading') && !packageList.get('choices').size || !('length' in classesOptions)) {
            return <div>Loading filter options...</div>;
        }

        let options = packageList.get('choices').toJS();
        options[0].options = _.uniqBy(options[0].options, 'value');
        options = fromJS(options);

        return (
            <div className='packages__filter'>
                <FilterForm
                    onChangeHandler={this.filterFormChangeHandler.bind(this)}
                    options={options}
                    filterTags={this.filterTags}
                    filtersRef={element => {
                        this.filterForm = element;
                    }}
                    newVersion/>
            </div>
        );
    }

    getView() {
        const { packageList, getPackageList, getNextPackageList } = this.props;
        const { filterTags } = this.state;
        const queryString = this.buildQueryString(filterTags);

        if (!packageList.get('items').size && !packageList.get('isLoading')) {
            return <div className='packages__empty-content'>No data</div>;
        }

        const isMobile = window.innerWidth <= 736;

        return this.state.view === 'grid' || isMobile
            ? (<PackagesGrid
                readOnly={isTrainer() || isEmployee() || isManager()}
                data={packageList}
                isLoading={packageList.get('isLoading')}
                getNext={getNextPackageList}/>)
            : (<PackagesTable
                readOnly={isTrainer() || isEmployee() || isManager()}
                isManual
                defaultPageSize={packageList.get('pageSize')}
                pages={packageList.get('pages')}
                count={packageList.get('count')}
                data={packageList.get('items')}
                onSorting={value => getPackageList(`o=${value}&${queryString}`)}
                loading={packageList.get('isLoading')}
                nextUrl={packageList.get('nextUrl')}
                getNext={getNextPackageList}/>);
    }

    getViewToggle = () => {
        const { view } = this.state;
        const current = view === 'grid'
            ? <Icon glyph={IconList} className='icon_list-view packages__view-icon'/>
            : <Icon glyph={IconGrid} className='icon_grid-view packages__view-icon'/>;
        return <a href='' className='packages__view-icon-wrapper' onClick={this.viewToggleClickHandler}>{current}</a>;
    };

    getClearButton = () => {
        if (this.state.filterTags.length) {
            return (
                <LinkButton
                    className='filter-tags__link-button'
                    text='Clear'
                    onClickHandler={this.filterTagsClearHandler}/>
            );
        }

        return null;
    };

    getAddNewButton() {
        const { classesOptions, handleOpenCreatePackageModal } = this.props;
        if (isTrainer() || isEmployee() || isManager()) return null;
        return (
            <AddNewItem
                className='packages__add'
                disabled={!('length' in classesOptions)}
                text='Create Package or Training'
                onClickHandler={() => { handleOpenCreatePackageModal(); }}/>
        );
    }

    filterTagsClearHandler = () => {
        const { getPackageList } = this.props;
        this.filterForm.clearFilters();
        getPackageList();
    };

    filterFormChangeHandler(value) {
        const { getPackageList } = this.props;
        const { filterTags } = this.state;
        const queryObject = value.queryObject.toJS();

        if (filterTags.indexOf(value.value) > -1) return;

        const newFilterTags = [];
        Object.keys(queryObject).forEach((key) => {
            const tags = queryObject[key];
            tags.forEach((tag) => {
                newFilterTags.push(tag);
            });
        });

        this.setState({
            filterTags: newFilterTags,
        });

        getPackageList(this.buildQueryString(newFilterTags));
    }

    buildQueryString = (filterTags) => {
        const { packageList } = this.props;
        const query = {};
        const params = [];

        filterTags.forEach(tag => {
            packageList.get('choices').toJS().forEach((group) => {
                group.options.forEach((item) => {
                    if (item.value === tag) {
                        const queryParam = group.query === 'name' ? 'name__icontains' : group.query;
                        if (!(queryParam in query)) {
                            query[queryParam] = [tag];
                        } else {
                            query[queryParam].push(tag);
                        }
                    }
                });
            });
        });

        Object.keys(query).forEach(key => {
            const value = query[key].join(',');
            params.push(`${key}=${value}`);
        });

        return params.join('&');
    };

    filterTagsRemoveHandler = (tagIndex) => {
        const { getPackageList } = this.props;
        const { filterTags } = this.state;
        const newFilterTags = Array.from(filterTags);
        newFilterTags.splice(tagIndex, 1);

        this.setState({
            filterTags: newFilterTags,
        });

        this.filterForm.removeTagByIndex(tagIndex);
        getPackageList(this.buildQueryString(newFilterTags));
    };

    viewToggleClickHandler = (e) => {
        e.preventDefault();
        const { view } = this.state;
        this.setState({
            view: view === 'list' ? 'grid' : 'list',
        });
    };

    render() {
        return (
            <div className='packages'>
                <div className='packages__top'>
                    <div className='packages__top-content'>
                        {this.getFilterForm()}
                        {this.getViewToggle()}
                    </div>
                    {this.getAddNewButton()}
                </div>
                {
                    this.state.filterTags.length > 0 &&
                    <div className='packages__filter-tags'>
                        <FilterTags
                            tags={this.state.filterTags}
                            tagsRemoveHandler={this.filterTagsRemoveHandler}
                            tagsClearHandler={this.filterTagsClearHandler}/>
                        {this.getClearButton()}
                    </div>
                }
                <div className='packages__header'>packages and training programs</div>
                {this.getView()}
            </div>
        );
    }
}

const form = reduxForm({
    form: 'packagesForm',
});

const mapStateToProps = state => {
    return {
        packageList: state.getIn(['packageList']),
        classesOptions: state.getIn(['misc', 'options', 'class']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getPackageList: bindActionCreators(PackagesService.getPackageList, dispatch),
    getNextPackageList: bindActionCreators(PackagesService.getNextPackageList, dispatch),
    handleOpenCreatePackageModal: bindActionCreators(openCreatePackageModalDialog, dispatch),
    handleLoadingClassOptions: bindActionCreators(getPackagesClassOptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(PackagesPage));
