/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import * as FormFactory from '../../../../components/common/Form/formFactory';

const TabModalDialog = (props) => {
    const { onSubmit, handleSubmit, isSubmitting, onTabChange, data, invalid, modalError } = props;

    const submitButtonClassNames = classNames({
        'button modal-dialog__button': true,
        'button_disabled': isSubmitting || invalid
    });

    const renderForm = () => {
        return FormFactory.getForm({
            formName: data.forms[data.activeForm].form,
            formProps: props,
            initialValues: data.forms[data.activeForm].initialValues
        });
    };

    const normalizeFormData = formData => {
        const { submitNormalizer, updateNormalizer } = FormFactory.getFormDefinition(data.forms[data.activeForm].form);
        onSubmit({ formData, submitNormalizer, updateNormalizer });
    };

    const renderTabControls = () => {
        return (
            <div className='modal-dialog__tab-controls'>
                {data.forms.map((formData, i) => {
                    if (data.activeForm === i) {
                        return (
                            <div className='modal-dialog__tab-switch-wrapper' key={formData.tabName}>
                                <span
                                    className='modal-dialog__tab-switch_active'>
                                    {formData.tabName}
                                </span>
                            </div>
                        );
                    }

                    return (
                        <div className='modal-dialog__tab-switch-wrapper' key={formData.tabName}>
                            <a
                                href=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    onTabChange(i);
                                }}
                                className='modal-dialog__tab-switch'>
                                {formData.tabName}
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderRadioControls = () => {
        return (
            <div className='radio-control'>
                <div className='radio-control__content'>
                    <div className='radio-control__description'>
                        <label className='radio-control__description-label'>
                            {data.radioTabsLabel}
                        </label>
                    </div>
                    <div className='radio-control__controls'>
                        {data.forms.map((formData, i) => {
                            return (
                                <label
                                    className='radio-control__controls-label'
                                    key={`${data.name}_${i}`}
                                    onClick={() => { onTabChange(i); }}>
                                    <Field
                                        name={data.name}
                                        component='input'
                                        type='radio'
                                        value={formData.tabName}
                                        checked={data.activeForm === i}
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>{formData.tabName}</span>
                                </label>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const modalClassNames = classNames({
        'modal-dialog__form': true,
        'modal-dialog__form_radio-tabs': data.radioSwitcher,
        [data.formAdditionalClass]: data.formAdditionalClass
    });

    return (
        <form className={modalClassNames} onSubmit={handleSubmit(normalizeFormData)}>
            <div className='modal-dialog__form-content'>
                {data.radioSwitcher ? renderRadioControls() : renderTabControls()}
                {renderForm()}
            </div>
            {modalError !== null && <div className='form__error'>{modalError}</div>}
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={isSubmitting}>
                    {
                        data.forms[data.activeForm].submitButtonText
                            ? data.forms[data.activeForm].submitButtonText
                            : isSubmitting
                            ? 'Submitting'
                            : 'Save'
                    }
                </button>
            </div>
        </form>
    );
};

TabModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    onTabChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    modalError: PropTypes.string,
};

const form = reduxForm({
    form: 'TabModalDialogForm',
    enableReinitialize: true
});

export default form(TabModalDialog);
