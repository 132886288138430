/* eslint-disable dot-notation,camelcase,consistent-return */
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as FormFactory from '../components/common/Form/formFactory';
import { flattenValueLabel } from '../helpers/data';
import { combineTrainingDateTime, formatDateToDashes, time12to24 } from '../helpers/date';
import { getUserNameSpace, test, trainingCompositeRole } from '../helpers/userRoles';
import * as EventTypes from '../types/dashboard/eventTypes';
import * as AlertEvents from '../components/Alert/events';

const INITIAL_STATE = fromJS({
    event: {},
    // default formConfig so '/trainings/new' can be opened directly
    formConfig: {
        form: 'trainingEventForm',
        submitAction: 'postItem',
        updateAction: 'goToCalendarPage',
        url: `/v1/${trainingCompositeRole}/training-events-composite/`,
        busyFields: [],
        shouldInitialise: false,
    },
    products: [],
    productsAmount: 0,
});

export const classEventSubmitNormalizer = formData => {
    const bookingDataJS = formData.size ? formData.toJS() : formData;
    const {
        pickup_location, new_drop_off_location_is_the_same, drop_off_location, new_pickup_location_is_the_same, card
    } = bookingDataJS;

    const pickupLocationNormalized = typeof pickup_location === typeof {}
        ? pickup_location.value
        : pickup_location;
    const dropoffLocationNormalized = typeof drop_off_location === typeof {}
        ? drop_off_location.value
        : drop_off_location;
    const cardNormalized = typeof card === typeof {}
        ? card.value
        : card;

    let newPickupAddress;
    if (pickupLocationNormalized === 123456) {
        const {
            new_pickup_location_name,
            new_pickup_location_address1,
            new_pickup_location_address2,
            new_pickup_location_city,
            new_pickup_location_state,
            new_pickup_location_zip_code,
            new_pickup_location_location_notes,
        } = bookingDataJS;
        newPickupAddress = {
            name: new_pickup_location_name,
            address1: new_pickup_location_address1,
            address2: new_pickup_location_address2 !== undefined ? new_pickup_location_address2 : '',
            city: new_pickup_location_city,
            state: new_pickup_location_state.value,
            zip_code: new_pickup_location_zip_code,
            location_notes: new_pickup_location_location_notes !== undefined ? new_pickup_location_location_notes : '',
        };
    }
    bookingDataJS.pickup_location = pickupLocationNormalized;
    if (pickup_location !== undefined) {
        if (pickup_location.detail) {
            bookingDataJS.pickup_location_detail = pickup_location.detail;
        }
    }

    let newDropoffAddress;
    if (dropoffLocationNormalized === 123456) {
        const {
            new_drop_off_location_name,
            new_drop_off_location_address1,
            new_drop_off_location_address2,
            new_drop_off_location_city,
            new_drop_off_location_state,
            new_drop_off_location_zip_code,
            new_drop_off_location_location_notes,
        } = bookingDataJS;
        newDropoffAddress = {
            name: new_drop_off_location_name,
            address1: new_drop_off_location_address1,
            address2: new_drop_off_location_address2 !== undefined ? new_drop_off_location_address2 : '',
            city: new_drop_off_location_city,
            state: new_drop_off_location_state.value,
            zip_code: new_drop_off_location_zip_code,
            location_notes: new_drop_off_location_location_notes !== undefined ? new_drop_off_location_location_notes : '',
        };
    }
    bookingDataJS.drop_off_location = dropoffLocationNormalized;
    if (drop_off_location !== undefined) {
        if (drop_off_location.detail) {
            bookingDataJS.drop_off_location_detail = drop_off_location.detail;
        }
    }

    let newCard;
    if (cardNormalized === 123456) {
        const {
            new_card_number, new_card_expiration_month, new_card_expiration_year, new_card_cvc
        } = bookingDataJS;
        newCard = {
            number: new_card_number,
            expiration_month: new_card_expiration_month,
            expiration_year: new_card_expiration_year,
            cvc: new_card_cvc,
            save_card: true,
        };
    }

    // card is undefined for training case
    if (card !== undefined) {
        if (card.type === 'package') {
            bookingDataJS.purchased_package = cardNormalized;
            bookingDataJS.card = card.defaultCardId;
        } else {
            if (bookingDataJS.purchased_package) {
                bookingDataJS.purchased_package = null;
            }
            bookingDataJS.card = cardNormalized;
        }
    }

    if (new_drop_off_location_is_the_same) {
        newPickupAddress = undefined;
    }

    if (new_pickup_location_is_the_same) {
        newDropoffAddress = undefined;
    }

    if (newPickupAddress !== undefined) {
        bookingDataJS.newPickupAddress = newPickupAddress;
    }

    if (newDropoffAddress !== undefined) {
        bookingDataJS.newDropoffAddress = newDropoffAddress;
    }

    if (newCard !== undefined) {
        bookingDataJS.newCard = newCard;
    }

    return bookingDataJS;
};

const eventFormConfig = {
    'class': eventData => {
        if (Object.keys(eventData).length === 0) {
            return {
                form: 'classEventForm',
                initialValues: {
                    pickup_start_time: {
                        value: '06:00AM',
                        label: '06:00AM'
                    },
                    pickup_end_time: {
                        value: '06:00AM',
                        label: '06:00AM'
                    },
                    dropoff_start_time: {
                        value: '06:00AM',
                        label: '06:00AM'
                    },
                    dropoff_end_time: {
                        value: '06:00AM',
                        label: '06:00AM'
                    },
                },
                submitAction: 'postItem',
                url: `/v1/${getUserNameSpace('class-occurrences')}/class-occurrences/`,
            };
        }
        return {
            form: 'classEventForm',
            itemId: eventData.id,
            initialValues: FormFactory.forms['classEventForm'].getInitialValues(eventData),
            submitAction: 'tryEditClassEvent',
            updateAction: 'updateClassCurrentEvent',
            errorAction: 'handleClassEventEditError',
            shouldForceSubmit: false,
            url: `/v1/${getUserNameSpace('class-occurrences')}/class-occurrences/${eventData.id}/`
        };
    },
    'training': (eventData) => {
        if (Object.keys(eventData).length === 0) {
            return {
                form: 'trainingEventForm',
                initialValues: {
                    start_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    },
                    end_time: {
                        value: '06:00AM',
                        label: '06:00AM',
                    }
                },
                submitAction: 'postItem',
                updateAction: 'goToCalendarPage',
                errorAction: 'handleTrainingEventCreateError',
                alertEvent: 'PROGRAM_SCHEDULE',
                url: `/v1/${getUserNameSpace('training-events-composite')}/training-events-composite/`,
                busyFields: [],
                shouldInitialise: false,
            };
        }
        return {
            form: 'trainingEventForm',
            className: 'event-detail__form-training',
            itemId: eventData.id,
            initialValues: FormFactory.forms['trainingEventForm'].getInitialValues(eventData),
            submitAction: 'tryEditTrainingEvent',
            updateAction: 'updateCurrentTrainingEventAndProductsAmount',
            errorAction: 'handleTrainingEventEditError',
            url: `/v1/${getUserNameSpace('training-events-composite')}/training-events-composite/${eventData.id}/`,
            shouldPassValues: true,
            busyFields: []
        };
    }
};

export const ClassEventDataNormalizer = (formData) => {
    formData = formData.toJS();
    const normalizeTime = value => {
        return time12to24(value);
    };

    const normalizeDate = value => {
        return formatDateToDashes(value);
    };

    const normalizeValue = (key, value) => {
        if (key.endsWith('_time')) {
            return { key, value: normalizeTime(value) };
        }

        if (key.endsWith('_date')) {
            return { key: 'date', value: normalizeDate(value) };
        }

        return { key, value };
    };

    return flattenValueLabel(formData, normalizeValue);
};

export const TrainingEventDataNormalizer = (formData, state) => {
    const products = [];
    formData = flattenValueLabel(formData.toJS());
    formData.start = combineTrainingDateTime(formData.start_date, formData.start_time);
    formData.end = combineTrainingDateTime(formData.end_date, formData.end_time);

    Object.keys(formData).forEach(key => {
        if (key.endsWith('_time') || key.endsWith('_date')) {
            delete formData[key];
        }
    });

    state.getIn(['currentEvent', 'products']).forEach(product => {
        if (product.size !== undefined) {
            product = product.toJS();
        }
        const { purchased, pickup_location, drop_off_location } = product;
        products.push({ purchased, pickup_location, drop_off_location });
    });

    formData.products = products;
    return formData;
};

const currentEventReducer = handleActions({
    [EventTypes.RESET_CURRENT_EVENT_REDUCER]: () => {
        return INITIAL_STATE;
    },
    [EventTypes.SET_CURRENT_EVENT]: (state, action) => {
        return state.set('event', fromJS(action.payload))
            .set('formConfig', fromJS(eventFormConfig[action.payload.type](action.payload)));
    },
    [EventTypes.UPDATE_CURRENT_TRAINING_EVENT]: (state, action) => {
        return state.set('event', fromJS(action.payload.set('type', 'training')));
    },
    [EventTypes.SET_CURRENT_EVENT_PRODUCTS]: (state, action) => {
        return state.set('products', fromJS(action.payload))
            .set('productsAmount', action.payload.length);
    },
    [EventTypes.CLEAR_CURRENT_EVENT_PRODUCTS]: (state, action) => {
        return state.set('products', fromJS([]))
            .set('productsAmount', 0);
    },
    [EventTypes.ADD_BUSY_FIELD]: (state, action) => {
        return state.updateIn(['formConfig', 'busyFields'], fields => fields.push(action.payload));
    },
    [EventTypes.REMOVE_BUSY_FIELD]: (state, action) => {
        return state.updateIn(['formConfig', 'busyFields'], fields => fields.filter(field => field !== action.payload));
    },
    [EventTypes.SET_NEW_CLASS_OCCURRENCE_EVENT]: (state, action) => {
        return state.set('event', fromJS({}))
            .set('formConfig', fromJS(eventFormConfig['class']({})));
    },
    [EventTypes.SET_NEW_TRAINING_EVENT]: (state) => {
        return state.set('event', fromJS({ type: 'training' }))
            .set('formConfig', fromJS(eventFormConfig['training']({})));
    },
    [EventTypes.SET_CLASS_OCCURRENCE_EVENT_INFO]: (state, action) => {
        return state.set('event', action.payload)
            .setIn(['formConfig', 'initialValues'],
                fromJS(FormFactory.forms['classEventForm'].getInitialValues(action.payload)));
    },
    [EventTypes.SET_TRAINING_EVENT_INFO]: (state, action) => {
        return state.set('event', action.payload)
            .setIn(['formConfig', 'initialValues'],
                fromJS(FormFactory.forms['trainingEventForm'].getInitialValues(action.payload)));
    },
    [EventTypes.ADD_DOG_TO_EVENT_PRODUCTS]: (state, action) => {
        return state.update(
            state => state.setIn(['products'], state.getIn(['products']).insert(0, fromJS(action.payload))));
    },
    [EventTypes.DECREASE_AVAILABLE_SPOTS]: (state, action) => {
        return state.setIn(['event', 'available_spots'], state.getIn(['event', 'available_spots']) - 1);
    },
    [EventTypes.CHANGE_PRODUCT_STATUS]: (state, action) => {
        const { id, status, status_detail, extended_status } = action.payload;
        const currentKey = state.getIn(['products'])
            .findKey(product => product.get('id') === id);
        return state
            .setIn(['products', currentKey, 'status'], status)
            .setIn(['products', currentKey, 'status_detail'], status_detail)
            .setIn(['products', currentKey, 'extended_status'], extended_status);
    },
    [EventTypes.UPDATE_PRODUCT_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['products'])
            .findKey(product => product.get('id') === action.payload.get('class_product'));
        if (currentKey !== undefined) {
            return state.setIn(['products', currentKey, 'reports'],
                state.getIn(['products', currentKey, 'reports'])
                    .insert(0, fromJS({ id: action.payload.get('id') })));
        }
        return state;
    },
    [EventTypes.DELETE_PRODUCT_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['products'])
            .findKey(product => product.get('id') === action.payload);
        return state.setIn(['products', currentKey, 'reports'], fromJS([]));
    },
    [EventTypes.DELETE_UNSAVED_TRAINING_EVENT_PRODUCT]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['products'],
                product => product.filter(product => product.getIn(['dog_detail', 'id']) !== action.payload));
        });
    },
    [EventTypes.UPDATE_EVENT_PRODUCT]: (state, action) => {
        const currentKey = state.getIn(['products'])
                                .findKey(product => product.get('id') === action.payload.get('id'));
        return state.setIn(['products', currentKey], action.payload);
    },
    [EventTypes.UPDATE_EVENT_PRODUCT_LOCATIONS]: (state, action) => {
        const pickupLocationValue = action.payload.get('pickup_location').value;
        const dropoffLocationValue = action.payload.get('drop_off_location').value;
        const pickupLocationDetail = action.payload.get('pickup_location').detail;
        const dropoffLocationDetail = action.payload.get('drop_off_location').detail;

        const isPickupLocationInitial = pickupLocationValue === undefined;
        const isDropOffLocationInitial = dropoffLocationValue === undefined;

        if (isPickupLocationInitial && isDropOffLocationInitial) {
            return state;
        }

        const currentKey = state.getIn(['products'])
                                .findKey(product => {
                                    return product.getIn(['dog_detail', 'id']) === action.payload.getIn(['dogId']);
                                });

        if (isPickupLocationInitial) {
            return state.setIn(['products', currentKey, 'drop_off_location'], dropoffLocationValue)
                        .setIn(['products', currentKey, 'drop_off_location_detail'], dropoffLocationDetail);
        }

        if (isDropOffLocationInitial) {
            return state.setIn(['products', currentKey, 'pickup_location'], pickupLocationValue)
                        .setIn(['products', currentKey, 'pickup_location_detail'], pickupLocationDetail);
        }

        return state.setIn(['products', currentKey, 'pickup_location'], pickupLocationValue)
                    .setIn(['products', currentKey, 'pickup_location_detail'], pickupLocationDetail)
                    .setIn(['products', currentKey, 'drop_off_location'], dropoffLocationValue)
                    .setIn(['products', currentKey, 'drop_off_location_detail'], dropoffLocationDetail);
    }
}, INITIAL_STATE);

export default currentEventReducer;
