import * as axios from 'axios';
import {
    finishTrainingsListLoading,
    setNextTrainingsList,
    setTrainingsList,
    startTrainingsListLoading
} from '../actions/dashboard/trainingsActions';
import {
    finishLoadingCurrentCustomer,
    finishLoadingCurrentDog,
    startLoadingCurrentCustomer,
    startLoadingCurrentDog
} from '../actions/dashboard/employeeActions';
import * as TrainingActions from '../actions/dashboard/trainingActions';
import { getUserNameSpace } from '../helpers/userRoles';
import { setGlobalError } from '../actions/errorHandlerActions';
import { getScope } from './oauth';

export const getTrainingsList = (queryString) => {
    return (dispatch, getState) => {
        dispatch(startTrainingsListLoading());
        let url = `/v1/${getUserNameSpace('training-events-composite')}/training-events-composite/`;
        const rangeStart = getState().getIn(['calendar', 'rangeStart']) + 'T00:00';
        const rangeEnd = getState().getIn(['calendar', 'rangeEnd']) + 'T23:59';
        const rangeQuery = `start_0=${rangeStart}&start_1=${rangeEnd}`;
        queryString = queryString ? queryString + '&' + rangeQuery : rangeQuery;

        if (queryString) {
            url = `${url}?${queryString + '&page_size=0'}`;
        }

        return axios.get(url)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(setTrainingsList(response.data));
                        dispatch(finishTrainingsListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishTrainingsListLoading());
                    });
    };
};

export const getNextTrainingsList = () => {
    return (dispatch, getState) => {
        dispatch(startTrainingsListLoading());
        const nextUrl = getState().getIn(['trainingsList', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(setNextTrainingsList(response.data));
                        dispatch(finishTrainingsListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishTrainingsListLoading());
                    });
    };
};

export const patchTrainingEvent = (eventData) => {
    return dispatch => {
        return axios
            .patch(`/v1/${getUserNameSpace('training-events-composite')}/training-events-composite/${eventData.id}/`,
                eventData)
                    .then(response => {
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                // if (err.response.data.error_description) {
                                //     throw new SubmissionError({
                                //         email: err.response.data.error_description,
                                //         password: err.response.data.error_description
                                //     });
                                // }
                            }
                        }
                    });
    };
};

export const getCustomerTrainingsList = () => {
    return (dispatch) => {
        dispatch(startTrainingsListLoading());
        const url = '/v1/customers/packages-trainings/?model_type=trainingprogram&is_archived=false';

        return axios.get(url)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(setTrainingsList(response.data));
                        dispatch(finishTrainingsListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishTrainingsListLoading());
                    });
    };
};

export const sendTrainingRequest = (data) => {
    return dispatch => {
        const url = `/v1/customers/me/training-programs/${data.id}/request/`;
        return axios.post(url, data)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
            });
    };
};

export const getPreviousTrainings = ({ context, id }) => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace(context + '.previous-trainings')}/${context}/${id}/previous-trainings/`)
                    .then((response) => {
                        if (context === 'customers') {
                            dispatch(startLoadingCurrentCustomer());
                            dispatch(TrainingActions.setCustomersPreviousTrainings(response.data));
                            dispatch(finishLoadingCurrentCustomer());
                            dispatch(TrainingActions.markCustomersPreviousTrainingsLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(startLoadingCurrentDog());
                            dispatch(TrainingActions.setDogsPreviousTrainings(response.data));
                            dispatch(finishLoadingCurrentDog());
                            dispatch(TrainingActions.markDogsPreviousTrainingsLoaded());
                        }
                    })
                    .catch((error) => {
                        if (context === 'customers') {
                            dispatch(finishLoadingCurrentCustomer());
                        }
                        if (context === 'dogs') {
                            dispatch(finishLoadingCurrentDog());
                        }
                        dispatch(setGlobalError(error));
                    });
    }
};

export const getUpcomingTrainings = ({ context, id }) => {
    return (dispatch) => {
        dispatch(startLoadingCurrentCustomer());
        return axios.get(`/v1/${getUserNameSpace(context + '.upcoming-trainings')}/${context}/${id}/upcoming-trainings/`)
                    .then((response) => {
                        if (context === 'customers') {
                            dispatch(startLoadingCurrentCustomer());
                            dispatch(TrainingActions.setCustomersUpcomingTrainings(response.data.results));
                            dispatch(finishLoadingCurrentCustomer());
                            dispatch(TrainingActions.markCustomersUpcomingTrainingsLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(startLoadingCurrentDog());
                            dispatch(TrainingActions.setDogsUpcomingTrainings(response.data.results));
                            dispatch(finishLoadingCurrentDog());
                            dispatch(TrainingActions.markDogsUpcomingTrainingsLoaded());
                        }
                    })
                    .catch((error) => {
                        if (context === 'customers') {
                            dispatch(finishLoadingCurrentCustomer());
                        }
                        if (context === 'dogs') {
                            dispatch(finishLoadingCurrentDog());
                        }
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};

export const getCustomerUpcomingTrainings = () => {
    return dispatch => {
        return axios.get('/v1/customers/me/upcoming-trainings/')
                    .then((response) => {
                        dispatch(TrainingActions.setCustomersUpcomingTrainings(response.data.results));
                        dispatch(TrainingActions.markCustomersUpcomingTrainingsLoaded());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};

export const patchPreviousTrainingsAsync = ({ context, trainingData }) => {
    return async dispatch => {
        const contextId = trainingData.contextId;
        const trainingId = trainingData.trainingId;
        const result = await axios.patch(
            `/v1/${getUserNameSpace(context + '.previous-trainings')}/${context}/${contextId}/previous-trainings/${trainingId}/`,
            trainingData);
        return result;
    };
};

export const patchPreviousTrainings = ({ context, trainingData }) => {
    return (dispatch) => {
        const contextId = trainingData.contextId;
        const trainingId = trainingData.trainingId;
        const request = new FormData();
        Object.keys(trainingData).map((trainingInfo) => {
            return request.append(trainingInfo, trainingData[trainingInfo]);
        });
        request.append('scope', getScope());

        return axios.patch(`/v1/${getUserNameSpace(context + '.previous-trainings')}/${context}/${contextId}/` +
            `previous-trainings/${trainingId}/`, request)
                    .then(response => {
                        if (context === 'customers') {
                            dispatch(TrainingActions.getCustomersPreviousTrainings(contextId));
                        }
                        if (context === 'dogs') {
                            dispatch(TrainingActions.getDogsPreviousTrainings(contextId));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const patchUpcomingTrainings = ({ context, contextId, trainingId, trainingData }) => {
    return (dispatch, getState) => {
        return axios.patch(
            `/v1/${getUserNameSpace(context + '.upcoming-trainings')}/${context}/${contextId}/upcoming-trainings/${trainingId}/`,
            trainingData)
                    .then(response => {
                        console.log('patchUpcomingTraining response: ', response);
                        if (context === 'customers') {
                            dispatch(TrainingActions.setCustomersUpcomingTrainingDetails(response.data));
                        }
                        if (context === 'dogs') {
                            dispatch(TrainingActions.setDogsUpcomingTrainingDetails(response.data));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const patchUpcomingTrainingsAsync = ({ context, trainingData, queryParams }) => {
    return async dispatch => {
        const contextId = trainingData.contextId;
        const trainingId = trainingData.trainingId;
        const result = await axios.patch(
            `/v1/${getUserNameSpace(context + '.upcoming-trainings')}/${context}/${contextId}/upcoming-trainings/${trainingId}/`,
            trainingData,
            { params: queryParams }
            );
        return result;
    };
};