import React from 'react';

class DogReportVideo extends React.Component {
  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();
    this.iframeWrapperRef = React.createRef();

    this.state = {
      youtubePlayer: null,
    };

    if (!document.querySelector('#youtube-video-api-script')) {
      window.onYouTubeIframeAPIReady = function() {
        window.youTubeIframeAPIReady = true;
      }
      const scriptTag = document.createElement('script');
      scriptTag.src="https://www.youtube.com/iframe_api";
      scriptTag.id = 'youtube-video-api-script';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }
  }

  componentDidMount() {
    this.setUpYouTubePlayer();
  }

  setUpYouTubePlayer = () => {
    if (!window.youTubeIframeAPIReady) {
      setTimeout(this.setUpYouTubePlayer, 10);
      return;
    }

    const youtubeIframe = this.iframeRef.current;
    const youtubeIframeWrapper = this.iframeWrapperRef.current;
    
    const onPlayerStateChange = event => {
      if (event.data === window.YT.PlayerState.ENDED) {
        youtubeIframeWrapper.classList.add("ended");
      } else if (event.data === window.YT.PlayerState.PLAYING) {
        youtubeIframeWrapper.classList.remove("ended");
        youtubeIframeWrapper.classList.remove("paused");
      }
    };

    const youtubePlayer = new window.YT.Player(youtubeIframe, {
      events: {
        'onStateChange': onPlayerStateChange
      }
    });

    youtubeIframeWrapper.addEventListener("click", function() {
      const playerState = youtubePlayer.getPlayerState();
      if (playerState === window.YT.PlayerState.ENDED) {
        youtubePlayer.seekTo(0);
      }
    });
  }

  render() {
    const { videoUrl } = this.props;

    return (
      <div className="dog-report-video-outer-wrapper">
        <div
          className="dog-report-video-inner-wrapper"
          ref={this.iframeWrapperRef}
        >
          <iframe
            className="dog-report-video"
            src={`${videoUrl}?disablekb=1&modestbranding=1&rel=0&enablejsapi=1`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            ref={this.iframeRef}/>
        </div>
      </div>
    );
  }
}

export default DogReportVideo;
