import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCamera } from '../../../../../assets/icons/icon-camera.svg';
import { ReactComponent as IconDog } from '../../../../../assets/icons/icon-dog.svg';
import Icon from '../../../Icon/index';

const ReportCardImageUpload = ({ image, clickHandler, name }) => {
    const getImage = () => {
        if (image) {
            return (
                <div>
                    <div
                        className='dog-report-form__image-preview'
                        style={{ background: `url(${image}) no-repeat center center / cover`, backgroundSize: `100% 100%` }}/>
                    <div className='dog-report__link'/>
                </div>
            );
        }

        return (
            <div className='dog-report-form__image-preview_empty' onClick={clickHandler}>
                <Icon className='dog-report-form__image-preview-icon' glyph={IconDog}/>
            </div>
        );
    };

    return (
        <div className='dog-report-form__image-upload-container'>
            {getImage()}
            <a className='dog-report-form__update-image-button' onClick={clickHandler}>
                <Icon glyph={IconCamera} className='icon_image-upload'/>
                <div className='dog-report-form__update-image-button_text'>Change Image</div>
            </a>
        </div>
    );
};

ReportCardImageUpload.propTypes = {
    image: PropTypes.string,
    clickHandler: PropTypes.func,
    preview: PropTypes.string,
    name: PropTypes.string,
};

export default ReportCardImageUpload;
