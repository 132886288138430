import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import * as FormFactory from '../../../../components/common/Form/formFactory';
import {getGlanceLocation, getGlanceTrainingLocation} from '../../../../services/glance';
import { ADMIN_ONLY_PACKAGE_ID } from '../../../../config';
import {setGlobalError} from "../../../../actions/errorHandlerActions";
import {
    finishGlanceTrainingDataLocationLoading,
    setGlanceTrainingLocationItems
} from "../../../../actions/dashboard/dashboardPageActions";

const styles = {
    alternativeClassItem: {
        display: 'flex',
        flexDirection: 'row",'
    },
    changeClassesContainer: {
        margin: '0.86rem auto 0',
        padding: '0 20px',
        width: 515,
    },
};

class EditCreateModalDialog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            alternativeClassId: null,
            alternativeClassesData: [],
            changeClassesChecked: false,
            fetchingAlternativeClasses: true,
            fetchingAlternativeClassesError: '',
        };
    }

    componentDidMount() {
        const { data } = this.props;

        if (data.form !== 'editTrainingModalDialog') {
            const {
                classDate,
                className,
                classOccurrenceId,
            } = data;
            getGlanceLocation({
                date: classDate,
                trainingClassName: className,
            })()
              .then(({data}) => {
                  const filteredData = data.filter((classItem) => {
                      return classItem.class_occurrence !== classOccurrenceId;
                  });
                  this.setState({
                      alternativeClassesData: filteredData,
                      fetchingAlternativeClasses: false,
                      fetchingAlternativeClassesError: '',
                  });
              })
              .catch((err) => {
                  this.setState({
                      alternativeClassesData: [],
                      fetchingAlternativeClasses: false,
                      fetchingAlternativeClassesError: 'Error getting available classes',
                  });
              });
        } else {
            const {
                trainingDate,
                trainingName,
                trainingOccurrenceId,
            } = data;
            getGlanceTrainingLocation({
                date: trainingDate,
                trainingName })()
              .then(({data}) => {
                  const filteredData = data.filter((trainingItem) => {
                      return trainingItem.training_event !== trainingOccurrenceId;
                  });
                this.setState({
                    alternativeClassesData: filteredData,
                    fetchingAlternativeClasses: false,
                    fetchingAlternativeClassesError: '',
                });
            })
        .catch((err) => {
                this.setState({
                    alternativeClassesData: [],
                    fetchingAlternativeClasses: false,
                    fetchingAlternativeClassesError: 'Error getting available classes',
                });
            });
        }
    }

    onClickAlternativeClassItem = (event) => {
        this.setState({ alternativeClassId: event.target.value });
    }

    getAlternativeClassItem = (classData, index) => {
        const { alternativeClassId } = this.state;
        const {
            class_occurrence_detail: {
                dropoff_end_time: dropoffEndTime,
                dropoff_start_time: dropoffStartTime,
                id,
                location_array_detail,
                pickup_end_time: pickupEndTime,
                pickup_start_time: pickupStartTime,
            },
            trainer_detail: { full_name: fullName },
        } = classData;

        const neighborhood = location_array_detail[Object.keys(location_array_detail)[0]];
    
        return (
            <label className='radio-control__controls-label-list-item' key={`class_occurrence_${id}`}>
                <Field
                    className='radio-control__controls-input-list-item'
                    component='input'
                    type='radio'
                    name='class_occurrence'
                    value={id}
                    id={`class_occurrence_${id}`}
                    checked={id === parseInt(alternativeClassId, 10)}
                    onChange={this.onClickAlternativeClassItem}/>
                <div className='radio-control__controls-text-wrapper'>
                    <div className='radio-control__controls-text-list-item'>
                        {`Neighborhood: ${neighborhood}`}
                    </div>
                    <div className='radio-control__controls-text-list-item'>
                        {`Pickup time: ${pickupStartTime} - ${pickupEndTime}`}
                    </div>
                    <div className='radio-control__controls-text-list-item'>
                        {`Dropoff time: ${dropoffStartTime} - ${dropoffEndTime}`}
                    </div>
                    <div className='radio-control__controls-text-list-item'>
                        {`Trainer: ${fullName}`}
                    </div>
                </div>
            </label>
        );
    };

    handleChangeClassesChecked = () => {
        const { changeClassesChecked } = this.state;
        this.setState({ changeClassesChecked: !changeClassesChecked });
    }

    render() {
        const {
            onSubmit,
            onClose,
            handleSubmit,
            isSubmitting,
            data,
            invalid,
            isOpened,
            modalError,
            stripeError,
            modalData,
        } = this.props;
        const {
            alternativeClassesData,
            changeClassesChecked,
            fetchingAlternativeClasses,
        } = this.state;
        let alternativeClassesToRender;
        let showSwitchClassesMenu;

        const useFreeCreditPackageFields = modalData &&
            modalData.packageId &&
            modalData.packageId === ADMIN_ONLY_PACKAGE_ID;

        const additioanalSubmitClasses = data && 'submitButtonClasses' in data ? data.submitButtonClasses : '';
        const submitButtonClassNames = classNames(
            {
                'button modal-dialog__button': true,
                'button_disabled': isSubmitting || invalid,
            },
            additioanalSubmitClasses,
        );

        const renderForm = () => {
            return FormFactory.getForm({
                formName: data.form,
                formProps: this.props,
                initialValues: data.initialValues,
                isOpened,
                useFreeCreditPackageFields,
            });
        };

        const normalizeFormData = formData => {
            const { submitNormalizer, updateNormalizer } = FormFactory.getFormDefinition(data.form);
            onSubmit({ formData, submitNormalizer, updateNormalizer });
        };

        let modalErrorToDisplay = modalError;
        if (modalError && data.form === 'createDogReportModalDialog') {
            modalErrorToDisplay = 'Error creating snapshot';
        }

        const editCreateForm = data.form === 'editUpcomingClassModalDialog';

        // a stripe error will be displayed twice in the modal for booking a class
        // so notClassBookingModalDialog is used to prevent showing an error message
        // in this component
        const notClassBookingModalDialog = !(data.form === 'classBookingModalDialog');

        if (editCreateForm) {
            alternativeClassesToRender = alternativeClassesData.map(this.getAlternativeClassItem);
            showSwitchClassesMenu = alternativeClassesToRender.length > 0;
        }

        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit(normalizeFormData)}>
                {data.text &&
                <p className='modal-dialog__text modal-dialog__text_bold'>
                    {data.text}
                </p>}
                {data.extraText &&
                <p className='modal-dialog__text modal-dialog__text_bold'>
                    {data.extraText}
                </p>}
                {data.headline &&
                <div className='modal-dialog__headline'>
                    <div className='modal-dialog__headline-title'>{data.headline.title}</div>
                    <div className='modal-dialog__headline-subtitle'>{data.headline.subtitle}</div>
                </div>}
                <div className='modal-dialog__form-content'>
                    {renderForm()}
                </div>
                {(editCreateForm && !fetchingAlternativeClasses) ?
                    <div style={styles.changeClassesContainer}>
                        {showSwitchClassesMenu ?
                            <React.Fragment>
                                <div className='checkbox-control'>
                                    <label className='checkbox-control__label-no-centering'>
                                        <Field
                                            name='switch_classes'
                                            component='input'
                                            type='checkbox'
                                            checked={changeClassesChecked}
                                            onClick={this.handleChangeClassesChecked}
                                            className='checkbox-control__input'/>
                                        <span className='checkbox-control__text'>Switch classes</span>
                                    </label>
                                </div>
                                {changeClassesChecked ? alternativeClassesToRender : null}
                            </React.Fragment>
                        :
                            <span style={{ fontSize: '1.16rem', textAlign: 'center' }}>
                                There are no alternative classes to switch to
                            </span>
                        }
                    </div>
                : null}
                {modalError !== null && <div className='form__error'>{modalErrorToDisplay}</div>}
                {(stripeError && notClassBookingModalDialog) ?
                    <div className='form__error'>{stripeError}</div>
                : null}
                <div className='modal-dialog__form-footer'>
                    <button className={submitButtonClassNames} type='submit' disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting' : data.submitButtonText ? data.submitButtonText : 'Save'}
                    </button>
                    {data.hasCancel && <div className='modal-dialog__cancel' onClick={onClose}>Cancel</div>}
                </div>
            </form>
        );
    }
}

EditCreateModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isOpened: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    modalError: PropTypes.string,
    submitButtonClasses: PropTypes.string,
    classDate: PropTypes.string,
    className: PropTypes.string,
};

const form = reduxForm({
    form: 'EditCreateModalDialogForm',
    enableReinitialize: true
});

const mapStateToProps = state => ({
    stripeError: state.getIn(['payments', 'error']),
    modalData: state.getIn(['modal', 'data']),
});

export default form(connect(mapStateToProps)(EditCreateModalDialog));
