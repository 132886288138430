import * as axios from 'axios';
import { addAdditionalOwner } from '../actions/dashboard/additionalOwnersActions';
import { getScope } from './oauth';
import { getUserNameSpace } from '../helpers/userRoles';
import * as AlertEvents from '../components/Alert/events';
import { generateAlertMeta } from '../components/Alert/actions';

const dispatchAlert = (dispatch, responseData) => {
    dispatch(generateAlertMeta({
        event: 'CUSTOMER_EDIT',
        responseData
    }));
};

export const patchEmergencyContact = (customerId, owner) => {
    return (dispatch, getState) => {
        console.log('patchEmergencyContact fired');
        console.log(owner);
        const request = new FormData();
        Object.keys(owner).forEach((chunk) => {
            if (chunk === 'id') {
                return;
            }
            if (chunk === 'phone_number') {
                // eslint-disable-next-line consistent-return
                return request.append(chunk, owner[chunk].replace(/[^0-9]/g, ''));
            }
            request.append(chunk, owner[chunk]);
        });
        request.append('scope', getScope());
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.patch(`/v1/${getUserNameSpace('customers.emergency-contacts')}/customers/${customerId}/emergency-contacts/${owner.id}/`, request)
                    .then(response => {
                        dispatchAlert(
                            dispatch,
                            { name }
                        );
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};

export const postEmergencyContact = (customerId, owner) => {
    return (dispatch, getState) => {
        console.log('postEmergencyContact fired');
        console.log(owner);
        const request = new FormData();
        Object.keys(owner).forEach((chunk) => {
            if (chunk === 'phone_number') {
                return request.append(chunk, owner[chunk].replace(/[^0-9]/g, ''));
            }
            return request.append(chunk, owner[chunk]);
        });
        request.append('scope', getScope());
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.post(`/v1/${getUserNameSpace('customers.emergency-contacts')}/customers/${customerId}/emergency-contacts/`, request)
                    .then(response => {
                        dispatchAlert(
                            dispatch,
                            { name }
                        );
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};

export const deleteEmergencyContact = (customerId, owner) => {
    return (dispatch, getState) => {
        console.log('deleteEmergencyContact fired');
        console.log(owner);
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.delete(`/v1/${getUserNameSpace('customers.emergency-contacts')}/customers/${customerId}/emergency-contacts/${owner.id}`)
                    .then(response => {
                        dispatchAlert(
                            dispatch,
                            { name }
                        );
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};
