import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalDialog from '../../../common/ModalDialog';
import { setUploadModalClosed } from '../../../../actions/modal/uploadActions';
import { ReactComponent as IconCancel } from '../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckmark } from '../../../../assets/icons/icon-checkmark.svg';

class UploadModalDialog extends React.PureComponent {
    componentDidMount() {
    }

    onModalClose() {
        const { handleUploadModalClosed, upload } = this.props;
        const files = upload.files;
        for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            file.cancel();
        }
        handleUploadModalClosed();
    }

    renderContent() {
        const { upload } = this.props;
        const files = upload.files;
        let complete = true;
        let completeCount = 0;
        let count = 0;

        for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            complete = file.status === 'loading' ? false : complete;
            if (file.status === 'success') completeCount += 1;
            if (file.status !== 'canceled') count += 1;
        }
        return (
            <div className='modal-dialog__uploading'>
                <div className='modal-dialog__headline'>
                    <div className='modal-dialog__headline-title'>
                        { complete ? `${completeCount} Uploads Complete` : `Uploading ${count} Files`}
                    </div>
                    <div className='modal-dialog__headline-subtitle'>Once uploaded, our Fitdog Team will review your documents and update any vaccination history.</div>
                </div>
                <div className='upload'>
                    <div className='upload__list'>
                        {
                            files.map((file, index) => {
                                const { name, progress, status } = file;
                                const progressStyle = {
                                    width: progress + '%'
                                };
                                if (status === 'canceled') return null;
                                return (
                                    <div className='upload__item' key={'file_' + index}>
                                        <div className='upload__item-header'>
                                            <div className='upload__file-name'>
                                                {name}
                                                {
                                                    status === 'success' &&
                                                    <IconCheckmark className='icon icon_checkmark upload__checkmark'/>
                                                }
                                            </div>
                                            {
                                                status === 'loading' && progress !== 100 &&
                                                <div className='upload__cancel' onClick={() => { file.cancel(); }}>
                                                    Cancel
                                                </div>
                                            }
                                            {
                                                status === 'loading' && progress === 100 &&
                                                <div className='upload__processing'>
                                                    Processing...
                                                </div>
                                            }
                                        </div>
                                        {
                                            status === 'error' &&
                                            <div className='upload__error'>
                                                <IconCancel className='icon icon_immunisation-cancel upload__error-icon'/>
                                                File could not be uploaded.
                                                <div className='link' onClick={() => { file.upload(); }}>Try Again</div>
                                            </div>
                                        }
                                        {
                                            (status === 'loading' || status === 'success') &&
                                            <div className='progress-bar'>
                                                <div className='progress-bar__progress' style={progressStyle}/>
                                            </div>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { upload } = this.props;
        const isOpened = upload.isOpened;
        const files = upload.files;
        return (
            <ModalDialog
                form={
                    isOpened && files.length && this.renderContent()
                }
                headerClassName='modal-dialog__header_transparent'
                className='modal-dialog_upload'
                isModalOpened={isOpened}
                handleModalClosed={this.onModalClose.bind(this)}
                overflowShown/>
        );
    }
}

UploadModalDialog.propTypes = {
    handleUploadModalClosed: PropTypes.func,
    upload: PropTypes.shape({}),
};

const mapStateToProps = state => {
    return {
        upload: state.getIn(['misc', 'modals', 'upload']).toJS(),
        progress: state.getIn(['misc', 'modals', 'upload', 'progress']),
        status: state.getIn(['misc', 'modals', 'upload', 'status']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleUploadModalClosed: bindActionCreators(setUploadModalClosed, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadModalDialog);
