import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import AddNewItem from '../../../../../components/common/AddNewItem';
import { getCustomers, getNextCustomers } from '../../../../../services/customers';
import renderSelectControl from '../../../../common/Form/SelectControl';
import CustomersTable from './CustomersTable';
import { setCurrentCustomerFromCustomersList } from '../../../../../actions/dashboard/employeeActions';
import { openCreateCustomerModalDialog } from '../../../../../actions/modal/openActions';
import { completeSignUp } from "../../../../../actions/onboarding/onBoardingStepsActions";

class CustomersPage extends React.PureComponent {
    static propTypes = {
        handleGettingCustomers: PropTypes.func,
        customersList: PropTypes.shape({}),
        handleSettingCurrentCustomer: PropTypes.func,
        handleGettingCustomersPaymentCards: PropTypes.func,
        handleGettingNextCustomers: PropTypes.func,
        handleOpeningCreateCustomerModalDialog: PropTypes.func,
        handleSignature: PropTypes.func
    };

    componentDidMount() {
        const { customersList, handleGettingCustomers, handleSignature } = this.props;
        // eslint-disable-next-line react/prop-types
        //handleSignature();
        if (!customersList.get('customers').size) {
            handleGettingCustomers();
        }
    }

    render() {
        // eslint-disable-next-line max-len
        const {
            customersList, handleSettingCurrentCustomer, handleGettingCustomers, handleGettingNextCustomers,
            handleOpeningCreateCustomerModalDialog
        } = this.props;
        const customersFilterOptions = [
            { value: '', label: 'All' },
            { value: 'No', label: 'Active' },
            { value: 'Yes', label: 'Inactive' },
        ];
        return (
            <div className='customers'>
                <div className='customers__filter'>
                    <Field
                        name='customers-filter'
                        type='text'
                        onChangeCallBack={(value) => handleGettingCustomers('?is_banned=', value.value)}
                        component={renderSelectControl}
                        options={customersFilterOptions}
                        label='Filter'/>
                </div>
                <AddNewItem
                    className='customers__add-customer'
                    text='New Customer'
                    onClickHandler={handleOpeningCreateCustomerModalDialog}/>
                <CustomersTable
                    isManual
                    isFiltrable
                    data={customersList.get('customers')}
                    defaultPageSize={customersList.get('pageSize')}
                    pages={customersList.get('pages')}
                    count={customersList.get('count')}
                    loading={customersList.get('isLoading')}
                    nextUrl={customersList.get('nextUrl')}
                    getNext={handleGettingNextCustomers}
                    onFiltering={(filter, filterContent) => handleGettingCustomers(filter, filterContent)}
                    onSorting={value => handleGettingCustomers('?o=', value)}
                    onCustomerClick={handleSettingCurrentCustomer}/>
            </div>
        );
    }
}

const form = reduxForm({
    form: 'customersForm'
});

const mapStateToProps = state => {
    return {
        customersList: state.getIn(['customersList'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleGettingCustomers: bindActionCreators(getCustomers, dispatch),
    handleGettingNextCustomers: bindActionCreators(getNextCustomers, dispatch),
    handleSettingCurrentCustomer: bindActionCreators(setCurrentCustomerFromCustomersList, dispatch),
    handleOpeningCreateCustomerModalDialog: bindActionCreators(openCreateCustomerModalDialog, dispatch),
    handleSignature: bindActionCreators(completeSignUp, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(CustomersPage));
