/* eslint-disable no-undef */
import * as axios from 'axios';
import creditCardType from 'credit-card-type';
import { SubmissionError } from 'redux-form/immutable';
import stripeAsPromised from 'stripe-as-promised';
import { finishLoadingCurrentCustomer, startLoadingCurrentCustomer } from '../actions/dashboard/employeeActions';
import {
    markPaymentCardsLoaded,
    removeCurrentCustomerPaymentCard,
    setCurrentCustomersPaymentCards,
} from '../actions/dashboard/paymentCardsAction';
import { setGlobalError } from '../actions/errorHandlerActions';
import {
    closeAddCustomerCardModal,
    setAddCreditCardModalClosed,
    setAddCreditCardModalLoaded,
    setChangePaymentCardModalClosed,
} from '../actions/miscActions';
import { addStepData } from '../actions/steppedFormActions';
import { setStripeError } from '../actions/payments';
import { generateAlertMeta } from '../components/Alert/actions';
import { STRIPE_PUBLIC } from '../config';
import { addZero } from '../helpers/date';
import { getUserNameSpace } from '../helpers/userRoles';

const dispatchAlert = (dispatch, responseData) => {
    dispatch(generateAlertMeta({
        event: 'CUSTOMER_EDIT',
        responseData,
    }));
};

export const getCustomersPaymentCards = (customerId = null) => {
    return (dispatch) => {
        const url = customerId
            ? `/v1/${getUserNameSpace('customers.payment-cards')}/customers/${customerId}/payment-cards/`
            : '/v1/customers/me/payment-cards/';
        dispatch(startLoadingCurrentCustomer());
        return axios.get(url)
                    .then(({ data }) => {
                        dispatch(setCurrentCustomersPaymentCards(data || []));
                        dispatch(finishLoadingCurrentCustomer());
                        dispatch(markPaymentCardsLoaded());
                        return data || [];
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(markPaymentCardsLoaded());
                        dispatch(setGlobalError(error));
                    });
    };
};

export const postCustomersPaymentCard = async (customerId, cardData, token) => {
    const request = {
        first6: cardData.number.substr(0, 6),
        last4: cardData.number.substr(12, 16),
        token,
        is_hidden: cardData.is_hidden || false,
    };
    const url = customerId
        ? `/v1/${getUserNameSpace('customers.payment-cards')}/customers/${customerId}/payment-cards/`
        : '/v1/customers/me/payment-cards/';
    const newCard = await axios.post(url, request);
    newCard.data.isHidden = !cardData.save_card;
    return newCard;
};

export const createStripeCardToken = (customerId, cardData, isAsync, customerName) => {
    return (dispatch, getState) => {
        Stripe.setPublishableKey(STRIPE_PUBLIC);
        const cardNumberNoSpaces = cardData.number.replace(/ /g, '');
        const card = {
            number: cardNumberNoSpaces,
            cvc: cardData.cvc,
            exp_month: addZero(cardData.expiration_month.value),
            exp_year: cardData.expiration_year.value,
        };
        cardData.number = cardNumberNoSpaces;

        const stripe = stripeAsPromised(Stripe, Promise);
        const name = customerName || getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return new Promise((resolve, reject) => {
            stripe.card.createToken(card)
                .then((response) => {
                    // A customer can add a card to their profile a couple of different ways - by adding a card in the
                    // payment methods section add clicking the save card check box when entering payment information
                    // before purchasing a class or package. When doing the latter, the data is saved in
                    // form.AddCreditCardStepForm.values in the redux store. If AddCreditCardStepForm is not a property
                    // of form we know that the customer is entering the payment information in their profile section,
                    // and we know that it should be saved. If AddCreditCardStepForm is present, we save the payment
                    // information if AddCreditCardStepForm.values.save_card is true.
                    let creditCardFormValues = getState().getIn(['form', 'AddCreditCardStepForm', 'values']);
                    if (creditCardFormValues) {
                        creditCardFormValues = creditCardFormValues.toJS();
                    }
                    if (isAsync) {
                        return postCustomersPaymentCard(customerId, cardData, response.id)
                            .then(({ data }) => {
                                dispatchAlert(
                                    dispatch,
                                    { name },
                                );
                                if (!creditCardFormValues || (creditCardFormValues && creditCardFormValues.save_card)) {
                                    window._dcq.push(['track', 'Added credit card']);
                                }
                                return resolve(data);
                            });
                    }
                    return postCustomersPaymentCard(customerId, cardData, response.id)
                        .then(({ data: newCardInfo }) => {
                            resolve(newCardInfo);
                            dispatch(getCustomersPaymentCards(customerId));
                            dispatch(setAddCreditCardModalClosed());
                            dispatch(setAddCreditCardModalLoaded());
                            dispatchAlert(
                                dispatch,
                                { name },
                            );
                            if (!creditCardFormValues || (creditCardFormValues && creditCardFormValues.save_card)) {
                                window._dcq.push(['track', 'Added credit card']);
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                    if (err) {
                    const { type, param, message } = err;
                    dispatch(setStripeError(message));
                    if (err.response) {
                        reject(new SubmissionError({
                            _error: err.response.data.error_message_detail,
                        }));
                    } else {
                        reject(new SubmissionError({
                            _error: err.message,
                        }));
                    }
                    } else {
                    reject(new SubmissionError({
                        _error: 'Error',
                    }));
                    }
                });
        });
    };
};

export const getCustomersPaymentCardsForBooking = (customerId) => {
    return axios.get(`/v1/${getUserNameSpace('customers.payment-cards')}/customers/${customerId}/payment-cards/`);
};

export const loadBookingPaymentCards = (formName) => {
    return dispatch => {
        dispatch(getCustomersPaymentCards())
            .then((data) => dispatch(addStepData({ formName, name: 'cards', data })))
            .catch(err => console.log(err));
    };
};

export const addPaymentCard = (data, target = 'booking') => {
    return async (dispatch, getState) => {
        const response = await dispatch(createStripeCardToken(null, data, false))
            .then((data) => {
                let cards = getState().getIn(['steppedForm', target, 'data', 'cards']) || [];
                if ('size' in cards) {
                    cards = cards.toJS();
                }
                data.type = creditCardType(data.first6)[0].niceType;
                cards.push(data);
                dispatch(addStepData(target, { name: 'cards', data: cards }));
                dispatch(closeAddCustomerCardModal()); //
                return data;
            })
            .catch(err => {
                console.log(err);
                return false;
            });
        return response;
    };
};

export const setDefaultCustomerPaymentCard = (cardId) => {
    return () => {
        const url = `/v1/${getUserNameSpace('customers.payment-cards')}/me/payment-cards/${cardId}/set-default/`;
        return axios.post(url);
    };
};

export const removeCustomerPaymentCard = (cardId) => {
    return dispatch => {
        return axios.delete(`/v1/customers/me/payment-cards/${cardId}`)
                    .then(response => {
                        dispatch(removeCurrentCustomerPaymentCard(cardId));
                    })
                    .catch(err => console.log(err));
    };
};

export const changeCustomerPaymentCard = (cardData) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return dispatch(createStripeCardToken(null, cardData, true))
                .then(({ data }) => {
                    dispatch(removeCustomerPaymentCard(cardData.cardId))
                        .then(() => {
                            dispatch(getCustomersPaymentCards(null))
                                .then(() => {
                                    resolve();
                                    dispatch(setChangePaymentCardModalClosed());
                                });
                        });
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    };
};
