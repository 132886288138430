import * as axios from 'axios';

export const sendFile = ({ currentFile, url, progressCallback = null, completeCallback = null }) => {
    const request = new FormData();
    request.append('doc_file', currentFile);

    return axios.post(url, request, { onUploadProgress: progressCallback })
        .then(response => {
            console.log('Server response: ', response);
            
            if (completeCallback) {
                completeCallback(response);
            }
        })
        .catch(err => {
            console.log(err);
            console.log(err.response);
            if (err.response) {
                if (err.response.status !== 200) {
                    console.log('Unexpected error code from the API server: ', err.response.status);
                }
            }
        });
};

export const sendPhoto = ({ photo, url, progressCallback = null, successCallback = null }) => {
    return () => {
        const request = new FormData();
        request.append('photo', photo);
        return axios.put(url, request, {
            onUploadProgress: progressCallback
        })
            .then(response => {
                console.log('Server response: ', response);
                if (successCallback) {
                    successCallback(response);
                }
                return response;
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                if (err.response) {
                    if (err.response.status !== 200) {
                        console.log('Unexpected error code from the API server: ', err.response.status);
                    }
                }
        });
    };
};

export const uploadFile = ({
    currentFile,
    url,
    progressCallback = null,
    completeCallback = null,
    errorCallback = null,
    cancelToken = null,
    cancelCallback = null,
}) => {
    return () => {
        const request = new FormData();
        request.append('doc_file', currentFile);

        return axios.post(url, request, {
                onUploadProgress: progressCallback,
                cancelToken
            })
            .then(response => {
                console.log('Server response: ', response);
                
                if (completeCallback) {
                    completeCallback(response);
                }
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                    cancelCallback(err.message);
                } else {
                    console.log(err);
                    console.log(err.response);
                    errorCallback(err.response);
                    if (err.response) {
                        if (err.response.status !== 200) {
                            console.log('Unexpected error code from the API server: ', err.response.status);
                        }
                    }
                }
            });
    };
};
