import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../common/Icon';
import { ReactComponent as IconRemove } from '../../assets/icons/icon-add.svg';

const RemoveItem = ({ text, onClickHandler, className = '', disabled = false }) => {
    const addNewClassName = classNames(
        'remove-item',
        className,
        { 'remove-item_disabled': disabled }
    );
    return (
        <a className={addNewClassName} onClick={onClickHandler}>
            <div className='remove-item__text'>{ text }</div>
            <Icon glyph={IconRemove} className='icon_add-new-item'/>
        </a>
    );
};

RemoveItem.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

export default RemoveItem;
