import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import React from 'react';
import classNames from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';
import DropZone from 'react-dropzone';
import { FILE_SIZE_MAX, FILE_IMAGE_HEIGHT_MAX, FILE_IMAGE_WIDTH_MAX, FILE_IMAGE_TYPES, FILE_SIZE_SPINNER } from '../../../../config';
import BaseField from '../';
import Loader from '../../../common/Loader/Loader';
import { ReactComponent as IconFile } from '../../../../assets/icons/icon-file.svg';
import Icon from '../../../common/Icon/index';
import { generateAlertMeta } from '../../../Alert/actions';
import { formatBytes } from '../../../../helpers/data';
import { getOrientation, rotateImage, getBlobPromise } from '../../../../helpers/image';

class ImageSelector extends BaseField {
    static propTypes = {
        ...BaseField.propTypes,
        displayAlert: PropTypes.func,
        dimensions: PropTypes.arrayOf(PropTypes.number),
    };

    state = {
        isBusy: false,
        isCropping: false
    };

    fieldClassName = 'image-selector';
    dimensions = {};

    alertData = {
        MAX_FILE_SIZE_ERROR: {
            maxSize: formatBytes(FILE_SIZE_MAX)
        },
        MAX_RESOLUTION_ERROR: {
            maxWidth: FILE_IMAGE_WIDTH_MAX,
            maxHeight: FILE_IMAGE_HEIGHT_MAX
        }
    };

    validateImageFile = file => new Promise(async (resolve, reject) => {
        if (file.size > FILE_SIZE_MAX) {
            reject({
                event: 'MAX_FILE_SIZE_ERROR',
                alertData: this.alertData.MAX_FILE_SIZE_ERROR
            });
        }

        try {
            const { orientation, needsRotation } = await getOrientation(file);
            this.orientation = orientation;
            this.needsRotation = needsRotation;
        } catch (error) {
            console.log('FAILED TO GET IMAGE ORIENTATION', error);
        }

        const reader = new FileReader();
        const image = new Image();

        image.onload = () => {
            if (!this.dimensions) {
                this.dimensions = {};
            }

            let w = image.naturalWidth || image.width;
            let h = image.naturalHeight || image.height;

            if (this.needsRotation) {
                [w, h] = [h, w];
            }

            if (h > FILE_IMAGE_HEIGHT_MAX || w > FILE_IMAGE_WIDTH_MAX) {
                return reject({
                    event: 'MAX_RESOLUTION_ERROR',
                    alertData: this.alertData.MAX_RESOLUTION_ERROR
                });
            }

            this.dimensions.w = w;
            this.dimensions.h = h;
            return resolve({ file, image });
        };

        reader.onload = e => {
            image.src = e.target.result;
        };

        reader.readAsDataURL(file);
    });

    handleDrop = acceptedFiles => {
        this.file = acceptedFiles[0];
        this.dimensions = {};
        this.needsRotation = false;
        this.orientation = 0;
        this.setState(() => {
            return { isBusy: true };
        }, this.runValidation);
    };

    handleBlur = () => {
        const { input: { value, onBlur } } = this.props;

        if (!value) {
            onBlur();
        } else {
            onBlur(value);
        }
    };

    handleCropClick = (e) => {
        e.preventDefault();

        if (this.state.isCropping) {
            return;
        }

        this.setState({ isCropping: true }, () => {
            this.cropImage();
        });
    };

    handleBrowseClick = (e) => {
        e.preventDefault();
        this.refDropzone.open();
    };

    cropImage = () => {
        const { input: { onChange } } = this.props;
        const canvas = this.refCropper.getImage();
        const photo = canvas.toDataURL();

        canvas.toBlob((blob) => {
            this.setState({ isCropping: false }, () => {
                onChange(fromJS({
                    file: blob,
                    filename: this.file.name,
                    photo_processed: photo,
                    needsCrop: false
                }));
            });
        });
    };

    runValidation = () => {
        const { displayAlert, input } = this.props;
        const { file } = this;

        this.validateImageFile(file)
            .then(async ({ file, image }) => {
                const { dimensions: [width, height] } = this.props;
                const { dimensions: { w, h } } = this;
                const needsCrop = !(Math.floor((width / height) * 10) / 10 === Math.floor((w / h) * 10) / 10);

                if (this.needsRotation) {
                    const rotatedCanvas = await rotateImage({ imageObject: image, orientation: this.orientation });
                    file.preview = rotatedCanvas.toDataURL();
                }

                this.setState({ isBusy: false }, () => {
                    this.clearPreview();
                    input.onChange(fromJS({
                        file,
                        filename: this.file.name,
                        photo_processed: file.preview,
                        needsCrop
                    }));
                });
            })
            .catch(errorEvent => {
                console.log('ERROR', errorEvent);
                this.setState({ isBusy: false }, () => {
                    this.file = null;
                    displayAlert({
                        type: 'error',
                        ...errorEvent
                    });
                });
            });
    };

    clearPreview = () => {
        const { input: { value } } = this.props;

        if (value && value.get) {
            // Avoid memory leaks.
            window.URL.revokeObjectURL(value.get('photo_processed'));
        }
    };

    renderPlaceHolder = () => {
        const { label } = this.props;
        return (
            <div className='image-selector__placeholder' onClick={this.handleBrowseClick}>
                <div className='image-selector__icon-text'>
                    <Icon className='icon_file' glyph={IconFile}/>
                    <div className='image-selector__text'>
                        {label}
                    </div>
                </div>
            </div>
        );
    };

    renderDropzoneImage = () => {
        const { input: { value }, dimensions: [width, height] } = this.props;
        if (!value) {
            return this.renderPlaceHolder();
        }

        const imagePreviewClassNames = classNames(
            'image-selector__image animate fadeIn',
            value.get('needsCrop') && 'image-selector__image_needs-crop'
        );

        const image = value.get('photo') || value.get('photo_processed');

        return (
            <div
                style={{
                    width: `${width}px`,
                    height: `${height}px`
                }}
                className={imagePreviewClassNames}>
                <AvatarEditor
                    ref={(ref) => {
                        this.refCropper = ref;
                    }}
                    width={width}
                    height={height}
                    style={{
                        width: `${width}px`,
                        height: `${height}px`
                    }}
                    border={0}
                    scale={1}
                    rotate={0}
                    image={image}/>
                {value.get('needsCrop') && <button
                    className='image-selector__overlay-button overlay-button_right'
                    onClick={this.handleCropClick}>
                    Crop
                </button>}
                <button
                    className='image-selector__overlay-button overlay-button_left'
                    onClick={this.handleBrowseClick}>
                    Browse
                </button>
            </div>
        );
    };

    renderSpinner = () => {
        const { isBusy } = this.state;
        const { file } = this;

        if (!file || file.size < FILE_SIZE_SPINNER || !isBusy) {
            return null;
        }

        return (
            <Loader
                isVisible
                colorClassName='icon_loader-accent'
                modifierClassName='calendar__loader image-selector__loader'/>
        );
    };

    renderDropzone = () => {
        const { name, input } = this.props;
        return (
            <DropZone
                ref={(ref) => {
                    this.refDropzone = ref;
                }}
                onMouseLeave={this.handleBlur}
                onMouseEnter={input.onFocus}
                accept={FILE_IMAGE_TYPES}
                name={name}
                onDrop={this.handleDrop}
                disableClick
                className={this.getCombinedFieldClassName()}>
                {this.renderSpinner()}
                {this.renderDropzoneImage()}
                {this.renderError()}
            </DropZone>
        );
    };

    renderPreviewImage = () => {
        const { input: { value } } = this.props;
        const valueJS = value.toJS ? value.toJS() : value;
        return (
            <div className={this.getCombinedFieldClassName()}>
                <div
                    style={{
                        // eslint-disable-next-line camelcase
                        background: `url(${valueJS.photo || valueJS.photo_processed}) no-repeat center center / cover`
                    }}
                    className='image-selector__image animate fadeIn'/>
            </div>
        );
    };

    render() {
        const { formProps: { isPreview } } = this.props;
        return isPreview ? this.renderPreviewImage() : this.renderDropzone();
    }
}

const mapDispatchToProps = {
    displayAlert: generateAlertMeta
};

const ImageSelectorWrapped = connect(null, mapDispatchToProps)(ImageSelector);

const ImageSelectorField = ({ fieldConfig, formProps }) => {
    return (
        <Field
            {...fieldConfig}
            formProps={formProps}
            component={ImageSelectorWrapped}/>
    );
};

ImageSelectorField.propTypes = BaseField.defaultFieldProps;

export default ImageSelectorField;
