/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { isLandscapeOrientation, isPortraitOrientation } from '../../../../../../helpers/device';

const ReportCards = ({ data, width }) => {
    const { participating_dogs, dogs_with_cards, percent } = data;
    // named as 'Ratio' because height and width are equal
    const isMobile = window.innerWidth <= 768;
    // ration is X in - container width / 100 = device chart width due to mock up / X
    const desktopChartRation = 0.5414;
    const mobileChartRatio = 0.3737;
    const mobileLandscapeChartRatio = 0.25;
    const chartRatio = width * (isMobile
        ? (isLandscapeOrientation()
            ? mobileLandscapeChartRatio
            : mobileChartRatio)
        : desktopChartRation);
    const strokeWidth = 3;
    const r = (chartRatio - strokeWidth) / 2;

    let percentNormalized;
    if (percent === 0) {
        percentNormalized = percent;
    } else {
        percentNormalized = (percent * 100).toFixed(1);
    }

    const circleRatio = chartRatio / 2;
    const circumference = 2 * 3.14 * (r);
    // stroke-dasharray - so the array needs values not the number
    const strokeLengthNormalized = ((circumference / 100) * percentNormalized).toString().replace('.', ',');

    return (
        <div className='report-cards__chart'>
            <div className='report-cards__chart-content'>
                <div className='chart__title chart__title_centered'>Report Card %</div>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='circle-chart'
                    viewBox={`0 0 ${chartRatio} ${chartRatio}`}
                    width={chartRatio}
                    height={chartRatio}>
                    <circle
                        className='circle-chart__background'
                        stroke='#dddddd'
                        strokeWidth={strokeWidth}
                        fill='none'
                        cx={circleRatio}
                        cy={circleRatio}
                        r={r}/>
                    {percent !== 0 &&
                    <circle
                        className='circle-chart__circle'
                        stroke='#00a1c3'
                        strokeWidth={strokeWidth}
                        strokeDasharray={strokeLengthNormalized}
                        strokeLinecap='round'
                        fill='none'
                        cx={circleRatio}
                        cy={circleRatio}
                        r={r}/>}
                    <g className='circle-chart__info'>
                        <text
                            className='circle-chart__percent'
                            x={circleRatio}
                            y={circleRatio}
                            alignmentBaseline='central'
                            textAnchor='middle'>
                            {percentNormalized}%
                        </text>
                    </g>
                </svg>
                <div className='chart__text'>
                    {dogs_with_cards} Report Cards sent out of {participating_dogs} participating dogs
                </div>
            </div>
        </div>
    );
};

ReportCards.propTypes = {
    data: PropTypes.shape({}),
    width: PropTypes.number.isRequired,
};

export default ReportCards;
