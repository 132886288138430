import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as IconClasses } from '../../../../../../assets/icons/icon-all-classes.svg';
import { getSteppedFormValues } from '../../../../../../reducers/steppedFormReducer';
import FileUploader from "../../../../../common/FileUploader";
import {bindActionCreators} from "redux";
import {uploadImmunization} from "../../../../../../actions/customer/immunizationActions";

const getErrors = (dogData, classData, availableDogs = [], customer) => {
    const {
        access_level: dogAccessLevel,
        assessment_status: assessmentStatus,
        survey: { assessment },
        id,
        name
    } = dogData;
    const { access_level: classAccessLevel, title } = classData;
    const today = moment();
    const dog_age_months = moment.duration(today.diff(moment(dogData.birthday))).asMonths()
    let error = null;
    if (dogAccessLevel < classAccessLevel) {
        error = {
            title: 'Sorry, this class is not safe for your dog.',
            content: 'Try other classes to improve your dog\'s Access Level.'
        };
    }

    if (!assessment && !customer.has_never_booked_class && title !== "Assessment Hike") {
        error = {
            title: 'Requires Home Evaluation'
        };
    }

    if (assessmentStatus === 'IMMUNIZATION' && !customer.has_never_booked_class && title !== "Assessment Hike") {
        error = {
            title: 'Vaccination Required'
        };
    }

     if (title.toLowerCase().includes('puppy') && title !== "Assessment Hike") {
        if (dog_age_months < 3 || dog_age_months > 6){
            error = {
                title: 'Sorry, this class is available only for dogs from 3 to 6 months',
            };
        }
    }

    if (dog_age_months < 3 && title !== "Assessment Hike") {
        error = {
            title: 'Does not meet age requirement.',
        };
    }

    if (!availableDogs.includes(id)) {
        error = {
            title: `${name} is already booked for ${title}`
        };
    }
    return error;
};

const BookingDogItem = ({ dogInfo, className, onClick, isSelected, classInfo, availableDogs, customer, handleUploadImmunization }) => {
    if (dogInfo) {
        const { id, name, photo } = dogInfo;
        const dogError = getErrors(dogInfo, classInfo, availableDogs, customer);
        const itemClasses = classNames([
            className,
            {
                'dogs-slider__item_active': isSelected,
                'dogs-slider__item_disabled': dogError
            }
        ]);
        return (
            <div
                className={itemClasses}
                onClick={() => {
                    if (!dogError && onClick) {
                        onClick(id);
                    }
                }}>
                <span className='dogs-slider__photo' style={{ backgroundImage: `url(${photo || 'https://thumb.ibb.co/ivfaQF/icon_default_img_dog.jpg'})` }}>
                    {dogError &&
                        <i className='dogs-slider__icon'>
                            <IconClasses className='icon_customer-classes'/>
                        </i>
                    }
                </span>
                <p className='dogs-slider__name'>{name}</p>
                {dogError &&
                    <p className='dogs-slider__error'>
                        {dogError.title === 'Vaccination Required' ?
                          <span className='dogs-slider__error-title'>{dogError.title}
                              <span>{'. '}</span>
                            <FileUploader uploadAction={handleUploadImmunization} bookingDogItem={true}  />
                            </span>
                          :
                          <span className='dogs-slider__error-title'>{dogError.title}</span>}
                        {dogError.content && <span className='dogs-slider__error-content'>{dogError.content}</span>}
                    </p>
                }
            </div>
        );
    }
    return null;
};

BookingDogItem.propTypes = {
    dogInfo: PropTypes.shape({}),
    className: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    classInfo: PropTypes.shape({}),
    availableDogs: PropTypes.arrayOf(PropTypes.number),
    customer: PropTypes.shape({}),
    handleUploadImmunization: PropTypes.func,
};

const mapStateToProps = state => {
    const values = getSteppedFormValues('booking')(state);
    const formData = values ? values.data : {};
    return {
        classInfo: formData.classInfo || {},
        availableDogs: formData.availableDogs || [],
        customer: state.getIn(['currentCustomer', 'initialInstance']).toJS(),
    };
};

const mapDispatchToProps = dispatch => ({
    handleUploadImmunization: bindActionCreators(uploadImmunization, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDogItem);
