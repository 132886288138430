import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as EmployeeTypes from '../types/dashboard/employeeTypes';

const INITIAL_STATE = fromJS({
    dogs: [],
    count: null,
    pageSize: null,
    pages: null,
    nextUrl: null,
    previous: null,
    isLoading: false
});

const dogsListReducer = handleActions({
    [EmployeeTypes.SET_DOGS]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        // eslint-disable-next-line camelcase
        // const { active, inactive, all } = count_total;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        const pagesCount = Math.ceil(count / results.length);
        return state.update((state) => {
            return state
                .setIn(['dogs'], fromJS(results))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                // eslint-disable-next-line camelcase
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [EmployeeTypes.SET_NEXT_DOGS]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        // eslint-disable-next-line camelcase
        const { active, inactive, all } = count_total;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        const pagesCount = Math.ceil(count / results.length);

        return state.update((state) => {
            return state
                .setIn(['dogs'], state.get('dogs').concat(fromJS(results)))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [EmployeeTypes.PREPEND_DOGS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['dogs'], state.get('dogs').insert(0, action.payload)).setIn(['count'],
                state.getIn(['count']) + 1);
        });
    },
    [EmployeeTypes.APPEND_DOGS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['dogs'], state.get('dogs').push(action.payload)).setIn(['count'],
                state.getIn(['count']) + 1);
        });
    },
    [EmployeeTypes.REMOVE_DOG_FROM_LIST]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['dogs'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [EmployeeTypes.UPDATE_DOG_PHOTO]: (state, action) => {
        const currentKey = state.getIn(['dogs'])
            .findKey(item => item.get('id') === action.payload.id);
        return state.update((state) => {
            return state.setIn(['dogs', currentKey, 'photo'], action.payload.photo)
                        .setIn(['dogs', currentKey, 'photo_processed'], action.payload.photo);
        });
    },
    [EmployeeTypes.START_DOGS_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [EmployeeTypes.FINISH_DOGS_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    )
}, INITIAL_STATE);

export default dogsListReducer;
