import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processCustomersAdditionalOwners } from '../../../../actions/dashboard/additionalOwnersActions';
import { processCustomerContactInfo } from '../../../../actions/dashboard/employeeActions';
import { setAdditionalOwnersModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import AdditionalOwnersModalDialogForm from './AdditionalOwnersModalDialogForm';

// eslint-disable-next-line max-len
const AdditionalOwnersModalDialog = (props) => {
    const { isAdditionalOwnersModalOpened, handleAdditionalOwnersModalClosed, handleSubmit, customer } = props;
    const customerFullName = customer.get('full_name');
    return (
        <ModalDialog
            form={
                <AdditionalOwnersModalDialogForm
                    customersName={customerFullName}
                    onSubmit={data => handleSubmit(customer.get('id'), data.toJS())}/>
            }
            title={`Edit ${customerFullName}'s Additional Owners`}
            isModalOpened={isAdditionalOwnersModalOpened}
            handleModalClosed={handleAdditionalOwnersModalClosed}/>
    );
};

AdditionalOwnersModalDialog.propTypes = {
    isAdditionalOwnersModalOpened: PropTypes.bool,
    handleAdditionalOwnersModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({})
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isAdditionalOwnersModalOpened: state.getIn(['misc', 'modals', 'additionalOwners'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAdditionalOwnersModalClosed: bindActionCreators(setAdditionalOwnersModalClosed, dispatch),
    handleSubmit: bindActionCreators(processCustomersAdditionalOwners, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalOwnersModalDialog);
