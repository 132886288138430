import moment from 'moment';
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCancel } from '../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconDots } from '../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconNoShow } from '../../../assets/icons/icon-no-show.svg';
import { formatDateToSlashes } from '../../../helpers/date';
import AvatarPlaceholder from '../../common/AvatarPlaceholder';
import Dropdown from '../../common/Dropdown';
import Icon from '../../common/Icon/Icon';
import { shouldDisplayEventProductDropdown } from '../../../helpers/dropdownItems';

class ClassCard extends React.PureComponent {
    static propTypes = {
        classData: PropTypes.shape({}).isRequired,
        classActions: PropTypes.shape({}),
        isPrevious: PropTypes.bool,
    };

    state = {
        expanded: false,
    };

    getLocation = (classOccurrence) => {
        const { location_detail, location_array, location_array_detail } = classOccurrence;
        if (location_array && location_array.length) {
            return Object.keys(location_array_detail).map((key) => location_array_detail[key]).join(', ');
        }

        return location_detail;
    };

    formatAddress = locationDetail => {
        return `${locationDetail.address1}${locationDetail.address2 ? (' ' + locationDetail.address2) : ''},
        ${locationDetail.city} ${locationDetail.state} ${locationDetail.zip_code}`;
    };

    ATTENDED_STATES = {
        'cancelled': <Icon glyph={IconCancel} className='icon_cancel'/>,
        'attended': <Icon glyph={IconCheckMark} className='icon_check-mark'/>,
        'no show': <Icon glyph={IconNoShow} className='icon_no-show'/>,
        'scheduled': <div className='no-info'>—</div>,
    };

    handleExpandButtonClick = e => {
        e.preventDefault();
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    renderContent = () => {
        if (!this.state.expanded) {
            return null;
        }

        const { classData } = this.props;
        const {
            dog_detail: { name }, class_occurrence_detail: {
                trainer_detail: { full_name }, pickup_start_time,
                pickup_end_time, dropoff_start_time, dropoff_end_time,
            },
        } = classData;

        const convertTime = serverTime => moment(serverTime, 'hh:mmA').format('hh:mmA');

        return (
            <div className='attending-dogs-card__content'>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Dog</div>
                    <div className='attending-dogs-card__item-content'>{name || 'Name not set'}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Trainer</div>
                    <div className='attending-dogs-card__item-content'>{full_name || 'Trainer not set'}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Pick Up</div>
                    <div className='attending-dogs-card__item-content'>
                        {(!pickup_start_time || !pickup_end_time)
                            ? 'Pick Up time not set'
                            : (`${convertTime(pickup_start_time)} - ${convertTime(pickup_end_time)}`)}
                    </div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Drop Off</div>
                    <div className='attending-dogs-card__item-content'>
                        {(!dropoff_start_time || !dropoff_end_time)
                            ? 'Drop Off time not set'
                            : (`${convertTime(dropoff_start_time)} - ${convertTime(dropoff_end_time)}`)}
                    </div>
                </div>
            </div>
        );
    };

    renderExpandButton = () => {
        const text = this.state.expanded ? 'See Less' : 'See More';

        return (
            <div className='attending-dogs-card__expand-button-wrapper'>
                <a
                    href=''
                    onClick={this.handleExpandButtonClick}
                    className='attending-dogs-card__expand-button'>
                    {text}
                </a>
            </div>
        );
    };

    renderDropdown = () => {
        const { classActions, classData, isPrevious } = this.props;

        if (isPrevious && !shouldDisplayEventProductDropdown(classData)) {
            return null;
        }

        return (
            <Dropdown
                data={classData}
                classNames='dropdown_attending-dogs-card'
                icon={IconDots}
                iconClass='icon_chevron_down'
                items={classActions}/>
        );
    };

    render() {
        const { classData } = this.props;
        const { class_occurrence_detail } = classData;
        const {
            training_class_detail: { photo_processed, name, price }, date, location_detail,
        } = class_occurrence_detail;
        const headerBackground = photo_processed !== null
            ? ({
                backgroundColor: '#98999d',
                background: `url(${photo_processed}) center center / cover no-repeat`,
            })
            : ({
                backgroundColor: '#98999d',
            });
        return (
            <div className='attending-dogs-card-container'>
                <div className='attending-dogs-card'>

                    {this.renderDropdown()}

                    <div className='classes-schedule-card__header' style={headerBackground}>
                        {photo_processed === null && <AvatarPlaceholder type='dog' size='card'/>}
                        <div className='classes-schedule-card__header-title'>
                            <div className='classes-schedule-card__header-location'>
                                {this.getLocation(class_occurrence_detail) || 'Location not set'}
                            </div>
                            <div className='classes-schedule-card__header-price'>
                                {price ? '$' + price : 'Price not set'}
                            </div>
                        </div>
                    </div>
                    <div className='classes-schedule-card__name-info'>
                        <div className='classes-schedule-card__class-name'>
                            {name || 'Name not set'}
                        </div>
                        <div className='classes-schedule-card__weekdays'>
                            {date ? `${formatDateToSlashes(date)}, ` : 'Date not set, '}
                            {this.getLocation(class_occurrence_detail) || 'Location not set'}
                        </div>
                    </div>


                    {this.renderContent()}
                    {this.renderExpandButton()}

                </div>
            </div>
        );
    }
}

export default ClassCard;
