import * as axios from 'axios';
import Cookies from 'js-cookie';
import { push } from 'react-router-redux';
import { get } from 'lodash';
import { SubmissionError } from 'redux-form/immutable';
import { userLogout } from '../actions/onboarding/userActions';
import { OAUTH2_FITDOG_CLIENT_ID, OAUTH2_FITDOG_CLIENT_SECRET } from '../config';
import { isCustomer, isTrainer } from '../helpers/userRoles';
import { setRequestType } from './http';
import { loadProfileData } from './profile';
import { store } from '../index';
import { deleteOriginalPath } from '../actions/originalPathActions';
import { getAgeBirthdayString } from '../helpers/date';

const cookies = [
    'userRole',
    'scope',
    'accessToken',
    'refreshToken',
];

const clearCookie = (name, domain = document.domain, path = '/') => {
    // Clear cookie by setting an empty cookie with an expiry date in the past.
    document.cookie = `${name}=; expires=${new Date()}; domain=${domain}; path=${path}`;
};

const clearAllCookies = () => {
    cookies.forEach(cookie => {
        clearCookie(cookie);
    });
};

export const setUserRole = scopes => {
    const rolePrefix = 'role_';
    let role = '';

    scopes.split(' ').some(scope => {
        if (scope.startsWith(rolePrefix)) {
            role = scope.replace(rolePrefix, '');
            return true;
        }
        return undefined;
    });

    if (!role) {
        throw Error('Role scope not found');
    }

    try {
        localStorage.setItem('userRole', role);
    } catch (e) {
        console.log(e);
        Cookies.set('userRole', role);
    }
};

export const setScope = (responseData) => {
    let scope;
    if (responseData.access_token) {
        scope = responseData.scope;
    }
    if (responseData.token_data) {
        scope = responseData.token_data.scope;
    }

    try {
        localStorage.setItem('scope', scope);
    } catch (e) {
        console.log(e);
        Cookies.set('scope', scope);
    }

    try {
        return setUserRole(scope);
    } catch (e) {
        throw Error('CRITICAL FAILURE.\nFailed to set the Fitdog user role.\n\n' + e);
    }
};

export const getScope = () => {
    let scope = null;

    // scope = Cookies.get('scope');
    try {
        scope = localStorage.getItem('scope');
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        if (scope === null) {
            scope = Cookies.get('scope');
        }
    }

    return scope;
};

export const deleteScope = () => {
    localStorage.removeItem('scope');
    if (Cookies.get('scope') !== undefined) {
        Cookies.remove('scope');
    }
};

export const setAuthorizationHeader = (accessToken) => {
    if (accessToken) {
        axios.defaults.headers.common.authorization = `Bearer ${accessToken}`;
    } else {
        delete axios.defaults.headers.common.authorization;
        deleteScope();
    }
};

export const setAccessToken = (responseData) => {
    let accessToken;
    if (responseData.access_token) {
        accessToken = responseData.access_token;
    }
    if (responseData.token_data) {
        accessToken = responseData.token_data.access_token;
    }

    try {
        localStorage.setItem('accessToken', accessToken);
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        Cookies.set('accessToken', accessToken);
    }
    setAuthorizationHeader(accessToken);
};

export const setRefreshToken = (responseData) => {
    let refreshToken;
    if (responseData.refresh_token) {
        refreshToken = responseData.refresh_token;
    }
    if (responseData.token_data) {
        refreshToken = responseData.token_data.refresh_token;
    }

    try {
        localStorage.setItem('refreshToken', refreshToken);
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        Cookies.set('refreshToken', refreshToken);
    }
};

export const getAccessToken = () => {
    let accessToken = null;

    try {
        accessToken = localStorage.getItem('accessToken');
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        if (accessToken === null) {
            accessToken = Cookies.get('accessToken');
        }
    }

    return accessToken;
};

export const getRole = () => {
    try {
        // FIT-2560 fix
        // on IOS10 it get null instead of error if trying to accesses
        const userRole = localStorage.getItem('userRole');
        if (userRole !== null) {
            return userRole;
        }
        return Cookies.get('userRole');
        // return localStorage.getItem('userRole');
    } catch (e) {
        console.log(e);
        return Cookies.get('userRole');
    }
};

export const deleteUserRole = () => {
    localStorage.removeItem('userRole');
    if (Cookies.get('userRole')) {
        Cookies.remove('userRole');
    }
};

export const getRefreshToken = () => {
    let refreshToken = null;

    try {
        refreshToken = localStorage.getItem('refreshToken');
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        if (refreshToken === null) {
            refreshToken = Cookies.get('refreshToken');
        }
    }

    return refreshToken;
};

export const deleteAccessToken = () => {
    localStorage.removeItem('accessToken');
    if (Cookies.get('accessToken') !== undefined) {
        Cookies.remove('accessToken');
    }
};

export const deleteRefreshToken = () => {
    localStorage.removeItem('refreshToken');
    if (Cookies.get('refreshToken') !== undefined) {
        Cookies.remove('refreshToken');
    }
};

export const clearLocalRegistrationInfo = () => {
    try {
        localStorage.removeItem('introductionForm');
        localStorage.removeItem('healthForm');
        localStorage.removeItem('vetInfoForm');
        localStorage.removeItem('registration_step');
    } catch (e) {
        console.log(e);
    }
};

export const setRequestURLSearchParams = () => {
    let request = null;

    try {
        request = new URLSearchParams();
    } catch (e) {
        console.log(e);
    } finally {
        if (request === null) {
            request = new FormData();
        }
    }

    return request;
};

export const oauthService = ({
    grantType,
    role,
    backendType,
    url,
    actionCreator,
    redirectPath,
    userData,
}) => {
    return (dispatch) => {
        if (backendType === 'google-oauth2' && userData.profileObj !== undefined &&
            (!userData.profileObj.email ||
                !userData.profileObj.email.length ||
                !userData.profileObj.phone ||
                !userData.profileObj.phone.length ||
                userData.profileObj.phone === 'None')) {
            dispatch(actionCreator(userData));
        }
        if (backendType === 'facebook' &&
            (!userData.email ||
                !userData.email.length || !userData.phone || !userData.phone.length || userData.phone === 'None')) {
            dispatch(actionCreator(userData));
        }

        const request = setRequestType(grantType);
        // signIn/email-password case
        if (grantType === 'password' && url === '/oauth/token/') {
            request.append('username', userData.email);
            request.append('password', userData.password);
        }
        // signUp/email-password case
        if (grantType === 'password' && url === '/v1/customers/signup/') {
            request.append('email', userData.user_email);
            request.append('full_name', userData.full_name);
            request.append('password', userData.user_password);
            request.append('phone', userData.phone.replace(/[^\w\s]/gi, ''));
        }
        // signIn/fb-google case
        if (grantType === 'convert_token' && url.indexOf('/oauth/convert-token/') !== -1) {
            request.append('backend', backendType);
            request.append('token', userData.accessToken);

            // Not the most elegant solution but a quick fix
            let phone = get(userData, 'profileObj.phone', userData.phone);
            const email = get(userData, 'profileObj.email', userData.email);
            const name = get(userData, 'profileObj.name', userData.email);
            if (phone === 'None') {
                phone = undefined;
            }
            request.append('phone', phone);

            // signUp/fb-google case
            if (role) {
                request.append('role', role);
            }
            request.append('email', email);
            request.append('full_name', name);
        }

        // gingr user
        if ('import_from_gingr' in userData) {
            request.append('import_from_gingr', userData.import_from_gingr);
        }
        request.append('grant_type', grantType);
        request.append('client_id', OAUTH2_FITDOG_CLIENT_ID);
        request.append('client_secret', OAUTH2_FITDOG_CLIENT_SECRET);

        return new Promise((resolve, reject) => {
            axios.post(url, request)
                .then(response => {
                    // track user sign ups
                    if (grantType === 'password' && url === '/v1/customers/signup/') {
                        window.gtag('event', 'sign_up', {
                            'email': userData.user_email
                        });
                    }
                    if (userData.authFor === 'customer' && !(response.data.user_role === 'customer') ||
                        userData.authFor === 'employee' && response.data.user_role === 'customer') {
                        // revoke received token
                        const request = setRequestURLSearchParams();
                        request.append('token', getAccessToken());
                        request.append('client_id', OAUTH2_FITDOG_CLIENT_ID);
                        request.append('client_secret', OAUTH2_FITDOG_CLIENT_SECRET);
                        axios.post('/oauth/revoke-token', request);

                        // throw error
                        reject(new SubmissionError({
                            _error: 'Your information doesn’t match our records. Please try again.',
                        }));
                    } else {
                        // setting accessToken and scope to localStorage and authorization header
                        setAccessToken(response.data);
                        setRefreshToken(response.data);
                        setScope(response.data);

                        // remove local data from previous user
                        clearLocalRegistrationInfo();

                        // load user profile data
                        dispatch(loadProfileData()).then(({ data: loadedUserData }) => {
                            if (actionCreator) {
                                dispatch(actionCreator(userData));
                            }
                            const originalPath = store.getState().get('originalPath').get('originalPath');
                            if (redirectPath) {
                                if (isCustomer() && url === '/oauth/token/') {
                                    if (originalPath) {
                                        dispatch(push(originalPath));
                                        dispatch(deleteOriginalPath());
                                    } else {
                                        dispatch(push('/home'));
                                    }
                                } else if (isTrainer() && url === '/oauth/token/') {
                                    if (originalPath) {
                                        dispatch(push(originalPath));
                                        dispatch(deleteOriginalPath());
                                    } else {
                                        dispatch(push('/dashboard'));
                                    }
                                } else if (originalPath) {
                                    dispatch(push(originalPath));
                                    dispatch(deleteOriginalPath());
                                } else {
                                    dispatch(push(redirectPath));
                                }
                            }
                            resolve();
                        });
                    }
                })
                // eslint-disable-next-line consistent-return
                .catch(err => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response === undefined || err.response.status === 500) {
                        reject(new SubmissionError({
                            _error: 'Network error. Please try again later.',
                        }));
                    }
                    if (err.response) {
                        if (err.response.status !== 200) {
                            console.log('Unexpected error code from the API server: ', err.response.status);
                            // ginger user
                            if (err.response.status === 409) {
                                return dispatch(push('/welcome-back'));
                            }

                            if ((backendType === 'google-oauth2' || backendType === 'facebook') &&
                                (err.response.data.error.phone || err.response.data.error.email)) {
                                return dispatch(push('/finish-sign-up'));
                            }
                            if (err.response.data.email) {
                                reject(new SubmissionError({
                                    email: err.response.data.email,
                                    user_email: err.response.data.email,
                                }));
                            }
                            if (err.response.data.password) {
                                reject(new SubmissionError({
                                    password: err.response.data.password,
                                    user_password: err.response.data.password,
                                }));
                            }
                            if (err.response.data.error_description) {
                                reject(new SubmissionError({
                                    _error: 'Your information doesn’t match our records. Please try again.',
                                }));
                            }
                        }
                    }
                });
        }).catch(error => {
            throw error;
        });
    };
};

export const canShowAlert = () => {
    let alertIsShown = false;

    try {
        alertIsShown = localStorage.getItem('alertIsShown');
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        if (alertIsShown === false) {
            alertIsShown = Cookies.get('alertIsShown');
        }
    }

    return !alertIsShown;
};

export const setAlertAsShown = () => {
    try {
        localStorage.setItem('alertIsShown', true);
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        Cookies.set('alertIsShown', true);
    }
};

export const setAlertAsNotShown = () => {
    try {
        localStorage.setItem('alertIsShown', false);
    } catch (e) {
        console.log(e); // if you want to read the error
    } finally {
        Cookies.set('alertIsShown', false);
    }
};

const setCustomerAlert = (responseData) => {
    if (responseData.alert && responseData.message) {
        setAlertAsNotShown();
    }
};

export const logout = () => {
    return (dispatch) => {
        const request = setRequestURLSearchParams();
        request.append('token', getAccessToken());
        request.append('client_id', OAUTH2_FITDOG_CLIENT_ID);
        request.append('client_secret', OAUTH2_FITDOG_CLIENT_SECRET);
        const redirectTo = isCustomer() ? '/' : '/employee';
        return axios.post('/oauth/revoke-token', request)
                    .then(() => {
                        if (window.localStorage) {
                            localStorage.clear();
                        }

                        if (window.Cookies) {
                            clearAllCookies();
                        }
                        dispatch(userLogout());
                        dispatch(push(redirectTo));
                    })
                    .catch(error => {
                        console.log('Error in logout', error.response);
                    });
    };
};

let authTokenRequest;

export const makeActualAuthenticationRequest = () => {
    const request = {
        'grant_type': 'refresh_token',
        'refresh_token': getRefreshToken(),
        'client_id': OAUTH2_FITDOG_CLIENT_ID,
        'client_secret': OAUTH2_FITDOG_CLIENT_SECRET,
    };
    axios.defaults.headers.common.authorization = '';
    return axios.post('/oauth/token/', request)
                .then((response) => {
                    setAccessToken(response.data);
                    setRefreshToken(response.data);
                    setScope(response.data);
                    setCustomerAlert(response.data);
                    window.location.reload();
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401) {
                        deleteAccessToken();
                        deleteRefreshToken();
                        deleteScope();
                        deleteUserRole();
                    }
                });
};

const resetAuthTokenRequest = () => {
    authTokenRequest = null;
};

export const getAuthToken = () => {
    if (!authTokenRequest) {
        authTokenRequest = makeActualAuthenticationRequest();
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
};
