import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import TrainingEventCard from '../TrainingEventCard';
import { openCustomReports } from '../../../../../actions/dashboard/reportsActions';
import { setCurrentDogFromDogsList } from '../../../../../actions/dashboard/employeeActions';

class TrainingEventSlider extends React.PureComponent {
    static propTypes = {
        items: PropTypes.shape({}),
        dispatchPush: PropTypes.func,
        openCustomReports: PropTypes.func,
        handleSettingCurrentDog: PropTypes.func,
    };

    componentDidMount() {
        window.onload = () => {
            if (this.swiper) this.swiper.swiper.update(true);
        };
    }

    swiper = null;

    params = {
        containerClass: 'class-event-slider',
        slidesPerView: 2,
        spaceBetween: 32,
        init: (swiper) => {
            this.swiper = swiper;
        },
        nextButton: '.swiper-button-next.class-event-slider__next',
        prevButton: '.swiper-button-prev.class-event-slider__prev',
        breakpoints: {
            1023: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            736: {
                slidesPerView: 1,
                spaceBetween: 10,
                nextButton: '.swiper-button-next.class-event-slider__next',
                prevButton: '.swiper-button-prev.class-event-slider__prev',
            }
        }
    };

    render() {
        const { items, dispatchPush, openCustomReports, handleSettingCurrentDog } = this.props;
        const slides = items.toJS().map((item, i) => {
            return (
                <div className='class-event-card-shadow-container' key={'class-event-card-' + i}>
                    <TrainingEventCard
                        cardData={item}
                        dispatchPush={dispatchPush}
                        openCustomReports={openCustomReports}
                        handleSettingCurrentDog={handleSettingCurrentDog} />
                </div>
            );
        });

        return (
            <div className='class-event-slider-container'>
                <Swiper
                    {...this.params}
                    ref={node => {
                        this.swiper = node;
                    }}>
                    {slides}
                </Swiper>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    dispatchPush: bindActionCreators(push, dispatch),
    openCustomReports: bindActionCreators(openCustomReports, dispatch),
    handleSettingCurrentDog: bindActionCreators(setCurrentDogFromDogsList, dispatch),
});

export default connect(null, mapDispatchToProps)(TrainingEventSlider);
