import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { bindActionCreators } from 'redux';
import AjaxLoader from '../../../common/AjaxLoader';
import { openCustomersTrainingModal } from '../../../../actions/modal/openActions';
import { getCustomerTrainingsList, getNextTrainingsList } from '../../../../services/trainings';
import { ReactComponent as IconChevronLongRight } from '../../../../assets/icons/icon-chevron-long-right.svg';
import AvatarPlaceholder from '../../../common/AvatarPlaceholder';

class TrainingPrograms extends React.PureComponent {
    static propTypes = {
        trainingsList: PropTypes.arrayOf(PropTypes.shape({})),
        hasMore: PropTypes.bool,
        getCustomerTrainingsList: PropTypes.func,
        getNextData: PropTypes.func,
        isLoading: PropTypes.bool,
        openCustomersTrainingModal: PropTypes.func
    }
    componentDidMount() {
        const { trainingsList, getCustomerTrainingsList, isLoading } = this.props;
        if (!trainingsList.length && !isLoading) {
            getCustomerTrainingsList();
        }
    }

    renderTrainingList = () => {
        const { trainingsList, openCustomersTrainingModal } = this.props;

        return trainingsList.reduce((list, trainingProgram, index) => {
            if (!trainingProgram.is_paused) {
                list.push(
                    <div className='training-programs__item' key={index}>
                        <div className='training-programs__image' style={{ backgroundImage: `url(${trainingProgram.photo_processed || ''})` }}>
                            {trainingProgram.photo_processed === null && <AvatarPlaceholder type='dog' size='customer-card'/>}
                        </div>
                        <div className='training-programs__item-container'>
                            <div className='training-programs__item-content'>
                                <h5 className='training-programs__item-title'>{trainingProgram.name}</h5>
                                <p className='training-programs__item-text'>{trainingProgram.description}</p>
                            </div>
                            <div className='training-programs__buttons'>
                                <button
                                    className='button training-programs__get-started'
                                    onClick={() => openCustomersTrainingModal(trainingProgram.id)}>
                                    Get started
                                </button>
                                <Link to={trainingProgram.link_to_website} target='_blank' className='training-programs__more-button'>
                                    Learn More
                                    <IconChevronLongRight className='icon_chevron-long-right training-programs__more-button__icon'/>
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            }
            return list;
        }, []);
    }
    render() {
        const { trainingsList, hasMore, getNextData, isLoading } = this.props;
        const loader = <AjaxLoader />;
        return (
            <div className='inner-page'>
                <div className='inner-page__container'>
                    <section className='training-programs'>
                        <h1 className='inner-page__title'>Training Programs</h1>
                        <div className='training-programs__list'>
                            <InfiniteScroll
                                className='training-programs__list-wrapper'
                                pageStart={0}
                                loadMore={() => !isLoading ? getNextData() : null}
                                hasMore={hasMore}
                                loader={loader}>
                                {trainingsList.length ? this.renderTrainingList() : loader}
                            </InfiniteScroll>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    trainingsList: state.getIn(['trainingsList', 'trainingsItems']).toJS(),
    hasMore: state.getIn(['trainingsList', 'nextUrl']) !== null,
    isLoading: state.getIn(['trainingsList', 'isTrainingsLoading'])
});
const mapDispatchToProps = (dispatch) => ({
    getCustomerTrainingsList: bindActionCreators(getCustomerTrainingsList, dispatch),
    getNextData: bindActionCreators(getNextTrainingsList, dispatch),
    openCustomersTrainingModal: bindActionCreators(openCustomersTrainingModal, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPrograms);