/* eslint-disable camelcase */

import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import {
    updateClassesDescriptionState,
    updateClassFeaturedOption,
} from '../../../../../../actions/dashboard/classesScheduleActions';
import { openClassDeleteModalDialog, openEditClassModalDialog } from '../../../../../../actions/modal/openActions';
import { ReactComponent as IconChevronUp } from '../../../../../../assets/icons/icon-chevron-up.svg';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconStarFilled } from '../../../../../../assets/icons/icon-star-filled.svg';
import { ReactComponent as IconStar } from '../../../../../../assets/icons/icon-star.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import { timePrettify } from '../../../../../../helpers/date';
import Dropdown from '../../../../../common/Dropdown';
import Icon from '../../../../../common/Icon';
import Table from '../../../../../common/Table/Table';
import { isEmployee, isManager } from '../../../../../../helpers/userRoles';

class ClassScheduleTable extends Table {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        classNameModifier: PropTypes.string,
        defaultPageSize: PropTypes.number,
        isManual: PropTypes.bool,
        isPaginated: PropTypes.bool,
        isFiltrable: PropTypes.bool,
        isPaginationOptional: PropTypes.bool,
        loading: PropTypes.bool,
        pages: PropTypes.number,
        onFiltering: PropTypes.func,
        isSortable: PropTypes.bool,
        isDescriptionFull: PropTypes.shape({}),
        classesIsLoading: PropTypes.bool,
        handleDescriptionStateToggle: PropTypes.func,
        handleFeaturedToggle: PropTypes.func,
        handleOpenEditClassModal: PropTypes.func,
        readOnly: PropTypes.bool,
        openClassDeleteModalDialog: PropTypes.func
    };

    state = {
        vipValue: '',
        isScrollable: false,
        descriptionSizes: fromJS({}),
    };

    componentDidMount() {
        super.componentDidMount();

        const { descriptionSizes } = this.state;
        const { classesIsLoading } = this.props;

        window.addEventListener('resize', this.getDescriptionSizes, false);

        if (descriptionSizes.size === 0 && !classesIsLoading) {
            this.getDescriptionSizes();
        }
    }

    componentDidUpdate() {
        this.getDescriptionSizes();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.getDescriptionSizes);
    }

    getDropdownItems = (row) => {
        const {
            handleFeaturedToggle,
            handleOpenEditClassModal,
            openClassDeleteModalDialog
        } = this.props;
        const itemsList = [];
        itemsList.push({
            iconClass: 'icon_star',
            icon: IconStar,
            text: row.value.get('featured') ? 'Unmark Featured' : 'Mark as Featured',
            onClickCB: () => {
                handleFeaturedToggle(row.index, row.value);
            },
        });
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit',
                onClickCB: () => {
                    handleOpenEditClassModal(row.value);
                },
            }, {
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete',
                onClickCB: () => {
                    openClassDeleteModalDialog(row.value);
                },
            });
        }
        return itemsList;
    };

    getDescriptionSizes = () => {
        const { classesIsLoading, data } = this.props;
        const { descriptionSizes } = this.state;

        if (!classesIsLoading) {
            const updatedDescriptionSizes = Object.keys(
                this.descriptionElements,
            ).reduce((resultObject, descriptionElement) => {
                if (this.descriptionElements[descriptionElement] !== null) {
                    resultObject[descriptionElement] = this.descriptionElements[descriptionElement].clientHeight;
                    if (resultObject.changes === undefined) {
                        resultObject.changes = resultObject[descriptionElement].toString();
                    } else {
                        resultObject.changes += resultObject[descriptionElement].toString();
                    }
                    if (resultObject.maxBlockHeight === undefined) {
                        resultObject.maxBlockHeight = (this.fontElement.clientHeight * 2) +
                            (this.fontElement.clientHeight / 2);
                    }
                }
                return resultObject;
            }, {});
            if (
                data.size !== descriptionSizes.size - 2 ||
                updatedDescriptionSizes.changes !== descriptionSizes.get('changes')
            ) {
                this.updateDescriptionSizes(updatedDescriptionSizes);
            }
        }
    };

    descriptionElements = {};

    updateDescriptionSizes = newDescriptionSizes => {
        this.setState({
            descriptionSizes: fromJS(newDescriptionSizes),
        });
    };

    renderTable(columns) {
        const { isScrollable } = this.state;

        const tableClassNames = classNames({
            '-with-scroll-bar': isScrollable,
        });
        const {
            data,
            defaultPageSize = 50,
            isPaginated = false,
            isPaginationOptional = false,
            loading,
            isManual,
            pages,
            isSortable = false,
        } = this.props;

        if (data.size !== 0) {
            return (
                <ReactTable
                    key={'classes-schedule-table-' + data.size}
                    className={tableClassNames}
                    manual={isManual}
                    showPagination={isPaginated}
                    showPageSizeOptions={isPaginationOptional}
                    defaultPageSize={defaultPageSize}
                    filterable={false}
                    sortable={isSortable}
                    data={data}
                    pages={pages}
                    loading={loading}
                    columns={columns}/>
            );
        }

        return <div className='classes-schedule-table__empty-content'>No data</div>;
    }

    render() {
        const { descriptionSizes, isScrollable } = this.state;
        const {
            isDescriptionFull,
            handleDescriptionStateToggle,
            handleFeaturedToggle,
            handleOpenEditClassModal,
            readOnly,
            openClassDeleteModalDialog
        } = this.props;
        const columns = [
            {
                Header: 'Name',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'name',
                accessor: d => d.get('name'),
                Cell: row =>
                    (<div>
                        {row.value}
                    </div>),
            },
            {
                Header: () =>
                    (<div>
                        <div>Location /</div>
                        <div>Neighborhood</div>
                    </div>),
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'location',
                accessor: d => {
                    const location_detail = d.get('location_detail');
                    const location_array = d.get('location_array');
                    const location_array_detail = d.get('location_array_detail');

                    if (location_array && location_array.size) {
                        const detail = location_array_detail.toJS();
                        return Object.keys(detail).map((key) => detail[key]).join(', ');
                    }

                    return location_detail;
                },
                Cell: row => {
                    return (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value || '–'}
                            </span>}
                    </div>);
                }
                    ,
            },
            {
                Header: 'Days',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'classScheduleDays',
                sortable: false,
                accessor: d => d.get('daysNormalized'),
                Cell: row =>
                    (<div>
                        {row.value.length === 0
                            ? <span>-</span>
                            : <span>
                                {row.value}
                            </span>}
                    </div>),
            },
            {
                Header: () =>
                    (<div>
                        <div>Pickup /</div>
                        <div>Dropoff</div>
                    </div>),
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'classSchedulePickupDropoff',
                sortable: false,
                accessor: d => d,
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : (<div className='td__pickup-dropoff'>
                                <div className='pickup__location'>Pickup</div>
                                <div className='pickup__time'>
                                    {timePrettify(row.value.get('pickup_start_time'))}
                                    -
                                    {timePrettify(row.value.get('pickup_end_time'))}
                                </div>
                                <div className='dropoff__location'>Dropoff</div>
                                <div className='dropoff__time'>
                                    {timePrettify(row.value.get('dropoff_start_time'))}
                                    -
                                    {timePrettify(row.value.get('dropoff_end_time'))}
                                </div>
                            </div>)}
                    </div>),
            },
            {
                Header: () =>
                    (<div>
                        <div>Access</div>
                        <div>Level</div>
                    </div>),
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'access_level',
                accessor: d => d.get('access_level_detail'),
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value}
                            </span>}
                    </div>),
            },
            {
                Header: 'Instructor',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                id: 'trainer',
                accessor: d => d.getIn(['trainer_detail', 'full_name']),
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value}
                            </span>}
                    </div>),
            }
        ];
        if (!readOnly) {
            columns.push({
                Header: 'Commission',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                width: 130,
                id: 'commission',
                sortMethod: (a, b) => {
                    return a - b;
                },
                accessor: d => d.get('commission'),
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value}%
                            </span>}
                    </div>),
            });
        }
        columns.push({
                Header: 'Price',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                width: 130,
                id: 'price',
                sortMethod: (a, b) => {
                    return a - b;
                },
                accessor: d => d.get('price'),
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            // check if price not float
                            : (<span>
                                  ${Number(row.value) % 1 === 0 ? Math.round(Number(row.value)) : row.value}
                            </span>)}
                    </div>),
            },
            {
                Header: 'Spots',
                headerClassName: 'classes-schedule-table__th',
                className: 'classes-schedule-table__td',
                width: 130,
                id: 'spots',
                accessor: d => d.get('spots'),
                Cell: row =>
                    (<div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value}
                            </span>}
                    </div>),
            }
        );
        if (!readOnly) {
            columns.push({
                Header: '',
                headerClassName: 'classes-schedule-table__th classes-schedule-table__th_last-of-type',
                className: 'classes-schedule-table__td classes-schedule-table__td_dropdown-menu',
                width: 64,
                id: 'classScheduleDropdownMenu',
                resizable: false,
                sortable: false,
                accessor: d => d,
                Cell: row =>
                    (<div className='classes-schedule-table__dropdown-menu-container'>
                        {row.value.get('featured')
                            ? (<Icon
                                glyph={IconStarFilled}
                                className='icon-star-filled classes-schedule-table_featured-class-icon'/>)
                            : null}
                        <Dropdown
                            classNames='dropdown_table-classes'
                            icon={IconDots}
                            iconClass='icon_chevron_down'
                            items={this.getDropdownItems(row)}/>
                    </div>),
            });
        }
        columns.push({
                Header: '',
                headerClassName: 'classes-schedule-table__th classes-schedule-table__th_hidden',
                className: 'classes-schedule-table__td classes-schedule-table__td_description',
                id: 'classScheduleDescription',
                resizable: false,
                sortable: false,
                accessor: d => d,
                Cell: row => {
                    const descriptionClassName = classNames({
                        'classes-schedule-table__description-content': true,
                        'classes-schedule-table__description-content_hidden': !isDescriptionFull.get(
                            row.value.get('id'),
                        ),
                        'classes-schedule-table__description-content_show': isDescriptionFull.get(row.value.get('id')),
                    });
                    const descriptionButtonClassName = classNames({
                        'classes-schedule-table__description-show-button': true,
                        'classes-schedule-table__description-show-button_hidden':
                        descriptionSizes.get(row.value.get('id').toString()) <=
                        descriptionSizes.get('maxBlockHeight'),
                    });
                    const descriptionButtonChevronClassName = classNames({
                        'icon-chevron-up': true,
                        'classes-schedule-table__description-button-chevron': true,
                        'classes-schedule-table__description-button-chevron-down': !isDescriptionFull.get(
                            row.value.get('id'),
                        ),
                        'classes-schedule-table__description-button-chevron-up': isDescriptionFull.get(
                            row.value.get('id'),
                        ),
                    });
                    const descriptionDotsClassName = classNames({
                        'classes-schedule-table__description-dots': true,
                        'classes-schedule-table__description-dots_visible': !isDescriptionFull.get(row.value.get('id')),
                        'classes-schedule-table__description-dots_hidden':
                        isDescriptionFull.get(row.value.get('id')) ||
                        descriptionSizes.get(row.value.get('id').toString()) <=
                        descriptionSizes.get('maxBlockHeight'),
                    });

                    return (
                        <div className='classes-schedule-table__description-container'>
                            {row.value.get('description') === undefined
                                ? <span/>
                                : (<div className='classes-schedule-table__description-box'>
                                    <div
                                        className='classes-schedule-table__description-title'
                                        ref={element => {
                                            this.fontElement = element;
                                        }}>
                                        Description
                                    </div>
                                    <div className={descriptionClassName}>
                                        <div
                                            className='classes-schedule-table__description-content-text'
                                            ref={element => {
                                                this.descriptionElements[row.value.get('id')] = element;
                                            }}>
                                            {row.value.get('description')}
                                        </div>
                                    </div>
                                    <div className={descriptionDotsClassName}>...</div>
                                    <div
                                        className={descriptionButtonClassName}
                                        onClick={() => {
                                            handleDescriptionStateToggle(row.value.get('id'));
                                        }}>
                                        {isDescriptionFull.get(row.value.get('id')) ? 'Show less' : 'Show more'}
                                        <Icon glyph={IconChevronUp} className={descriptionButtonChevronClassName}/>
                                    </div>
                                </div>)}
                        </div>
                    );
                },
            },
        );
        const { classNameModifier = '' } = this.props;
        return (
            <div className={`classes-schedule-table ${classNameModifier}`}>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isDescriptionFull: state.getIn(['classesList', 'isDescriptionFull']),
        classesIsLoading: state.getIn(['classesList', 'isLoading']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleDescriptionStateToggle: bindActionCreators(updateClassesDescriptionState, dispatch),
    handleFeaturedToggle: bindActionCreators(updateClassFeaturedOption, dispatch),
    handleOpenEditClassModal: bindActionCreators(openEditClassModalDialog, dispatch),
    openClassDeleteModalDialog: bindActionCreators(openClassDeleteModalDialog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassScheduleTable);
