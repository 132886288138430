/* eslint-disable camelcase */
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { ReactComponent as AllPackagesIcon } from '../../../../assets/icons/icon-all-packages.svg';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express-gray.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card-gray.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card-gray.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa-gray.svg';
import { isInPast } from '../../../../helpers/date';
import { responseDataNormalizer } from '../../../../helpers/normalize';
import { capitalizeEachWord } from '../../../../helpers/text';
import * as ValidatorFactory from '../../../../helpers/validate';
import { checkIfPackageWithCurrentClassIsPurchased } from '../../../../services/packages';
import { getCustomersPaymentCardsForBooking } from '../../../../services/paymentCards';
import Icon from '../../../common/Icon';
import renderSelectControl from '../SelectControl';
import NewPaymentCard from './NewPaymentCard';

const creditCardType = require('credit-card-type');

const CARD_ICONS = {
    'visa': <Icon glyph={IconVisa} className='icon_card'/>,
    'master-card': <Icon glyph={IconMasterCard} className='icon_card'/>,
    'american-express': <Icon glyph={IconAmericanExpress} className='icon_card'/>,
    'package': <Icon glyph={AllPackagesIcon} className='icon_purchased-package'/>,
    'undefined': <Icon glyph={IconNeutralCard} className='icon_card'/>,
};

const customEditUpcomingClassesOptionRenderer = data => {
    if (data === undefined) {
        return <div/>;
    }
    return (
        <div className='select-control__upcoming-classes-option'>
            {data.extra && <Icon className='icon_location' glyph={data.extra}/>}
            {data.add && data.add}
            {data.type && CARD_ICONS[data.type]}
            <div className='select-control__label'>{data.label}</div>
        </div>
    );
};

class renderPaymentControl extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        input: PropTypes.shape({}),
        label: PropTypes.string,
        meta: PropTypes.shape({}),
        helper: PropTypes.string,
        formError: PropTypes.string,
        isOpened: PropTypes.bool,
        ownerId: PropTypes.number,
        classTypeId: PropTypes.number,
        optionRenderer: PropTypes.func,
        disabled: PropTypes.bool,
        paymentValue: PropTypes.shape({}),
        stripeError: PropTypes.string,
    };

    state = {
        cardsOptions: [],
        defaultCardId: null,
        newCardValue: '',
        cardNumberValue: {},
    };

    componentDidMount() {
        const { isOpened, ownerId, classTypeId } = this.props;

        if (isOpened) {
            this.setState({ // eslint-disable-line
                cardNumberValue: {},
                newCardValue: '',
            });
        }

        if (ownerId && !this.state.cardsOptions.length) {
            this.getPaymentCards(ownerId).then(() => {
                this.checkIfCustomerPurchasedPackageWithCurrentClass({
                    customerId: ownerId,
                    classTypeId,
                });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isOpened, ownerId } = this.props;

        if (nextProps.isOpened === true && isOpened !== nextProps.isOpened) {
            this.setState({
                cardNumberValue: {},
                newCardValue: '',
            });
        }

        // if (paymentValue) {
        if (nextProps.ownerId && (ownerId !== nextProps.ownerId || this.state.cardsOptions.length === 0)) {
            this.setState({ cardsOptions: [] });
            this.getPaymentCards(nextProps.ownerId).then(() => {
                this.checkIfCustomerPurchasedPackageWithCurrentClass({
                    customerId: nextProps.ownerId,
                    classTypeId: nextProps.classTypeId,
                });
            });
        }
        // }
    }

    getPaymentCards = (id) => {
        return getCustomersPaymentCardsForBooking(id)
            .then(response => {
                const cardsArray = response.data;
                const cardsOptions = cardsArray.map(card => {
                    if (card.is_default) this.setState({ defaultCardId: card.id });
                    const cardInfo = creditCardType(card.first6.substr(0, 2))[0];
                    return {
                        value: card.id,
                        label: `${capitalizeEachWord(cardInfo.type)} ending in *${card.last4}`,
                        type: cardInfo.type,
                    };
                });
                cardsOptions.push(
                    { value: 123456, label: 'Add New Card', add: <div className='add-icon add-icon_card'/> });
                const newCardOptions = uniqBy(this.state.cardsOptions.concat(cardsOptions), 'value');
                this.setState({ cardsOptions: newCardOptions });
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
            });
    };

    checkIfCustomerPurchasedPackageWithCurrentClass = ({ customerId, classTypeId }) => {
        if (classTypeId !== undefined) {
            checkIfPackageWithCurrentClassIsPurchased({ customerId, classId: classTypeId })
                .then(response => {
                    const packagesArray = responseDataNormalizer(response);
                    // eslint-disable-next-line array-callback-return,consistent-return
                    const creditsArray = packagesArray.filter((purchasedPackage) => {
                        return !isInPast(purchasedPackage.expires_at) && purchasedPackage.quantity_remaining > 0;
                    }).map((credit) => {
                        return ({
                            value: credit.id,
                            label: `1 ${credit.package_detail.name} Credit`,
                            type: 'package',
                            defaultCardId: this.state.defaultCardId,
                        });
                    });
                    const newCardOptions = uniqBy(creditsArray.concat(this.state.cardsOptions), 'value');
                    this.setState({ cardsOptions: newCardOptions });
                })
                .catch(err => {
                    console.log(err);
                    console.log(err.response);
                });
        }
    };

    handleNewCardValueChange = value => {
        this.setState({
            newCardValue: value,
        });
    };

    render() {
        const { cardsOptions, cardNumberValue, newCardValue } = this.state;
        const {
            optionRenderer,
            disabled,
            paymentValue,
            stripeError,
        } = this.props;
        return (
            <div className='form__column form__column_block'>
                <Field
                    name='card'
                    type='text'
                    disabled={disabled}
                    selectedValue={paymentValue}
                    component={renderSelectControl}
                    optionRenderer={optionRenderer || customEditUpcomingClassesOptionRenderer}
                    onChangeCallBack={value => {
                        this.setState({
                            cardNumberValue: paymentValue || value,
                        });
                    }}
                    options={cardsOptions}
                    label='Payment Information'
                    validate={!paymentValue
                        ? ValidatorFactory.requiredValidatorSelect('Payment Information')
                        : undefined}/>
                {cardNumberValue !== undefined && cardNumberValue.value === 123456 && (
                    <NewPaymentCard
                        newCardType='new_card'
                        cardNumberValue={newCardValue}
                        changeNewCardValue={this.handleNewCardValueChange}
                        stripeError={stripeError}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stripeError: state.getIn(['payments', 'error']),
    };
}

export default connect(mapStateToProps)(renderPaymentControl);
