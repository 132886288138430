import { insertSubstr } from '../helpers/text';

export const PHONE_MASK = '+1 (999) 999-9999';
export const PHONE_MASK_CHAR = '_';

export const phoneNumberNormalizer = value => value.replace(/\D/g, '');

export const creatablePhoneNumberNormalizer = (data) => {
    if (data && 'value' in data) {
        return data.value.replace(/[^0-9]/g, '');
    }
    return null;
};

export const creatablePhoneValueNormalizer = (data) => (data && 'value' in data) ? data.value : null;

export const timeFieldValueNormalizer = valueObj => {
    const mask = '00:00PM';

    if (!valueObj) {
        return {
            value: mask,
            label: mask
        };
    }

    const { value } = valueObj;

    if (!value || !value.length) {
        return valueObj;
    }

    // Split the string using `PM` or `AM` as devider and including it in the resulting array.
    const [time, PMAM] = value.split(/(?=[pm]|[am])/i).map(item => {
        item = item.toUpperCase();
        if ((item.startsWith('P') || item.startsWith('A')) && item.length === 1) {
            item += 'M';
        }
        return item;
    });

    // Split time at `:`.
    let formattedTime = time.split(':').filter(String).map(item => {
        // A one digit number, pad it with `0`.
        if (item.length === 1) {
            item = '0' + item;
        }
        return item;
    });

    // Array contains only one element – hours, add `00` as minutes.
    if (formattedTime.length === 1) {
        formattedTime.push('00');
    }

    formattedTime = formattedTime.join(':');

    if (PMAM) {
        formattedTime += PMAM;
    } else if (formattedTime.length > 5) {
        // Need this to fix a case when time is `XX:XXX`.
        formattedTime = formattedTime.slice(0, 5 - formattedTime.length);
    }

    const maskedValue = formattedTime + mask.slice(formattedTime.length);
    return {
        value: maskedValue,
        label: maskedValue
    };
};

export const timeFieldInputNormalizer = value => {
    const len = value.length;
    // Remove everything except the allowed chars.
    const regex = /[^0-9aApPmM:]/;
    const normalizedValue = value.replace(regex, '').toUpperCase();

    if (normalizedValue === '') {
        return '';
    }

    // In some cases order is important.
    // If no matching, return `null`.
    const inputProcessor = {
        1: value => {
            // if not `0` or `:`
            if (/[0:]/.test(value)) {
                return null;
            }
            return value;
        },
        2: value => {
            // if `10, 11, 12` or `1:, 2:, ... , 9:`
            if (/^1[0-2]|[1-9]:$/.test(value)) {
                return value;
            }

            // if `1A, 3A, 9P... => 1AM, 3AM, 9PM...`
            if (/^[1-9][AP]$/.test(value)) {
                return value + 'M';
            }

            // if `21, 34, 99... => 2:1, 3:4, 9:9...`
            if (/^[2-9][^:]$/.test(value)) {
                return insertSubstr(value, ':', 1);
            }

            return null;
        },
        3: value => {
            // if `103, 129, 111... => 10:3, 12:9, 11:1...`
            if (/^1[0-2][^:]$/.test(value)) {
                return insertSubstr(value, ':', 2);
            }

            // if `10:, 11:, 12:` or `1:0, 9:9, 4:4...`
            if (/^1[0-2]:|[1-9]:[0-9]$/.test(value)) {
                return value;
            }

            // if `1AM, 3AM, 9PM...`
            if (/^[1-9](AM|PM)$/.test(value)) {
                return value;
            }

            // if `12A, 10A, 11P... => 12AM, 10AM, 11PM...`
            if (/1[0-2][AP]$/.test(value)) {
                return value + 'M';
            }

            return null;
        },
        4: value => {
            // if `10:0, 11:9, 12:1...`
            if (/^(1[0-2]:[0-9])$/.test(value)) {
                return value;
            }
            // if `1:01, 9:50, 4:44...`
            if (/^[1-9]:[0-5][0-9]$/.test(value)) {
                return value;
            }
            // if `1:1A, 7:0P, 9:9P...` => `1:1AM, 7:0PM, 9:9PM...`
            if (/^[1-9]:[0-9][AP]$/.test(value)) {
                return value + 'M';
            }

            // if `12AM, 10AM, 11PM...`
            if (/1[0-2](AM|PM)$/.test(value)) {
                return value;
            }

            return null;
        },
        5: value => {
            // if `10:00, 11:09, 12:10...`
            if (/^1[0-2]:[0-5][0-9]$/.test(value)) {
                return value;
            }
            // if `10:0A, 11:0P, 12:1A...` => `10:0AM, 11:0PM, 12:1AM...`
            if (/^1[0-2]:[0-9][AP]$/.test(value)) {
                return value + 'M';
            }
            // if `1:1PM, 9:0AM, 3:9PM...`
            if (/^[1-9]:[0-9](AM|PM)$/.test(value)) {
                return value;
            }
            // if `1:01P, 9:50A, 4:00P...` => `1:01PM, 9:50AM, 4:00PM...`
            if (/^[1-9]:[0-5][0-9][AP]$/.test(value)) {
                return value + 'M';
            }

            return null;
        },
        6: value => {
            // if `10:00A, 11:09P, 12:10P...` => `10:00AM, 11:09PM, 12:10PM...`
            if (/^1[0-2]:[0-5][0-9][AP]$/.test(value)) {
                return value + 'M';
            }

            // if `1:00PM, 9:30AM, 3:01PM...`
            if (/^[1-9]:[0-5][0-9](AM|PM)]$/.test(value)) {
                return value;
            }

            return null;
        },
        7: value => {
            // if `10:00AM, 11:09PM, 12:10PM...`
            if (/^1[0-2]:[0-5][0-9](AM|PM)$/.test(value)) {
                return value;
            }

            return null;
        }
    };
    if (len in inputProcessor) {
        return inputProcessor[len](normalizedValue);
    }

    return null;
};

export const responseDataNormalizer = (response) => {
    return response.results !== undefined
        ? response.results
        : response.data.results !== undefined ? response.data.results : [];
};

export const immutableValueNormalizer = value => {
    if (!value) {
        return value;
    }

    return value.toJS ? value.toJS() : value;
};

export const agreementTextValueNormalizer = value => {
    if (!value) {
        return value;
    }

    return value.toJS ? value.toJS().plain : value.plain;
};

export const messageInputNormalize = value => {
    if (value.length > 1000) {
        return;
    }
    // eslint-disable-next-line consistent-return
    return value;
};

export const prettifyPhone = value => {
    if (!value || !value.length) return value;
    const x = value.replace(/\D/g, '').match(/1?(\d{0,3})(\d{0,3})(\d{0,4})/);
    const code = value[0] === '1' ? '+1' : '';
    return !x[2] ? `(${x[1]})` : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
};
