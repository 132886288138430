import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class ContentBlock extends React.PureComponent {
    state = {
        isOpened: window.innerWidth > 736
    }

    componentDidMount() {
        this.overflowTimeout = false;
    }

    handleClick() {
        const { isOpened } = this.state;

        if (isOpened) {
            this.body.style.height = this.body.offsetHeight + 'px';
            this.body.style.overflow = 'hidden';
            setTimeout(() => {
                this.body.style.height = '0px';
            }, 1);
        } else {
            this.body.style.height = this.list.offsetHeight + 'px';
            this.body.style.overflow = 'hidden';
            setTimeout(() => {
                this.body.style.overflow = 'visible';
            }, 300);
        }

        this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
    }

    render() {
        const { title, editAction, items, additionalBlock } = this.props;
        const { isOpened } = this.state;
        const bodyClassName = classNames({
            'content-block__body': true,
            'content-block__body_hidden': !isOpened
        });
        return (
            <div className='content-block'>
                <div className='content-block__header'>
                    <div className='content-block__header-text'>{title}</div>
                    {
                        editAction &&
                        <div className='content-block__header-actions'>
                            <a onClick={editAction} className='content-block__header-action'> Edit </a>
                        </div>
                    }
                </div>
                <div className={bodyClassName} ref={body => { this.body = body; }}>
                    <div className='content-block__list' ref={list => { this.list = list; }}>
                        {
                            items.map((item, index) => {
                                const itemClassNames = classNames({
                                    'content-block__item': true,
                                    'content-block__item_column': item.column
                                });
                                return (
                                    <div key={`content-block__item_${index}`} className={itemClassNames}>
                                        <div className='content-block__item-header'>
                                            {item.header}
                                            {
                                                item.headerContent &&
                                                <div className='content-block__item-header-content'>
                                                    {item.headerContent()}
                                                </div>
                                            }
                                        </div>
                                        <div className='content-block__item-content'>{item.content}</div>
                                    </div>
                                );
                            })
                        }
                        { additionalBlock }
                    </div>
                </div>
                <div className='content-block__toggle' onClick={this.handleClick.bind(this)}>{isOpened ? 'View Less' : 'View More'}</div>
            </div>
        );
    }
}

ContentBlock.propTypes = {
    title: PropTypes.string,
    editAction: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    additionalBlock: PropTypes.node
};

export default ContentBlock;
