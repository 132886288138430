import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router';
import { deleteAccessToken, setAuthorizationHeader } from '../../../../../services/oauth';
import renderInputControl from '../../../../common/Form/InputControl';
import renderPasswordControl from '../../../../common/Form/PasswordControl/PasswordControl';
import validate from './validate';

class SignInForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        error: PropTypes.string
    };

    componentDidMount() {
        setAuthorizationHeader();
        deleteAccessToken();
    }

    render() {
        const { handleSubmit, submitting, invalid, error } = this.props;
        const SubmitButtonClassNames = classNames({
            'signin__submit-button': true,
            'signin__submit-button_employee': true,
            'signin__submit-button_disabled': (submitting)
        });
        return (
            <form onSubmit={handleSubmit} className='signin__form'>
                <Field name='email' type='email' component={renderInputControl} formError={error} label='Email'/>
                <Field
                    name='password'
                    type='password'
                    component={renderPasswordControl}
                    formError={error}
                    helper='Forgot password?'
                    label='Password'/>
                {error && <div className='form__error'>{error}</div>}
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>Sign In</button>
                <div className='input-control__info input-control__info_center'>
                    <Link
                        className='input-control__helper'
                        to='/'>
                        Sign in as a Customer
                    </Link>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'signInForm',
    validate
})(SignInForm);
