import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router';
import { deleteAccessToken, setAuthorizationHeader } from '../../../../../services/oauth';
import renderInputControl from '../../../../common/Form/InputControl';
import renderPasswordControl from '../../../../common/Form/PasswordControl/PasswordControl';
import validate from './validate';

class SignInForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        error: PropTypes.string,
        pristine: PropTypes.bool,
        emailValue: PropTypes.string,
        emailError: PropTypes.string,
        passwordValue: PropTypes.string,
        passwordError: PropTypes.string,
    };

    // state = {
    //     isFormValid: false
    // };

    componentDidMount() {
        setAuthorizationHeader();
        deleteAccessToken();

        // console.log(document.getElementById('test').matches(':-webkit-autofill'));
        // console.log(document.getElementById('test').matches('.input-control__input'));

        // eslint-disable-next-line react/prop-types
        // console.log(this.emailField);
    }

    // componentWillReceiveProps(nextProps) {
    //     const { emailValue, emailError, passwordValue, passwordError } = nextProps;
    //     let actualEmail;
    //     let actualPassword;
    //     let actualEmailError;
    //     let actualPasswordError;
    //     if (emailValue !== undefined) {
    //         actualEmail = emailValue.length;
    //     }
    //     if (passwordValue !== undefined) {
    //         actualPassword = emailValue.length;
    //     }
    //     if (emailError !== undefined) {
    //         actualEmailError = emailError.length;
    //     }
    //     if (passwordError !== undefined) {
    //         actualPasswordError = passwordError.length;
    //     }
    //     if (actualEmail && actualEmailError && actualPassword && actualPasswordError) {
    //         this.setState({
    //             isFormValid: true
    //         });
    //     }
    // }

    render() {
        const { handleSubmit, submitting, invalid, error } = this.props;
        // const { isFormValid } = this.state;
        const SubmitButtonClassNames = classNames({
            'signin__submit-button': true,
            'signin__submit-button_disabled': submitting
        });
        return (
            <form onSubmit={handleSubmit} className='signin__form'>
                <Field name='email' type='email' component={renderInputControl} formError={error} label='Email'/>
                <Field
                    // ref={email => { this.emailField = email; }}
                    name='password'
                    type='password'
                    component={renderPasswordControl}
                    formError={error}
                    helper='Forgot password?'
                    label='Password'/>
                {error && <div className='form__error'>{error}</div>}
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>Sign In</button>
                <div className='input-control__info input-control__info_center'>
                    <Link
                        className='input-control__helper'
                        to='/employee'>
                        Sign in as an Instructor/Employee
                    </Link>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'SignInForm',
    validate
});

const mapStateToProps = state => {
    return {
        emailValue: state.getIn(['form', 'SignInForm', 'values', 'email']),
        emailError: state.getIn(['form', 'SignInForm', 'syncErrors', 'email']),
        passwordValue: state.getIn(['form', 'SignInForm', 'values', 'password']),
        passwordError: state.getIn(['form', 'SignInForm', 'syncErrors', 'password'])
    };
};

export default connect(mapStateToProps, null)(form(SignInForm));
